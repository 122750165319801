import { get, writable } from "svelte/store";
import { nvpToChart } from "../../utils/misc/chart-utils";
import { DashboardService } from "../../services/dashboard.service";
export const loading = writable(false);
export const filter = writable({ allCountries: 1, clfCountryId: 0 });
export const tiles = writable();
export const numCoursesByCountry = writable(nvpToChart([]));
export const numCoursesByType = writable(nvpToChart([]));
export const expiringCourses = writable([]);
export async function init() {
    clear();
    await loadDashboard();
}
async function loadDashboard() {
    loading.set(true);
    try {
        await Promise.all([
            loadTiles(),
            loadNumCoursesByCountry(),
            loadNumcoursesByType(),
            loadExpiringCourses()
        ]);
    }
    finally {
        loading.set(false);
    }
}
async function loadTiles() {
    let _filter = get(filter);
    let _resp = await DashboardService.getDashboardTiles(_filter);
    tiles.set(_resp.payload);
}
async function loadNumCoursesByCountry() {
    let _filter = get(filter);
    let _resp = await DashboardService.getNumCoursesByCountry(_filter);
    numCoursesByCountry.set(nvpToChart(_resp.payload));
}
async function loadNumcoursesByType() {
    let _filter = get(filter);
    let _resp = await DashboardService.getNumCoursesByType(_filter);
    numCoursesByType.set(nvpToChart(_resp.payload));
}
async function loadExpiringCourses() {
    let _filter = get(filter);
    let _resp = await DashboardService.getExpiringCourses(_filter);
    expiringCourses.set(_resp.payload);
}
export function toggleAllCountries(evt) {
    let value = evt.detail;
    if (value == 1) {
        filter.set(Object.assign(Object.assign({}, get(filter)), { allCountries: 1, clfCountryId: 0 }));
    }
    else {
        filter.set(Object.assign(Object.assign({}, get(filter)), { allCountries: 0, clfCountryId: 0 }));
    }
}
export function search() {
    loadDashboard();
}
export function clear() {
    filter.set({ allCountries: 1, clfCountryId: 0 });
    loadDashboard();
}
