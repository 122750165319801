<script lang="ts">
  import { onMount } from "svelte";
  import Container from "../../components/Container.svelte";
  import SearchDropdown from "../../components/Form/Fields/Dropdown/SearchDropdown.svelte";
  import FormField from "../../components/Form/FormField.svelte";
  import { Table } from "../../components/Table";
  import FormLayout from "../../layout/FormLayout.svelte";
  import {
    deletePortalUserOrganization,
    loadByUser,
    loading,
    organizationsComboItems,
    records,
    savePortalUserOrganization,
    searchOrganizations,
    selectedItem,
  } from "./settings-user-organizations.store";
  import { get } from "svelte/store";
  import type { IDropdownItem } from "../../services/DTO/dropdown-item";
  import { userOrganizationDtoFields } from "../../utils/TableFields/user-organization-dto.fields";

  export let userId: number;

  function submit() {
    let item = get(selectedItem);
    if (item) {
      savePortalUserOrganization(userId, item.oid);
    }
  }

  function onFilter(text: string) {
    searchOrganizations(text);
  }

  function onDelete(item: IDropdownItem) {
    deletePortalUserOrganization(userId, item.oid);
  }

  onMount(() => loadByUser(userId));
</script>

<FormLayout on:submit={submit} submitButtonText="Associate Training Provider" cancelEnabled={false}>
  <FormField usageHelp="select a training provider or digital innovation hub which can be accessed by this user for management purposes">
    <SearchDropdown
      placeholder="training provider to associate with"
      items={$organizationsComboItems}
      on:filter={(evt) => onFilter(evt.detail)}
      disabled={false}
      value={$selectedItem != null ? $selectedItem.oid : 0}
      on:select={(x) => ($selectedItem = x.detail)}
      name="Training Provider"
    />
  </FormField>
</FormLayout>

<Container>
  <Table
    loading={$loading}
    fields={userOrganizationDtoFields}
    records={$records}
    pagination={null}
    createEnabled={false}
    editEnabled={false}
    deleteEnabled={true}
    on:delete={(e) => onDelete(e.detail)}
  />
</Container>
