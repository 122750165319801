<script lang="ts">
  import { onMount } from "svelte";
  import { isUserLoggedIn, isUserRoleADM } from "../../app.store";
  import TagSelector from "../../components/Business/TagSelector.svelte";
  import Button from "../../components/Button.svelte";
  import Container from "../../components/Container.svelte";
  import Input from "../../components/Form/Fields/Input.svelte";
  import Switch from "../../components/Form/Fields/Switch.svelte";
  import Form from "../../components/Form/Form.svelte";
  import FormField from "../../components/Form/FormField.svelte";
  import ImageAsset from "../../components/ImageAsset.svelte";
  import { TablePaginator } from "../../components/Table";
  import Layout from "../../layout/Layout.svelte";
  import { PRIVACY_URL } from "../../utils/constants";
  import OrganizationSearchResult from "./OrganizationSearchResult.svelte";
  import {
    allCountries,
    clear,
    filter,
    initList,
    onAddTechArea,
    onDelTechArea,
    onPageChange,
    pagination,
    records,
    search,
    techAreasSelectableItems,
    techAreasSelectedItems,
    toggleAllCountries,
  } from "./search-organizations.store";
  import ClfDropdown from "../../components/Form/Fields/Dropdown/ClfDropdown.svelte";

  $: numRecords = $records != null ? $records.length : 0;

  const MaxTextSizeForTags: string = "150px";

  onMount(initList);
</script>

<Layout>
  <div class="base">
    <Container>
      {#if numRecords > 0}
        {#each $records as organization}
          <OrganizationSearchResult conn={organization}></OrganizationSearchResult>
        {/each}

        <TablePaginator pagination={$pagination} on:pageChange={(e) => onPageChange(e.detail)} />
      {/if}

      {#if numRecords == 0}
        <article class="message is-black">
          <div class="message-body">No training providers found according to search criteria</div>
        </article>
      {/if}
    </Container>
  </div>

  <div class="left-filter-box">
    <div class="left-filter-controls">
      <div class="filters-title valign-center-box">
        <ImageAsset name="icon_contact.png" />

        <span class="has-text-weight-normal">Search Training Providers</span>
      </div>

      <Form>
        <FormField>
          <Input placeholder="training provider name" small={true} bind:value={$filter.criteria.title} />
        </FormField>

        <div class="columns is-gapless">
          <div class="column is-4">
            <FormField>
              <Switch disabled={false} condensed={true} on:toggle={toggleAllCountries} value={$allCountries} name="All Countries" labelSize="0.8rem" />
            </FormField>
          </div>

          {#if $allCountries == 0}
            <div class="column is-8" style="margin-top: auto;">
              <FormField>
                <ClfDropdown name="" required={false} schema="COUNTRYXXX" small={true} bind:value={$filter.criteria.countryId} />
              </FormField>
            </div>
          {/if}
        </div>

        <!--
        <FormField>
          <TagSelector
            placeholder="topic"
            fullWidth={true}
            small={true}
            maxTextSize={maxTextSizeForTags}
            showEmptyMessage={false}
            selectableItems={clfTechArea}
            items={techAreasSelectedItems}
            onAdd={addTechArea}
            onDel={delTechArea}
          />
        </FormField>
        -->

        <FormField>
          <TagSelector
            placeholder="topic"
            fullWidth={true}
            small={true}
            maxTextSize={MaxTextSizeForTags}
            showEmptyMessage={false}
            selectableItems={$techAreasSelectableItems}
            items={$techAreasSelectedItems}
            on:add={onAddTechArea}
            on:del={onDelTechArea}
          />
        </FormField>

        <hr />

        <div class="field is-grouped">
          <div class="control search-buttons">
            <Button klass="is-primary" on:click={search}>
              <span class="icon" style="margin-right: 0.2rem;">
                <ImageAsset name="icon_search.png" />
              </span>
              Search
            </Button>

            <span style="margin: 0.5rem;" />

            <Button on:click={clear}>
              <span class="icon" style="margin-right: 0.2rem;">
                <ImageAsset name="icon_clear.png" />
              </span>
              Clear
            </Button>
          </div>
        </div>
      </Form>

      <a class="privacy-policy-link" href={PRIVACY_URL} target="_blank">Privacy Policy</a>
    </div>

    {#if $isUserLoggedIn && $isUserRoleADM}
      <div class="bottom-settings">
        <div class="valign-center-box">
          <ImageAsset name="icon_settings.png" />
          <a href="/settings">Settings</a>
        </div>
      </div>
    {/if}
  </div>
</Layout>

<style>
  .base {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 300px;
    overflow-y: auto;
  }
</style>
