import { derived, get, writable } from "svelte/store";
import { login, navigate } from "../../app.logic";
import { AuthService } from "../../services/auth.service";
import { withProof } from "../../utils/misc-utils";
export const loading = writable(false);
export const email = writable("");
export const password = writable("");
export const recoveryCode = writable("");
export const loginErrors = writable([]);
export const showForgotPassword = writable(false);
export const showRecovery = writable(false);
export const recaptchaToken = writable("");
export const recoveryDoneMessage = writable("");
//export const canSubmit = derived([email, password], ([$e, $p]) => $e != "" && $p != "");
export function init() {
    loading.set(false);
    email.set("");
    password.set("");
    recoveryCode.set("");
    recaptchaToken.set("");
    showForgotPassword.set(false);
    showRecovery.set(false);
    recoveryDoneMessage.set("");
    loginErrors.set([]);
}
export async function submit() {
    loading.set(true);
    try {
        let dto = {
            email: get(email),
            password: get(password),
            code: "",
            recaptchaToken: get(recaptchaToken)
        };
        password.set("");
        let _resp = await AuthService.login(dto);
        login(_resp.payload);
    }
    catch (err) {
        let issue = err;
        // console.log("login error", issue);
        if (issue.code === 'LOGIN_ERROR') {
            loginErrors.set(["Wrong login or password"]);
        }
        else {
            loginErrors.set([issue.message]);
        }
    }
    finally {
        loading.set(false);
    }
}
export async function requestCode() {
    loading.set(true);
    try {
        let dto = {
            email: get(email),
            password: "",
            code: "",
            recaptchaToken: get(recaptchaToken)
        };
        let _resp = await AuthService.forgotPassword(dto);
        showForgotPassword.set(false);
        showRecovery.set(true);
    }
    finally {
        loading.set(false);
    }
}
export async function resetPassword() {
    loading.set(true);
    try {
        let dto = {
            email: get(email),
            password: get(password),
            code: get(recoveryCode),
            recaptchaToken: get(recaptchaToken)
        };
        let _resp = await AuthService.resetPassword(dto);
        if (_resp.issue.error == false) {
            recoveryDoneMessage.set("Your password has been successfuly reset, you can now login.");
        }
        else {
            throw new Error("issue error");
        }
    }
    catch (err) {
        recoveryDoneMessage.set("Your password could not be reset. Please try again and make sure you type the code correctly.");
    }
    finally {
        loading.set(false);
    }
}
export async function submitWithCaptcha() {
    let token = await withProof("sign_in");
    recaptchaToken.set(token);
    await submit();
}
export async function submitRecoveryWithCaptcha() {
    let token = await withProof("forgot_password");
    recaptchaToken.set(token);
    await requestCode();
}
export async function submitResetWithCaptcha() {
    let token = await withProof("reset_password");
    recaptchaToken.set(token);
    await resetPassword();
}
export function backToLoginPage() {
    showForgotPassword.set(false);
    showRecovery.set(false);
    recoveryDoneMessage.set("");
    email.set("");
    password.set("");
    recoveryCode.set("");
}
export function setShowForgotPassword(v) {
    email.set("");
    password.set("");
    recoveryCode.set("");
    showForgotPassword.set(v);
    showRecovery.set(false);
    recoveryDoneMessage.set("");
}
export function gotoSignUp() {
    navigate("/sign-up");
}
