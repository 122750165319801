import { Observable, from, lastValueFrom } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { get } from "svelte/store";
import { sid } from "../../app.store";
import { showToast } from "../misc/toast-utils";
export class Api {
    static send(method, url, data) {
        const fetch = window.fetch;
        const token = get(sid);
        const headers = new Headers();
        const opts = { method: method, headers: headers };
        if (data) {
            headers.set('Content-Type', 'application/json');
            opts.body = JSON.stringify(data);
        }
        if (token) {
            headers.set('Authorization', `Token ${token}`);
        }
        // return fetch(`${BASE_API_URL}/${path}`, opts)
        //   .then(r => r.text())
        //   .then(json => {
        //     try {
        //       return JSON.parse(json) as IApiResponse<O>;
        //     } catch (err) {
        //       console.log(`Http request ${method} [${path}] error: [${err}]`);
        //       return <IApiResponse<O>>{
        //         issue: <IApiIssue>{
        //           code: 'API_ERROR',
        //           error: true,
        //           message: 'Client error: ' + err
        //         }
        //       };
        //     }
        //   });
        return from(fetch(url, opts)).pipe(mergeMap(x => x.json()), map(x => x), map(x => this.checkIssue(x)));
    }
    static sendRaw(method, url, data) {
        const fetch = window.fetch;
        const token = get(sid);
        const headers = new Headers();
        const opts = { method: method, headers: headers };
        if (data) {
            headers.set('Content-Type', 'application/json');
            opts.body = JSON.stringify(data);
        }
        if (token) {
            headers.set('Authorization', `Token ${token}`);
        }
        return from(fetch(url, opts));
    }
    static sendForm(method, url, contentType, data) {
        const fetch = window.fetch;
        const token = get(sid);
        const headers = new Headers();
        if (contentType != null) {
            headers.set('Content-Type', contentType);
        }
        const opts = { method: method, headers: headers, body: data };
        if (token) {
            headers.set('Authorization', `Token ${token}`);
        }
        //return from(fetch(url, opts));
        return from(fetch(url, opts)).pipe(mergeMap(x => x.json()), map(x => x), map(x => this.checkIssue(x)));
    }
    static checkIssue(res) {
        if (!!res && !!res.issue && res.issue.error) {
            showToast(`An error has occurred.\nCode: [${res.issue.code}]\nMessage:\n${res.issue.message}`, 'is-danger');
            throw res.issue;
        }
        return res;
    }
    static get(url) {
        return this.send('GET', url, null);
    }
    static post(url, data) {
        return this.send('POST', url, data);
    }
    static put(url, data) {
        return this.send('PUT', url, data);
    }
    static del(url) {
        return this.send('DELETE', url, null);
    }
    // export function post(endpoint, data) {
    // 	return fetch(endpoint, {
    // 		method: 'POST',
    // 		credentials: 'include',
    // 		body: JSON.stringify(data),
    // 		headers: {
    // 			'Content-Type': 'application/json'
    // 		}
    // 	}).then(r => r.json());
    // }
    static async download(url, fileName, data) {
        var _a, _b;
        let _resp = await lastValueFrom(this.sendRaw(data ? 'POST' : 'GET', url, data));
        if (_resp.status != 200) {
            showToast(`An error has occurred while downloading file`, 'is-danger');
            return;
        }
        if (!fileName) {
            try {
                fileName = (_a = _resp.headers.get('x-file-name')) !== null && _a !== void 0 ? _a : '';
            }
            catch (e) {
                // swallow
            }
            if (!fileName)
                fileName = "file.txt";
        }
        let _data = await _resp.arrayBuffer();
        let contentType = (_b = _resp.headers.get('content-type')) !== null && _b !== void 0 ? _b : '';
        let blob = new Blob([_data], { type: contentType });
        try {
            // intercept JSON and display issue (maybe)
            if (contentType.startsWith("application/json")) {
                let str = await blob.text();
                let json = JSON.parse(str);
                Api.checkIssue(json);
            }
            this.saveData(blob, fileName);
        }
        catch (e) {
            // swallow
        }
    }
    static saveData(blob, fileName) {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }
}
