<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Errors from "../../Errors.svelte";
  import Label from "../Label.svelte";

  const dispatch = createEventDispatcher();

  export let errors: string[] = [];
  export let placeholder: string = "";
  export let disabled: boolean = false;
  export let required: boolean = false;
  export let small: boolean = false;
  export let value: number = 0;
  export let name: string = "";
  export let maxlength: number | null = null;
  export let minValue: number | null = null;
  export let maxValue: number | null = null;

  $: displayName = required && name != "" ? name + " *" : name;

  function onKeyDown(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      event.preventDefault();
      dispatch("enter", event);
    } else {
      // if (event.key == ",") {
      //   //value = value + '.';
      //   event.preventDefault();
      //   event.stopPropagation();
      //   return;
      // }

      dispatch("keydown", event);
    }
  }

  function onBlur(event: Event) {
    dispatch("blur", event);
  }
</script>

<div>
  {#if name != ""}
    <Label>
      {displayName}
    </Label>
  {/if}

  <input
    class="input"
    class:is-danger={required && value === null}
    class:is-small={small}
    class:input-ctr-disabled={disabled}
    bind:value
    on:keydown={onKeyDown}
    on:blur={onBlur}
    {placeholder}
    {disabled}
    {required}
    type="text"
    pattern="[0-9]+(\.[0-9]+)?"
    {maxlength}
    min={minValue}
    max={maxValue}
  />

  <Errors {errors} prefix={name} />
</div>

<style>
  .input-ctr-disabled {
    border-color: #dbdbdb;
  }
</style>
