<script lang="ts">
  export let icon: string = "";
  export let label: string = "";
  export let url: string = "";
</script>

<p class="subtitle is-size-6" style="padding-top: 1rem;margin-bottom: 0;">
  <span class="icon map-detail-contact-icon contactIcon">
    <i class="material-icons">{icon}</i>
  </span>
  <a href={url} target="_blank">{label}</a>
</p>

<style>
  .contactIcon {
    vertical-align: bottom;
    margin-right: 1rem;
  }
</style>
