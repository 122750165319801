<script lang="ts">
  import Label from "./Label.svelte";

  export let name: string = "";
  export let value: string = "";
  export let small: boolean = false;
</script>

{#if name != ""}
  <Label>{name}</Label>
{/if}

<span class="text-label" class:is-small={small}>{value}</span>
