<script>
  import Container from "../../components/Container.svelte";
  import Layout from "../../layout/Layout.svelte";
</script>

<Layout>
  <Container>
    <h1>PRIVACY POLICY</h1>
    <div class="informative">
      <p class="subtitle">INFORMATION NOTICE ON THE PROCESSING OF PERSONAL DATA</p>
      <p>
        <b>NSBproject S.R.L.</b> with registered office in Mantua (Italy), Corso Garibaldi n. 157, ZIP code 46100, as owner of the processing of your personal data
        (hereinafter the “Owner”), issues this information in compliance with the European and Italian regulations on the protection of personal data , in order
        to let you know the purposes and methods of processing personal data that you may communicate while browsing this website (hereinafter the “Site”).
      </p>
      <p>
        It should be noted that this information refers only to the Site and not to third party websites, which may be reached by the User via links contained
        therein.
      </p>
      <h2>CATEGORIES OF DATA PROCESSED</h2>
      <h3>Navigation data</h3>
      <p>
        The computer systems and software procedures used to operate this website acquire, during their normal operation, some personal data whose transmission
        is implicit in the use of Internet communication protocols. This is information that is not collected to be associated with identified data subjects,
        but which by their very nature could, through processing and association with data held by third parties, allow users to be identified. This category of
        data includes, for example, the IP addresses or domain names of the computers used by users who connect to the site, the URI (Uniform Resource
        Identifier) addresses of the requested resources, the time of the request, the method used in submitting the request to the server, the size of the file
        obtained in response, the numerical code indicating the status of the response given by the server (successful, error, etc.) and other parameters
        relating to the operating system and the IT environment of the user. These data are used only to obtain anonymous statistical information on the use of
        the site and to check its correct functioning and are deleted immediately after processing.
      </p>
      <h3>Data provided voluntarily by the User</h3>
      <p>
        Sending e-mails to the addresses indicated on the Site – optional, explicit and voluntary – entails the subsequent acquisition of your e-mail address,
        necessary to respond to requests, as well as any additional personal data communicated.
      </p>
      <h3>Cookies</h3>
      <p>
        This site uses <i>Necessary Cookies</i>, which are essential for basic functionality for logged-in users in order to keep the user logged in. Such
        cookies remain stored until the user logs out and contain a session identifier. This site does not use <i>Performance</i> or <i>Analytics</i> cookies.
      </p>
      <p>
        Please refer to <a href="https://gdpr.eu/cookies/">https://gdpr.eu/cookies/</a> for further information about cookies.
      </p>
      <h2>PURPOSE AND LEGAL BASIS OF THE PROCESSING, NATURE OF DATA CONFERENCE AND CONSEQUENCES OF REFUSAL</h2>
      <p>
        The Data Controller will process your data to facilitate navigation and to provide any services requested by you through the forms specially prepared on
        the Site. Except for what is specified for navigation data and technical cookies, within the sections of the Site prepared for particular services at
        your request you will be free to provide your personal data for the purposes indicated in the respective information, but failure to provide them may
        make it impossible to obtain the requested service.
      </p>
      <h2>DATA CONSERVATION PERIOD</h2>
      <p>
        The Data Controller will process the personal data collected through the use of the Site for the time strictly necessary to achieve the purposes for
        which they were collected and, after the latter, for any time established by the regulations in force.
      </p>
      <p>For more details, please refer to any information provided for particular services.</p>
      <h2>SCOPE OF DATA COMMUNICATION AND CATEGORIES OF RECIPIENTS</h2>
      <p>
        The Data Controller will not disclose the data, but intends to communicate them to internal figures authorized to process the processing based on their
        respective duties, as well as to external subjects to whom it is necessary to communicate the data. These recipients, should they process data on behalf
        of the Data Controller, will be designated as data processors with a specific contract or other legal act.
      </p>
      <p>For more details, please refer to the information sheets prepared for particular services.</p>
      <h2>TRANSFER OF DATA TO A THIRD COUNTRY AND / OR AN INTERNATIONAL ORGANIZATION</h2>
      <p>Your personal data will not be transferred to non-European third countries.</p>
      <h2>RIGHTS OF THE INTERESTED PARTIES</h2>
      <p>
        It is your right to ask the Data Controller to access your personal data and to rectify them if they are inaccurate, to delete them or limit their
        processing if the conditions are met, to oppose their processing for legitimate interests pursued by the Data Controller, as well as to obtain the
        portability of the data personally provided only if subject to automated processing based on consent or contract. You also have the right to revoke the
        consent given for the processing purposes that require it, without prejudice to the lawfulness of the processing carried out up to the time of
        revocation.
      </p>
      <p>
        To exercise your data subject’s rights, you can use the form available
        <a href="https://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/1089924" target="_blank">here</a>
        and forward it to the following address: <a href="mailto:info@nsbproject.com">info@nsbproject.com</a>. Finally, we remind you that you also have the
        right to lodge a complaint with the relevant supervisory authority, the Italian Data Protection Authority (<a
          href="http://www.garanteprivacy.it/"
          target="_blank">www.garanteprivacy.it</a
        >).
      </p>
      <p>Last updated: October 2020.</p>
      <p>
        This information may undergo changes: we therefore recommend that you check the date of the last revision. Any changes will take effect from the date of
        publication on the Site, but the changes deemed most significant will be communicated to you, where possible (for example, if you were subscribed to our
        newsletter service).
      </p>
    </div>
  </Container>
</Layout>

<style>
  .informative h2 {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
    font-size: 1.5rem;
  }
  .informative h3 {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
    font-size: 1.25rem;
  }
  .informative p {
    margin-bottom: 0.25rem;
  }
  .informative .subtitle {
    margin-bottom: 1rem;
  }
</style>
