<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Button from "./Button.svelte";
  import Input from "./Form/Fields/Input.svelte";
  import Icon from "./Icon.svelte";

  export let entitySingular: string = "";
  export let entityPlural: string = "";
  export let searchPlaceholder: string = "";
  export let numRecords: number = 0;
  export let numRecordsVisible: boolean = true;
  export let searchFieldVisible: boolean = true;
  export let searchValue: string = "";

  const dispatch = createEventDispatcher();

  function onClearSearch(_event: Event) {
    searchValue = "";
    dispatch("search", searchValue);
  }

  function onSearchClick(_event: Event) {
    dispatch("search", searchValue);
  }

  $: entityName = numRecords === 1 ? entitySingular : entityPlural;
  $: seachPlaceholderText = searchPlaceholder ? searchPlaceholder : "search " + entitySingular;
</script>

<nav class="level no-margin">
  <div class="level-left">
    {#if numRecordsVisible}
      <div class="level-item">
        <p class="subtitle is-5">
          <strong>
            {numRecords}
          </strong>
          {entityName}
        </p>
      </div>
    {/if}

    {#if searchFieldVisible}
      <div class="level-item">
        <div class="field has-addons">
          <p class="control has-icons-right" style="margin-bottom:0;">
            <Input name="" placeholder={seachPlaceholderText} bind:value={searchValue} on:enter={onSearchClick} />

            <span class="icon is-small is-right">
              <i class="delete" on:click={onClearSearch} />
            </span>
          </p>

          <p class="control">
            <Button on:click={onSearchClick}>
              <Icon name="search" />
              <span>Search</span>
            </Button>
          </p>
        </div>
      </div>
    {/if}

    <slot name="search" />
  </div>

  <slot name="additional" />
</nav>

<div>
  <slot />
</div>

<style>
  .no-margin {
    margin-bottom: 0;
  }
</style>
