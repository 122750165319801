<script lang="ts">
  import { get } from "svelte/store";
  import { navigate } from "../../app.logic";
  import Button from "../../components/Button.svelte";
  import Container from "../../components/Container.svelte";
  import Textarea from "../../components/Form/Fields/Textarea.svelte";
  import FormField from "../../components/Form/FormField.svelte";
  import FormLayout from "../../layout/FormLayout.svelte";
  import { approveRegistration, approveRegistrationUserOnly, record, saveRegistration } from "./settings-registration-requests.store";
  import TextLabel from "../../components/Form/TextLabel.svelte";
  import type { IRegistrationRequest } from "../../entities/registration-request";
  import ConfirmModal from "../../components/Modal/ConfirmModal.svelte";
  import ApproveRegistrationRequestModal from "../../components/Modal/ApproveRegistrationRequestModal.svelte";
  import { clfCountryPipe } from "../../classification.store";

  let approveModalOpen = false;
  let rejectModalOpen = false;

  $: canApprove = $record != null && ($record.processed == 0 || ($record.processed == 1 && $record.rejected == 1));
  $: canReject = $record != null && $record.processed == 0;

  function describeRequestStatus(r: IRegistrationRequest | null) {
    if (r == null) return "";
    if (r.processed == 1) {
      if (r.approved == 1) return "Approved";
      else return "Rejected";
    } else return "Pending (to approve or reject)";
  }

  function onApprove() {
    approveModalOpen = true;
  }

  function onReject() {
    rejectModalOpen = true;
  }

  async function closeDetails() {
    await saveRegistration();
    record.set(null);
  }

  function gotoUser() {
    navigate("/settings/users/" + get(record)!.userId);
  }

  function gotoOrganization() {
    navigate("/organizations/" + get(record)!.organizationId);
  }

  function confirmApproveAll() {
    approveRegistration();
    approveModalOpen = false;
  }

  function confirmApproveUser() {
    approveRegistrationUserOnly();
    approveModalOpen = false;
  }

  function declineApprove() {
    approveModalOpen = false;
  }

  function confirmReject() {
    rejectModalOpen = false;
  }

  function declineReject() {
    rejectModalOpen = false;
  }
</script>

<ApproveRegistrationRequestModal bind:open={approveModalOpen} on:createAll={confirmApproveAll} on:createUser={confirmApproveUser} on:cancel={declineApprove} />

<ConfirmModal
  title="Registration Request Rejection"
  message="Really reject this registration request? You will still be able to approve it within a few days."
  bind:open={rejectModalOpen}
  on:confirm={confirmReject}
  on:decline={declineReject}
/>

<Container>
  <div class="title">Registration Request</div>

  {#if $record}
    {#if canApprove && !canReject}
      <article class="message is-warning">
        <div class="message-body">
          <p>
            This registration request has been rejected.
            <br />
            The system will keep it for a few days, in which you can still approve it, if needed.
            <br />
            After a few days, this request will be automatically removed.
          </p>
        </div>
      </article>
    {/if}

    <FormLayout submitEnabled={false} cancelEnabled={false}>
      <div class="columns is-gapless">
        <div class="column">
          <FormField>
            <TextLabel value={$record.firstName + " " + $record.lastName} name="Requestor Name" />
          </FormField>

          <FormField>
            <TextLabel value={$record.email} name="e-mail" />
          </FormField>

          <FormField>
            <TextLabel value={describeRequestStatus($record)} name="Status" />
          </FormField>
        </div>

        <div class="column">
          <FormField>
            <TextLabel value={$record.organizationName} name="Organization" />
          </FormField>

          <FormField>
            <TextLabel value={$record.vatNumber} name="VAT Number" />
          </FormField>

          <FormField>
            <TextLabel value={$record.address + " " + clfCountryPipe($record.clfCountryId)} name="Organization Address" />
          </FormField>

          <FormField>
            <TextLabel value={$record.organizationDescription} name="Organization Description" />
          </FormField>
        </div>
      </div>

      <FormField>
        <Textarea placeholder="" disabled={false} bind:value={$record.internalNotes} name="Internal Notes" />
      </FormField>

      <hr class="hr" />

      <div class="field is-grouped">
        {#if canApprove}
          <div class="control btn-centered">
            <Button klass="is-success" on:click={onApprove}>Approve</Button>
          </div>
        {/if}

        {#if canReject}
          <div class="control btn-centered">
            <Button klass="is-danger" on:click={onReject}>Reject</Button>
          </div>
        {/if}

        {#if !!$record.userId}
          <div class="control btn-centered">
            <Button klass="is-primary" on:click={(_) => gotoUser()}>View User</Button>
          </div>
        {/if}

        {#if !!$record.organizationId}
          <div class="control btn-centered">
            <Button klass="is-primary" on:click={(_) => gotoOrganization()}>View Training Provider</Button>
          </div>
        {/if}

        <div class="control btn-centered">
          <Button klass="is-light" on:click={closeDetails}>Back</Button>
        </div>
      </div>
    </FormLayout>
  {/if}
</Container>

<style>
  .hr {
    border: 0;
    border-top: 1px solid #eee;
    margin: 20px 0;
  }

  .btn-centered {
    margin: 0 auto;
  }
</style>
