<script lang="ts">
  import { onMount } from "svelte";

  //import { createEventDispatcher } from "svelte";

  export let opacity: number = 1;
  export let borderRadius: string = "0";
  export let height: string = "auto";
  export let width: string = "auto";
  export let customStyle: string = "";
  export let src: string = "";
  export let disableCache: boolean = true;
  export let background: string = "";

  let visible: boolean = false;

  //const dispatch = createEventDispatcher();

  $: realUrl = disableCache ? src + "?_dc=" + new Date().getTime() : src;

  /*
  function onClick(event: Event) {
    if (handleClick) {
      dispatch("click", event);
    }
  }

  $: hoverable = handleClick || href != "";
  */

  function init() {
    visible = false;
  }

  function onLoadError() {
    visible = false;
  }

  function onLoadSuccess() {
    visible = true;
  }

  onMount(() => init());
</script>

<div style:border-radius={borderRadius} style:background style:height style:width>
  <!-- svelte-ignore a11y-missing-attribute -->
  <img
    class="image"
    class:img-hidden-1={!visible}
    style:border-radius={borderRadius}
    style:opacity
    style:height
    style:width
    style={customStyle}
    src={realUrl}
    on:error={(_) => onLoadError()}
    on:load={(_) => onLoadSuccess()}
  />
</div>

<style>
  .image {
    transition: 320ms;
    object-fit: cover;
    display: block;
  }

  .img-hidden-1 {
    visibility: hidden;
  }
</style>
