<script>
  import Layout from "../../layout/Layout.svelte";
  import SettingsMenuItem from "./SettingsMenuItem.svelte";
</script>

<Layout>
  <div class="columns" style="margin-top: 1rem;">
    <div class="column is-2">
      <nav class="settings-nav panel has-background-white is-primary">
        <p class="panel-heading">Settings Menu</p>

        <SettingsMenuItem label="Manage Users" url="/settings/users" />
        <SettingsMenuItem label="Registration Requests" url="/settings/registration-requests" />
        <SettingsMenuItem label="Deletion Requests" url="/settings/deletion-requests" />
        <SettingsMenuItem label="Customise System Data" url="/settings/system-data" />
      </nav>
    </div>

    <div class="column is-10 nopad">
      <div>
        <slot />
      </div>
    </div>
  </div>
</Layout>

<style>
  .nopad {
    padding: 0;
  }
</style>
