import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
//import type { IRegionSettings } from '../entities/region-settings';
import { parseDate } from '../utils/date-utils';
import { BaseService } from './base-service';
export class PortalUserService {
    static getPortalUsers(filter, pageNumber) {
        let qs = encodeURIComponent(JSON.stringify(filter));
        // return BaseService.getPaginated<IPortalUserDTO[]>(`portal-users`, pageNumber);
        return lastValueFrom(BaseService.getPaginated(`portal-users?filter=${qs}`, pageNumber)
            .pipe(map(w => {
            w.payload.forEach(x => { x.lastLoginOn = parseDate(x.lastLoginOn); });
            return w;
        })));
    }
    static fetchPortalUser(oid) {
        return lastValueFrom(BaseService.get(`portal-users/${oid}`));
    }
    static savePortalUser(dto) {
        return lastValueFrom(BaseService.save(`portal-users`, dto));
    }
    static deletePortalUser(oid) {
        return lastValueFrom(BaseService.delete(`portal-users/${oid}`));
    }
    static resetPassword(dto) {
        return lastValueFrom(BaseService.post(`portal-users/reset-password`, dto));
    }
    // ----------------------------------------------------------------------
    // Search Dropdown
    // ----------------------------------------------------------------------
    // public static searchPortalUserCombo(selfId: number, search: string) {
    //   let qs = encodeURIComponent(search);
    //   return lastValueFrom(BaseService.get<IDropdownItem[]>(`portal-users-combo/${selfId}?search=${qs}`));
    // }
    // ----------------------------------------------------------------------
    // Roles
    // ----------------------------------------------------------------------
    static changeRole(dto) {
        return lastValueFrom(BaseService.post(`portal-users/change-role`, dto));
    }
    static getPortalRoles() {
        return lastValueFrom(BaseService.get(`portal-roles`));
    }
    // ----------------------------------------------------------------------
    // User Organizations
    // ----------------------------------------------------------------------
    static getPortalUserOrganizations(userId) {
        return lastValueFrom(BaseService.get(`portal-user-organizations/${userId}`));
    }
    static savePortalUserOrganization(dto) {
        return lastValueFrom(BaseService.save(`portal-user-organizations`, dto));
    }
    static deletePortalUserOrganization(oid) {
        return lastValueFrom(BaseService.delete(`portal-user-organizations/${oid}`));
    }
    // ----------------------------------------------------------------------
    // Self Region Settings
    // ----------------------------------------------------------------------
    static fetchSelfRegionSettings() {
        //return lastValueFrom(BaseService.get<IRegionSettings>(`self-region-settings`));
        return null;
    }
    // ----------------------------------------------------------------------
    // Self User
    // ----------------------------------------------------------------------
    static fetchSelf() {
        return lastValueFrom(BaseService.get(`portal-users/me`));
    }
    static saveSelf(dto) {
        return lastValueFrom(BaseService.save(`portal-users/me`, dto));
    }
    static selfChangePassword(dto) {
        return lastValueFrom(BaseService.post(`portal-users/me/change-password`, dto));
    }
}
