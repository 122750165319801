import { describeRole } from "../../pages/Profile/profile.store";
import { formatTimestampHuman } from "../date-utils";
export const portalUserDtoFields = [{
        name: "fullName",
        title: "Full Name",
        titleClass: "",
        dataClass: ""
    }, {
        name: "email",
        title: "e-mail",
        titleClass: "",
        dataClass: ""
    }, {
        name: "portalRoleCode",
        title: "Role",
        titleClass: "has-text-centered",
        dataClass: "has-text-centered",
        render: (record) => describeRole(record.portalRoleCode)
    }, {
        name: "lastLoginOn",
        title: "Last Login",
        titleClass: "has-text-centered",
        dataClass: "has-text-centered",
        render: (record) => formatTimestampHuman(record.lastLoginOn)
    }, {
        name: "daysToExpiration",
        title: "Days to Expiration",
        titleClass: "has-text-centered",
        dataClass: "has-text-centered"
    }, {
        name: "active",
        title: "Active",
        titleClass: "has-text-centered",
        dataClass: "has-text-centered",
        render: (record) => `<i class="material-icons">${record.active === 1 ? "check" : "close"}</i>`
    }];
