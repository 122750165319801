import { derived, get, writable } from 'svelte/store';
import { SearchService } from '../../services/search.service';
//import type { IDropdownItem } from '../../services/DTO/dropdown-item';
//import { ClassificationService } from '../../services/classification.service';
import { clfTechArea } from '../../classification.store';
export const loading = writable(false);
export const filter = writable({ criteria: {} });
export const records = writable([]);
export const pagination = writable(null);
export const allCountries = writable(1);
//export const clfTechAreaItems = writable<IDropdownItem[]>([]);
export const techAreaSelIds = derived(filter, $filter => !!$filter && !!$filter.criteria && !!$filter.criteria.techAreaIds ? $filter.criteria.techAreaIds : []);
export const techAreasSelectedItems = derived([clfTechArea, techAreaSelIds], ([$items, $ids]) => $items.filter(value => $ids.findIndex(id => id == value.oid) !== -1));
export const techAreasSelectableItems = derived([clfTechArea, techAreaSelIds], ([$items, $ids]) => $items.filter(value => $ids.findIndex(id => id == value.oid) == -1));
//$ids.findIndex((val) => $items.map(x => x.oid).findIndex((v) => v == val) != -1));
//export const techAreasSelectedItems = writable<IDropdownItem[]>([]);
export async function initList() {
    // filter.set({ criteria: <ISearchCoursesRequestDTO>{}, params: null, sorting: "" });
    // pagination.set({ currentPage: 1, totalPages: 0, totalRecords: 0 });
    // allCountries.set(1);
    // await load();
    await clear();
}
export async function search() {
    pagination.set({ currentPage: 1, totalPages: 0, totalRecords: 0 });
    await load();
}
export async function clear() {
    let criteria = {
        title: '',
        techAreaIds: [],
        channelIds: [],
        languageIds: [],
        countryId: undefined,
        startMonth: 0,
        startYear: 0,
        endMonth: 0,
        endYear: 0
    };
    filter.set({ criteria: criteria, params: null, sorting: "" });
    pagination.set({ currentPage: 1, totalPages: 0, totalRecords: 0 });
    allCountries.set(1);
    await load();
}
export async function load() {
    loading.set(true);
    try {
        let _filter = get(filter);
        let _pag = get(pagination);
        let _resp = await SearchService.searchOrganizations(_filter, _pag.currentPage);
        records.set(_resp.payload);
        pagination.set(_resp.pagination);
        // load clf tech areas
        //await loadClfTechAreas();
    }
    finally {
        loading.set(false);
    }
}
// export async function loadClfTechAreas() {
//   let _resp = await ClassificationService.getAllValuesCombo("TECHAREAXX");
//   clfTechAreaItems.set(_resp.payload);
// }
export function onPageChange(pageNumber) {
    // console.log("onPageChange: " + pageNumber);
    pagination.set(Object.assign(Object.assign({}, get(pagination)), { currentPage: pageNumber }));
    load();
}
export function toggleAllCountries(evt) {
    let value = evt.detail;
    if (value == 1) {
        filter.set(Object.assign(Object.assign({}, get(filter)), { criteria: Object.assign(Object.assign({}, get(filter).criteria), { countryId: 0 }) }));
        allCountries.set(1);
    }
    else {
        allCountries.set(0);
    }
}
export function onAddTechArea(evt) {
    let techAreaId = evt.detail;
    let _filter = get(filter);
    //console.log("store.onAddTechArea: " + techAreaId);
    if (_filter.criteria.techAreaIds.findIndex(val => val == techAreaId) == -1)
        _filter.criteria.techAreaIds.push(techAreaId);
    filter.set(Object.assign({}, _filter));
    //console.log("store.onAddTechArea result: ", _filter.criteria.techAreaIds);
}
export function onDelTechArea(evt) {
    let techAreaId = evt.detail;
    let _filter = get(filter);
    let idx = _filter.criteria.techAreaIds.findIndex(val => val == techAreaId);
    //console.log("store.onDelTechArea: " + techAreaId);
    if (idx != -1)
        _filter.criteria.techAreaIds.splice(idx, 1);
    filter.set(Object.assign({}, _filter));
    //console.log("store.onDelTechArea result: ", _filter.criteria.techAreaIds);
}
