<script lang="ts">
  import { onMount } from "svelte";
  import LayoutOrganizationDetail from "./LayoutOrganizationDetail.svelte";
  import { loading, maybeInitDetail, OrganizationDetailPage, record as organization } from "./organizations.store";
  import FormLayout from "../../layout/FormLayout.svelte";
  import FormField from "../../components/Form/FormField.svelte";
  import ImageUpload from "../../components/ImageUpload.svelte";
  import Switch from "../../components/Form/Fields/Switch.svelte";
  import Input from "../../components/Form/Fields/Input.svelte";
  import Textarea from "../../components/Form/Fields/Textarea.svelte";
  import DateInput from "../../components/Form/Fields/DateInput.svelte";
  import {
    record as course,
    techAreaSelIds,
    onAddCourseTechArea,
    onDelCourseTechArea,
    channelSelIds,
    onAddCourseChannel,
    onDelCourseChannel,
    languageSelIds,
    onAddCourseLanguage,
    onDelCourseLanguage,
    eduLevelSelIds,
    onAddCourseEduLevel,
    onDelCourseEduLevel,
    uploadLogo,
    logoFile,
    logoDc,
    initDetail,
    save,
  } from "./organizations-courses.store";
  import { BASE_API_URL } from "../../utils/constants";
  import { navigate } from "../../app.logic";
  import { dateFromISO, dateToISO } from "../../utils/date-utils";
  import ClfTagSelector from "../../components/Business/ClfTagSelector.svelte";
  import ClfDropdown from "../../components/Form/Fields/Dropdown/ClfDropdown.svelte";
  import { get } from "svelte/store";

  export let orgId: number;
  export let courseId: number;

  $: canSubmit = $course != null && $course.titleEn != "" && $course.clfDurationId != 0;

  async function submitForm() {
    let isNew = courseId <= 0;

    await save();

    if (!isNew) {
      cancelForm();
    } else {
      navigate("/organizations/" + orgId + "/courses/" + get(course)!.oid);
    }
  }

  function cancelForm() {
    navigate("/organizations/" + orgId + "/courses");
  }

  onMount(() => {
    maybeInitDetail(orgId);
    initDetail(courseId, orgId);
  });
</script>

<LayoutOrganizationDetail page={OrganizationDetailPage.OrganizationCourse} organization={$organization} whiteBox={true} loading={$loading}>
  {#if $course}
    <FormLayout on:submit={submitForm} on:cancel={cancelForm} {canSubmit} isNew={$course.oid == 0}>
      <div class="columns">
        <div class="column is-3">
          {#if $course.oid != 0}
            <FormField usageHelp="upload course logo (png, jpg)">
              <ImageUpload
                on:fileUpload={uploadLogo}
                disabled={false}
                value={$logoFile}
                dc={$logoDc}
                previewUrl={BASE_API_URL + "/course-logo/" + $course.oid}
                previewHeight="8rem"
                name="Logo"
              />
            </FormField>
          {/if}
        </div>

        <div class="column approval-flag">
          <div class="title">
            {#if $course.oid == 0}
              New Course
            {:else}
              Course: {$course.titleEn}
            {/if}
          </div>

          <FormField usageHelp="published courses are visible on map">
            <Switch disabled={false} bind:value={$course.published} name="Course published" />
          </FormField>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <FormField usageHelp="course title in English language (200 characters)">
            <Input placeholder="course english title" maxlength={200} disabled={false} required={true} bind:value={$course.titleEn} name="English Title" />
          </FormField>

          <FormField usageHelp="course title in original language (200 characters)">
            <Input placeholder="course original title" maxlength={200} disabled={false} bind:value={$course.title} name="Original Title" />
          </FormField>

          <FormField usageHelp="short description of course objective (400 characters)">
            <Textarea
              placeholder="course objective (max 400 characters)"
              maxlength={400}
              disabled={false}
              bind:value={$course.objective}
              name="Course Objective"
            />
          </FormField>

          <FormField usageHelp="course long description (unlimited length)">
            <Textarea placeholder="course description" disabled={false} bind:value={$course.description} name="Course Description" />
          </FormField>

          <FormField usageHelp="choose whether course can be activated on demand">
            <Switch disabled={false} bind:value={$course.onDemand} name="Course on demand" />
          </FormField>

          <FormField usageHelp="choose whether course is availabe on specific dates (start/end dates)">
            <Switch disabled={false} bind:value={$course.specificDates} name="Course specific dates" />
          </FormField>

          {#if $course.specificDates == 1}
            <FormField usageHelp="course start date">
              <DateInput
                placeholder="course start date"
                disabled={false}
                value={dateFromISO($course.startDate)}
                on:valueSelected={(v) => ($course.startDate = dateToISO(v.detail))}
                name="Start Date"
              />
            </FormField>

            <FormField usageHelp="course end date">
              <DateInput
                placeholder="course end date"
                disabled={false}
                value={dateFromISO($course.endDate)}
                on:valueSelected={(v) => ($course.endDate = dateToISO(v.detail))}
                name="End Date"
              />
            </FormField>
          {/if}

          <FormField usageHelp="choose most appropriate course duration">
            <ClfDropdown placeholder="duration" schema="COURSEDRTN" disabled={false} required={true} bind:value={$course.clfDurationId} name="Duration" />
          </FormField>
        </div>

        <div class="column">
          <FormField usageHelp="course website (200 characters)">
            <Input placeholder="course website" maxlength={200} disabled={false} bind:value={$course.website} name="Course WebSite" />
          </FormField>

          <FormField usageHelp="course specific contact e-mail address (100 characters)">
            <Input placeholder="course contact e-mail" maxlength={100} disabled={false} bind:value={$course.contactEmail} name="Course contact e-mail" />
          </FormField>

          <FormField usageHelp="course video presentation url (e.g. link to YouTube or Vimeo or other video resource) (200 characters)">
            <Input
              placeholder="course video presentation url"
              maxlength={200}
              disabled={false}
              bind:value={$course.videoUrl}
              name="Course video presentation"
            />
          </FormField>

          {#if $course.oid != 0}
            <FormField usageHelp="select appropriate course channels">
              <ClfTagSelector
                placeholder="course channel"
                disabled={false}
                schema="COURSETRCH"
                selectedClfIds={channelSelIds}
                name="Course Channels"
                on:add={onAddCourseChannel}
                on:del={onDelCourseChannel}
              />
            </FormField>

            <FormField usageHelp="select appropriate course languages">
              <ClfTagSelector
                placeholder="course language"
                disabled={false}
                schema="COURSELANG"
                selectedClfIds={languageSelIds}
                name="Course Languages"
                on:add={onAddCourseLanguage}
                on:del={onDelCourseLanguage}
              />
            </FormField>

            <FormField usageHelp="select appropriate course topics">
              <ClfTagSelector
                placeholder="course topics"
                disabled={false}
                schema="TECHAREAXX"
                selectedClfIds={techAreaSelIds}
                name="Course Topics"
                on:add={onAddCourseTechArea}
                on:del={onDelCourseTechArea}
              />
            </FormField>

            <FormField usageHelp="select appropriate course educational entry levels">
              <ClfTagSelector
                placeholder="education entry level"
                disabled={false}
                schema="COURSEEDLV"
                selectedClfIds={eduLevelSelIds}
                name="Course Education Entry Level"
                on:add={onAddCourseEduLevel}
                on:del={onDelCourseEduLevel}
              />
            </FormField>

            <!--
        <FormField usageHelp="select appropriate course skills to be acquired">
          <TagSelector
            errors={Api.errorsOf("clfCourseSkillId", courseStatus)}
            placeholder="skills to be acquired"
            selectableItems={clfCourseSkill}
            items={courseSkillsAsItems}
            onAdd={addCourseSkill}
            onDel={delCourseSkill}
            disabled={false}
            name="Skills to be acquired"/>
        </FormField>
        -->
          {/if}
        </div>
      </div>

      <hr class="hr" />

      <div class="columns">
        <div class="column is-12">
          <FormField usageHelp="internal notes, not visible to end users (unlimited length)">
            <Textarea placeholder="" disabled={false} bind:value={$course.internalNotes} name="Internal Notes (not visible to users)" />
          </FormField>
        </div>
      </div>
    </FormLayout>
  {/if}
</LayoutOrganizationDetail>

<style>
  .title {
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 22px;
  }

  .hr {
    border: 0;
    border-top: 1px solid #eee;
    margin: 20px 0;
  }
</style>
