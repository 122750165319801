import { lastValueFrom } from 'rxjs';
import { BaseService } from './base-service';
export class CourseService {
    static getCoursesByOrganization(organizationId) {
        return lastValueFrom(BaseService.get(`courses-by-organization/${organizationId}`));
    }
    static fetchCourse(oid) {
        return lastValueFrom(BaseService.get(`courses/${oid}`));
    }
    static saveCourse(dto) {
        return lastValueFrom(BaseService.save(`courses`, dto));
    }
    static deleteCourse(oid) {
        return lastValueFrom(BaseService.delete(`courses/${oid}`));
    }
    // ----------------------------------------------------------------------
    // Course Channels
    // ----------------------------------------------------------------------
    static getCourseChannels(courseId) {
        return lastValueFrom(BaseService.get(`course-channels/${courseId}`));
    }
    static saveCourseChannel(dto) {
        return lastValueFrom(BaseService.save(`course-channels`, dto));
    }
    static deleteCourseChannel(oid) {
        return lastValueFrom(BaseService.delete(`course-channels/${oid}`));
    }
    // ----------------------------------------------------------------------
    // Course Languages
    // ----------------------------------------------------------------------
    static getCourseLanguages(courseId) {
        return lastValueFrom(BaseService.get(`course-languages/${courseId}`));
    }
    static saveCourseLanguage(dto) {
        return lastValueFrom(BaseService.save(`course-languages`, dto));
    }
    static deleteCourseLanguage(oid) {
        return lastValueFrom(BaseService.delete(`course-languages/${oid}`));
    }
    // ----------------------------------------------------------------------
    // Course Tech Area
    // ----------------------------------------------------------------------
    static getCourseTechAreas(courseId) {
        return lastValueFrom(BaseService.get(`course-tech-areas/${courseId}`));
    }
    static saveCourseTechArea(dto) {
        return lastValueFrom(BaseService.save(`course-tech-areas`, dto));
    }
    static deleteCourseTechArea(oid) {
        return lastValueFrom(BaseService.delete(`course-tech-areas/${oid}`));
    }
    // ----------------------------------------------------------------------
    // Course Education Level
    // ----------------------------------------------------------------------
    static getCourseEducationLevels(courseId) {
        return lastValueFrom(BaseService.get(`course-education-levels/${courseId}`));
    }
    static saveCourseEducationLevel(dto) {
        return lastValueFrom(BaseService.save(`course-education-levels`, dto));
    }
    static deleteCourseEducationLevel(oid) {
        return lastValueFrom(BaseService.delete(`course-education-levels/${oid}`));
    }
    // ----------------------------------------------------------------------
    // Course Skills
    // ----------------------------------------------------------------------
    static getCourseSkills(courseId) {
        return lastValueFrom(BaseService.get(`course-skills/${courseId}`));
    }
    static saveCourseSkill(dto) {
        return lastValueFrom(BaseService.save(`course-skills`, dto));
    }
    static deleteCourseSkill(oid) {
        return lastValueFrom(BaseService.delete(`course-skills/${oid}`));
    }
    // ----------------------------------------------------------------------
    // Course Logo
    // ----------------------------------------------------------------------
    static uploadCourseLogo(courseId, logo) {
        return lastValueFrom(BaseService.uploadFile(`course-logo-upload/${courseId}`, logo));
    }
}
