import { dateFromISO, formatDateHuman } from "../date-utils";
export const organizationExpiringCourseDtoFields = [{
        name: "organizationName",
        title: "Organization Name",
        titleClass: "min-w-13",
        dataClass: "",
        render: (record) => `<a href="/organizations/${record.organizationId}">${record.organizationName}</a>`
    }, {
        name: "courseName",
        title: "Course Name",
        titleClass: "",
        dataClass: "",
        render: (record) => `<a href="/organizations/${record.organizationId}/courses/${record.courseId}">${record.courseName}</a>`
    }, {
        name: "courseEndDate",
        title: "Course Expiring Date",
        titleClass: "",
        dataClass: "",
        render: (record) => record.courseEndDate != null && record.courseEndDate.trim() != "" ? formatDateHuman(dateFromISO(record.courseEndDate)) : ""
    }];
