<script lang="ts">
  import type { IDropdownItem } from "../../services/DTO/dropdown-item";
  import { createEventDispatcher, onMount } from "svelte";
  import SearchDropdown from "../Form/Fields/Dropdown/SearchDropdown.svelte";
  // import { ClassificationService } from "../../services/classification.service";
  // import ClfDropdown from "../Form/Fields/Dropdown/ClfDropdown.svelte";

  export let tagClass: string = "is-primary";
  export let showEmptyMessage: boolean = true;
  export let emptyMessage: string = "There are no items";
  export let placeholder: string = "";
  export let maxTextSize: string = "250px";
  export let disabled: boolean = false;
  export let small: boolean = false;
  export let fullWidth: boolean = false;
  export let name: string = "";
  //export let errors: string[] = [];
  export let selectableItems: IDropdownItem[] = [];
  export let items: IDropdownItem[] = [];

  $: fullWidthClass = fullWidth ? "is-expanded" : "";

  let dummyValue: number = 0;

  const dispatch = createEventDispatcher();

  // async function load() {
  //   let values = await ClassificationService.searchValueCombo(schema, -1, "");
  //   selectableItems = values.payload || [];
  // }

  function handleAdd(evt: CustomEvent<IDropdownItem>) {
    if (!!evt && !!evt.detail) {
      dispatch("add", evt.detail.oid);
    }
    dummyValue = 0;
  }

  function handleDel(oid: number) {
    dispatch("del", oid);
    dummyValue = 0;
  }

  // onMount(() => load());
</script>

<div class={"control" + fullWidthClass}>
  <!-- <Dropdown {errors} {placeholder} {fullWidth} {small} items={selectableItems} onChange={handleAdd} {disabled} {value} {name} /> -->
  <SearchDropdown {placeholder} {small} items={selectableItems} {disabled} {name} filterVisible={false} on:select={handleAdd} bind:value={dummyValue} />
  <!-- <ClfDropdown {placeholder} {small} {schema} {disabled} {name} on:select={handleAdd} /> -->

  <div class="tagsContainer control">
    <div class="tags">
      {#each items as item}
        <span class={"tag " + tagClass}>
          <span class="truncateText" style:max-width={maxTextSize} title={item.label}>{item.label}</span>
          <button class="delete is-small" on:click={(_) => handleDel(item.oid)} />
        </span>
      {/each}

      {#if showEmptyMessage && items.length == 0}
        <article class="message is-primary is-small">
          <div class="message-body">{emptyMessage}</div>
        </article>
      {/if}
    </div>
  </div>
</div>

<style>
  .tagsContainer {
    margin-top: 1rem;
  }

  .truncateText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
