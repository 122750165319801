<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let name: string = "";
  export let href: string = "";
  export let target: string = "_self";
  export let klass: string = "";
  export let customIconStyle: string = "";
  export let handleClick: boolean = true;

  const dispatch = createEventDispatcher();

  function onClick(event: Event) {
    if (handleClick) {
      dispatch("click", event);
    }
  }

  $: hoverable = handleClick || href != "";
</script>

<span class="icon {klass}" style={customIconStyle}>
  {#if href == ""}
    {#if handleClick == false}
      <i class="material-icons icon-base">
        {name}
      </i>
    {:else}
      <i class="material-icons icon-base" class:hoverable class:icon-cursor-pointer={hoverable} on:click={onClick}>
        {name}
      </i>
    {/if}
  {:else if handleClick == false}
    <a class="material-icons icon-base" class:hoverable class:icon-cursor-pointer={hoverable} {href} {target}>
      {name}
    </a>
  {:else}
    <a class="material-icons icon-base" class:hoverable class:icon-cursor-pointer={hoverable} {href} {target} on:click={onClick}>
      {name}
    </a>
  {/if}
</span>

<style>
  .icon-base {
    cursor: default;
    width: 24px;
  }
  .icon-cursor-pointer {
    cursor: pointer;
  }
</style>
