export const organizationConnectionDtoFields = [{
        name: "nameEn",
        title: "Connected Training Provider",
        titleClass: "min-w-13",
        dataClass: "",
        render: (record) => record.connectedOrganization.nameEn
    }, {
        name: "country",
        title: "Country",
        titleClass: "",
        dataClass: "",
        render: (record) => record.country
    }, {
        name: "description",
        title: "Description",
        titleClass: "",
        dataClass: "",
        render: (record) => record.connectionDescription
    }];
