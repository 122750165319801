import { writable, derived } from 'svelte/store';
export const user = writable(null);
export const sid = writable("");
export const isUserLoggedIn = derived(user, $u => $u != null);
export const isUserRoleADM = derived(user, $u => ($u === null || $u === void 0 ? void 0 : $u.roles) && $u.roles.find(x => x === 'ADM') !== undefined);
export const isUserRolePAR = derived(user, $u => ($u === null || $u === void 0 ? void 0 : $u.roles) && $u.roles.find(x => x === 'PAR') !== undefined);
export const isUserRoleORG = derived(user, $u => ($u === null || $u === void 0 ? void 0 : $u.roles) && $u.roles.find(x => x === 'ORG') !== undefined);
export const mapMonth = writable(initMapMonth());
export const mapYear = writable(initMapYear());
function initMapMonth() {
    return [
        { oid: 1, label: "January" },
        { oid: 2, label: "February" },
        { oid: 3, label: "March" },
        { oid: 4, label: "April" },
        { oid: 5, label: "May" },
        { oid: 6, label: "June" },
        { oid: 7, label: "July" },
        { oid: 8, label: "August" },
        { oid: 9, label: "September" },
        { oid: 10, label: "October" },
        { oid: 11, label: "November" },
        { oid: 12, label: "December" }
    ];
}
function initMapYear() {
    let year = (new Date()).getFullYear();
    return [-1, 0, 1].map(i => ({ oid: year + i, label: "" + (year + i) }));
}
