<script lang="ts">
  export let label: string;
  export let url: string;

  $: currentUrlActive = window.location.pathname.startsWith(url);
</script>

<a href={url} class="panel-block" class:is-active={currentUrlActive}>
  {label}
</a>
