import { get, writable } from 'svelte/store';
import { ClassificationService } from '../../services/classification.service';
import { clfForceReload } from '../../classification.store';
export const loading = writable(false);
export const editing = writable(false);
export const selectedSchema = writable(0);
export const canEdit = writable(true);
export const schemas = writable([]);
export const schemaItems = writable([]);
export const records = writable([]);
export const record = writable(null);
let initialized = false;
export async function init() {
    if (!initialized) {
        initialized = true;
        editing.set(false);
        selectedSchema.set(0);
        canEdit.set(true);
        schemas.set([]);
        records.set([]);
        record.set(null);
        await loadSchemas();
    }
}
export async function loadSchemas() {
    let data = await ClassificationService.getSchemas();
    schemas.set(data.payload);
    let items = data.payload.map(y => ({ oid: y.oid, code: y.code, label: y.description }));
    schemaItems.set(items);
}
export async function loadSchemaValues(schema) {
    let data = await ClassificationService.getValues(schema);
    records.set(data.payload.map(x => (Object.assign(Object.assign({}, x), { code: x.code.trim() }))));
}
export async function reloadSchemaValues() {
    let schemaId = get(selectedSchema);
    let schema = get(schemas).find((x) => x.oid == schemaId);
    loadSchemaValues(schema.code);
}
export function onCreate() {
    // console.log("onCreate");
    let item = {
        oid: 0,
        code: '',
        description: '',
        displayOrder: 1,
        schemaId: get(selectedSchema)
    };
    record.set(item);
    editing.set(true);
}
export function onEdit(item) {
    // console.log("onEdit", item);
    record.set(Object.assign({}, item));
    editing.set(true);
}
export async function save() {
    let _record = get(record);
    await ClassificationService.saveValue(_record);
    await reloadSchemaValues();
    await clfForceReload();
}
export async function onDelete(item) {
    // console.log("onDelete", item);
    await ClassificationService.deleteValue(item.oid);
    await reloadSchemaValues();
    await clfForceReload();
}
