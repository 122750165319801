// import { BehaviorSubject } from 'rxjs';
// import { tap } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { BaseService } from './base-service';
export class ClassificationService {
    // classification: BehaviorSubject<IClfValue> = new BehaviorSubject<IClfValue>(null);
    // schemas: BehaviorSubject<IClfSchema[]> = new BehaviorSubject<IClfSchema[]>([]);
    // // Share classification data between routes
    // public selectClassification(classification: IClfValue) {
    //   this.classification.next(classification);
    // }
    // ----------------------------------------------------------------------
    // Classifications
    // ----------------------------------------------------------------------
    static getSchemas() {
        return lastValueFrom(BaseService.get(`classifications/schemas`));
    }
    // public static fetchValue(oid: number) {
    //   return lastValueFrom(BaseService.get<IClfValue>(`classifications/value/${oid}`));
    // }
    static getValues(schema) {
        return lastValueFrom(BaseService.get(`classifications/values/${schema}`));
        // .pipe(tap(data => data.payload.forEach(v => GenericHelper.parseStandardDates(v))));
    }
    static saveValue(item) {
        //this.clfStore.flush();
        return lastValueFrom(BaseService.save(`classifications/values`, item));
        // .pipe(tap(data => GenericHelper.parseStandardDates(data.payload)));
    }
    static deleteValue(oid) {
        // this.clfStore.flush();
        // return BaseService.delete<IClfValue>(`fe/classifications/values/${oid}`);
        return lastValueFrom(BaseService.delete(`classifications/values/${oid}`));
    }
    static searchValueCombo(schema, selfId, search) {
        let qs = encodeURIComponent(search);
        return lastValueFrom(BaseService.get(`classifications/search-values/${schema}/${selfId}?search=${qs}`));
    }
    static getAllValuesCombo(schema) {
        return lastValueFrom(BaseService.get(`classifications/all-values/${schema}`));
    }
}
