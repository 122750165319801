<script lang="ts">
  import { get, type Unsubscriber, type Writable } from "svelte/store";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import type { ISearchCoursesPoiDTO } from "../../../services/DTO/search-courses-poi-dto";
  import PoiDetail from "./PoiDetail.svelte";
  import ImageAsset from "../../ImageAsset.svelte";
  import type { ISearchCoursesDetailDTO } from "../../../services/DTO/search-courses-detail-dto";
  import { IMG_BASE_URL } from "../../../utils/constants";
  import { AtlasMap } from "./map";

  export let poiItems: Writable<ISearchCoursesPoiDTO[]>;
  export let detail: Writable<ISearchCoursesDetailDTO | null>;

  const dispatch = createEventDispatcher();

  let map: AtlasMap;
  let winWidth: number;
  let winHeight: number;
  let unsubscriber: Unsubscriber;

  //$: refreshMap();

  function startMap() {
    map = new AtlasMap({ imgBaseUrl: IMG_BASE_URL, onPoiClick: onPoiClick });
    unsubscriber = poiItems.subscribe((pois) => map.addPois(pois));
  }

  function onPoiClick(oid: number) {
    dispatch("poiClick", oid);
  }

  function onDetailClick(event: CustomEvent<ISearchCoursesDetailDTO>) {
    //navigate("/map/detail");
    dispatch("detailClick", event.detail);
  }

  onMount(() => startMap());
  onDestroy(() => {
    if (unsubscriber != null) unsubscriber();
  });
</script>

<svelte:window bind:innerWidth={winWidth} bind:innerHeight={winHeight} />

<div class="mapHost">
  <div id="map" class="map" style:width={winWidth - 300 + "px"} style:height={winHeight - 56 + "px"}>
    <div id="popup" class="ol-popup popup">
      <div class="popupPinImage">
        {#if $detail != null}
          {#if $detail.digitalInnovationHub == 1}
            <ImageAsset name="pin-selected-blue.png" />
          {:else}
            <ImageAsset name="pin-selected-green.png" />
          {/if}
        {/if}
      </div>

      <!-- svelte-ignore a11y-invalid-attribute -->
      <!-- svelte-ignore a11y-missing-content -->
      <a href="#" id="popup-closer" class="ol-popup-closer" />
      <div id="popup-content" class="has-text-centered" style="min-width: 250px;">
        {#if $detail != null}
          <PoiDetail poiDetail={$detail} on:detailClick={onDetailClick} />
        {/if}
      </div>
    </div>
  </div>
</div>

<style>
  .mapHost {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .map {
    /* if you enable this, also enable .ol-viewport in css file */
    /* flex: 1; */
    /* flex-grow: 1; */
    /* display: flex; */
    /* align-items: stretch; */
    /* position: relative; */
    margin-left: 300px;
    /*height: #{Number.toString(Window.height() - 56) + "px"};*/
    /*width: #{Number.toString(Window.width() - 300) + "px"};*/
  }

  .popup {
    position: relative;
    /* border-radius: 10px; */
    background-color: #fff;
    /* opacity: 0.8; */
    box-shadow: 2px 2px 6px #ccc;
    padding: 1rem;
  }

  .popupPinImage {
    position: absolute;
    left: -64px;
    top: 0;
    padding: 0;
    margin: 0;
    width: 108px;
    height: 115px;
  }
</style>
