<script lang="ts">
  import type { IDropdownItem } from "../../services/DTO/dropdown-item";
  import { createEventDispatcher, onMount } from "svelte";
  import { derived, writable, type Readable } from "svelte/store";
  import { ClassificationService } from "../../services/classification.service";
  import TagSelector from "./TagSelector.svelte";

  export let tagClass: string = "is-primary";
  export let showEmptyMessage: boolean = true;
  export let emptyMessage: string = "There are no items";
  export let placeholder: string = "";
  export let maxTextSize: string = "250px";
  export let disabled: boolean = false;
  export let small: boolean = false;
  export let fullWidth: boolean = false;
  export let name: string = "";
  //export let errors: string[] = [];
  export let schema: string = "";
  export let selectedClfIds: Readable<number[]>;

  const dispatch = createEventDispatcher();

  const clfItems = writable<IDropdownItem[]>([]);
  const selectedItems = derived([clfItems, selectedClfIds], ([$items, $ids]) => $items.filter((value) => $ids.findIndex((id) => id == value.oid) !== -1));
  const selectableItems = derived([clfItems, selectedClfIds], ([$items, $ids]) => $items.filter((value) => $ids.findIndex((id) => id == value.oid) == -1));

  async function load() {
    let _resp = await ClassificationService.getAllValuesCombo(schema);
    clfItems.set(_resp.payload);
  }

  function handleAdd(evt: CustomEvent<number>) {
    dispatch("add", evt.detail);
  }

  function handleDel(evt: CustomEvent<number>) {
    dispatch("del", evt.detail);
  }

  onMount(() => load());
</script>

<TagSelector
  {tagClass}
  {showEmptyMessage}
  {emptyMessage}
  {placeholder}
  {maxTextSize}
  {disabled}
  {small}
  {fullWidth}
  {name}
  items={$selectedItems}
  selectableItems={$selectableItems}
  on:add={handleAdd}
  on:del={handleDel}
/>
