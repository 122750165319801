<script lang="ts">
  export let customStyle = "";
</script>

<div class="container container-base" style={customStyle}>
  <slot />
</div>

<style>
  .container-base {
    padding: 1rem 2rem;
    max-width: unset !important;
    margin: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
</style>
