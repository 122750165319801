<script lang="ts">
  import LayoutSettings from "./LayoutSettings.svelte";
  import Container from "../../components/Container.svelte";
  import {
    canEdit,
    editing,
    init,
    loading,
    loadSchemaValues,
    onCreate,
    onDelete,
    onEdit,
    record,
    records,
    save,
    schemaItems,
    schemas,
    selectedSchema,
  } from "./settings-data.store";
  import FormLayout from "../../layout/FormLayout.svelte";
  import FormField from "../../components/Form/FormField.svelte";
  import Input from "../../components/Form/Fields/Input.svelte";
  import NumberInput from "../../components/Form/Fields/NumberInput.svelte";
  import type { IDropdownItem } from "../../services/DTO/dropdown-item";
  import { onMount } from "svelte";
  import SearchDropdown from "../../components/Form/Fields/Dropdown/SearchDropdown.svelte";
  import GlobalErrors from "../../components/GlobalErrors.svelte";
  import Table from "../../components/Table/Table.svelte";
  import { clfValueFields } from "../../utils/TableFields/clf-value.fields";

  $: isNew = $record != null && $record.oid === 0;
  $: canSubmit = $record != null && $record.code != "" && $record.description != "";

  function onSchemaSelected(item: IDropdownItem) {
    let schema = $schemas.find((x) => x.oid == item.oid);
    if (!!schema) {
      selectedSchema.set(schema.oid);
      //canEdit.set(schema.editable == 1);
      canEdit.set(true);
      loadSchemaValues(schema.code);
    } else {
      records.set([]);
      selectedSchema.set(0);
      canEdit.set(true);
    }
  }

  async function onSubmit() {
    await save();
    editing.set(false);
    record.set(null);
  }

  function onCancel() {
    editing.set(false);
    record.set(null);
  }

  onMount(init);
</script>

<LayoutSettings>
  <Container>
    {#if $editing === false}
      <nav class="level">
        <div class="level-left">
          <div class="level-item">
            <p class="subtitle is-5">select collection to edit</p>
          </div>

          <div class="level-item">
            <div class="field">
              <SearchDropdown
                name=""
                filterVisible={false}
                placeholder="collection"
                items={$schemaItems}
                on:select={(x) => onSchemaSelected(x.detail)}
                value={$selectedSchema ?? 0}
              />
            </div>
          </div>
        </div>
      </nav>

      <GlobalErrors errors={[]} />

      <div style="padding-bottom: 2rem;">
        <Table
          loading={$loading}
          fields={clfValueFields}
          records={$records}
          pagination={null}
          createEnabled={$selectedSchema != 0 && $canEdit}
          editEnabled={$canEdit}
          deleteEnabled={$canEdit}
          on:create={(_) => onCreate()}
          on:edit={(e) => onEdit(e.detail)}
          on:delete={(e) => onDelete(e.detail)}
        />
      </div>

      {#if !$canEdit}
        <div class="has-text-danger">Note: selected table is not editable.</div>
      {/if}
    {:else if $record != null}
      <FormLayout on:submit={onSubmit} on:cancel={onCancel} {canSubmit} {isNew}>
        <FormField usageHelp="internal code (10 chars)">
          <Input name="Code" placeholder="code" bind:value={$record.code} required={true} maxlength={10} />
        </FormField>

        <FormField usageHelp="display order (numeric). Values are ordered by display order and then by description">
          <NumberInput name="Display Order" placeholder="display order" required={true} bind:value={$record.displayOrder} />
        </FormField>

        <FormField usageHelp="visible description (200 chars)">
          <Input name="Description" placeholder="description" required={true} maxlength={200} bind:value={$record.description} />
        </FormField>
      </FormLayout>
    {/if}
  </Container>
</LayoutSettings>
