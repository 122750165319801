import { writable } from 'svelte/store';
import { OrganizationService } from '../../services/organization.service';
export const loading = writable(false);
export const records = writable([]);
export const organizationsComboItems = writable([]);
export const editing = writable(false);
export const selectedItem = writable(null);
export const description = writable("");
export const filterValue = writable("");
function reset() {
    records.set([]);
    organizationsComboItems.set([]);
}
export async function loadByOrganization(organizationId) {
    loading.set(true);
    try {
        reset();
        if (organizationId > 0) {
            let _resp = await OrganizationService.getOrganizationConnections(organizationId);
            records.set(_resp.payload);
        }
        else {
            records.set([]);
        }
    }
    finally {
        loading.set(false);
    }
}
export async function searchOrganizations(currentOrgId, search) {
    let _res = await OrganizationService.searchOrganizationsCombo(currentOrgId, search);
    organizationsComboItems.set(_res.payload);
}
export async function saveOrganizationConnection(srcOrgId, dstOrgId, description) {
    let conn = {
        description: description,
        organization1Id: srcOrgId,
        organization2Id: dstOrgId
    };
    await OrganizationService.saveOrganizationConnection(conn);
    // TODO handle errors
    await loadByOrganization(srcOrgId);
}
export async function deleteOrganizationConnection(organizationId, connectionId) {
    await OrganizationService.deleteOrganizationConnection(connectionId);
    await loadByOrganization(organizationId);
}
export function resetEdit() {
    editing.set(false);
    selectedItem.set(null);
    description.set("");
}
