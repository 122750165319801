export const userOrganizationDtoFields = [{
        title: "Training Provider",
        titleClass: "",
        dataClass: "",
        name: "nameEn",
        render: (record) => record.organization.nameEn
    },
    {
        title: "Country",
        titleClass: "",
        dataClass: "",
        name: "country",
        render: (record) => record.country
    }];
