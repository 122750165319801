<script lang="ts">
  import { isUserRoleADM } from "../../app.store";
  import MainMenuItem from "./MainMenuItem.svelte";
</script>

<div class="main-menu noselect">
  <aside class="menu">
    <p class="menu-label">Menu</p>

    <ul class="menu-list">
      <MainMenuItem link="/booking" label="Prenotazioni" icon="task" />
      <MainMenuItem link="/counter" label="Sportelli" icon="school" />
      <MainMenuItem link="/report" label="Stampe" icon="print" />
    </ul>

    {#if $isUserRoleADM}
      <p class="menu-label">Amministrazione</p>

      <ul class="menu-list">
        <MainMenuItem link="/user" label="Utenti" icon="group" />
        <MainMenuItem link="/export-excel" label="Riepilogo" icon="summarize" />
        <!-- <MainMenuItem link="/settings" label="Settings" icon="settings" /> -->
      </ul>
    {/if}
  </aside>
</div>
