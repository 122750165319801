<script lang="ts">
  export let link: string = "";
  export let label: string = "";
  export let icon: string = "";

  $: currentUrlActive = window.location.pathname.startsWith(link);
</script>

<li class="main-menu-entry">
  {#if !!icon}
    <i class="material-icons main-menu-icon noselect" class:is-active={currentUrlActive}>
      {icon}
    </i>
  {/if}
  <a href={link} class="main-menu-label" class:is-active={currentUrlActive}>
    {label}
  </a>
</li>

<style>
  .main-menu-entry {
    line-height: 2rem;
  }

  .main-menu-icon {
    float: left;
    color: #ccc;
    line-height: 2rem;
  }

  .main-menu-label {
    display: inline;
    line-height: 2rem;
    vertical-align: middle;
  }
</style>
