<script lang="ts">
  import { onMount } from "svelte";
  import { navigate } from "../../app.logic";
  import Input from "../../components/Form/Fields/Input.svelte";
  import FormField from "../../components/Form/FormField.svelte";
  import Switch from "../../components/Form/Fields/Switch.svelte";
  import FormLayout from "../../layout/FormLayout.svelte";
  import { initDetail, record, resetPassword, save, promoteRole, passwordResetMessage, userPromoteMessage } from "./settings-user.store";
  import PortalRoleDropdown from "../../components/Form/Fields/Dropdown/PortalRoleDropdown.svelte";
  import Button from "../../components/Button.svelte";
  import LayoutSettings from "./LayoutSettings.svelte";
  import Container from "../../components/Container.svelte";
  import SettingsUserOrganizations from "./SettingsUserOrganizations.svelte";

  export let oid: number;

  $: isNew = oid == 0;
  $: newUserPasswordSet = !isNew || ($record != null && $record.newPassword != "");
  $: canSubmit = $record != null && $record.email != "" && $record.firstName != "" && $record.lastName != "" && $record.portalRoleId != 0 && newUserPasswordSet;
  $: canBePromoted = $record != null && $record.oid != 0 && $record.portalRoleCode == "ORG";

  async function onSubmit() {
    let newP = isNew;
    let _resp = await save();
    let u = _resp.payload;
    if (newP) {
      oid = u.oid;
      record.set(u);
      navigate(`/settings/users/${u.oid}`);
    } else {
      onCancel();
    }
  }

  function onCancel() {
    navigate(`/settings/users`);
  }

  onMount(() => initDetail(oid));
</script>

<LayoutSettings>
  <Container>
    {#if isNew}
      <div class="title">Create new user</div>
    {/if}

    {#if $record}
      <div class="columns">
        <div class="column">
          <FormLayout {canSubmit} {isNew} on:submit={onSubmit} on:cancel={onCancel}>
            <FormField usageHelp="inactive users cannot login">
              <Switch name="Active" bind:value={$record.active} />
            </FormField>

            <FormField usageHelp="user login e-mail (50 chars)">
              <Input name="Login e-mail" placeholder="user e-mail" maxlength={50} required={true} disabled={!isNew} bind:value={$record.email} />
            </FormField>

            {#if isNew}
              <FormField>
                <Input name="Password" placeholder="user password" type="password" required={true} bind:value={$record.newPassword} />
              </FormField>
            {/if}

            <FormField usageHelp="user first name (100 chars)">
              <Input name="First Name" placeholder="user first name" maxlength={100} required={true} bind:value={$record.firstName} />
            </FormField>

            <FormField usageHelp="user last name (100 chars)">
              <Input name="Last Name" placeholder="user last name" maxlength={100} required={true} bind:value={$record.lastName} />
            </FormField>

            {#if $record.portalRoleCode == "ADM"}
              <FormField usageHelp="choose whether user receives system e-mail messages">
                <Switch name="Receive system e-mail messages" bind:value={$record.receiveEmail} />
              </FormField>
            {/if}

            <div class="columns">
              <div class="column" style="padding-left:0;">
                <FormField>
                  <PortalRoleDropdown name="Role" placeholder="role" disabled={!isNew} required={true} bind:value={$record.portalRoleId} />
                </FormField>
              </div>

              {#if canBePromoted}
                <div class="column" style="margin-top: auto;">
                  <Button klass="is-danger is-outlined" disabled={!canBePromoted} on:click={(_) => promoteRole()}>Promote to Partner Admin</Button>
                </div>
              {/if}
            </div>

            {#if $userPromoteMessage != ""}
              <div class="column" style="margin-top: auto;">
                <article class="message is-dark">
                  <div class="message-body">
                    {$userPromoteMessage}
                  </div>
                </article>
              </div>
            {/if}
          </FormLayout>
        </div>

        {#if !isNew && $record.portalRoleCode != "ADM"}
          <div class="column">
            <article class="panel is-danger" style="margin-bottom: 1rem;">
              <p class="panel-heading is-size-6">Reset Password</p>

              <FormLayout on:submit={resetPassword} submitButtonText="Reset Password" saveButtonClass="is-danger" cancelEnabled={false}>
                <FormField>
                  <Input placeholder="new password" disabled={false} bind:value={$record.newPassword} type="password" name="" />
                </FormField>

                {#if $passwordResetMessage != ""}
                  <article class="message is-dark">
                    <div class="message-body">
                      {$passwordResetMessage}
                    </div>
                  </article>
                {/if}
              </FormLayout>
            </article>

            {#if $record.portalRoleCode != "ADM"}
              <article class="panel is-primary">
                <p class="panel-heading is-size-6">Associated Training Providers</p>
                <SettingsUserOrganizations userId={$record.oid} />
              </article>
            {/if}
          </div>
        {/if}
      </div>
    {/if}
  </Container>
</LayoutSettings>
