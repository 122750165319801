<script lang="ts">
  export let errors: string[] = [];
  export let prefix: string = "";
</script>

{#if errors !== null && errors.length > 0}
  <ul class="errors-base">
    {#each errors as error}
      <li>
        {prefix + " " + error}
      </li>
    {/each}
  </ul>
{/if}

<style>
  .errors-base {
    border-radius: 0 0 2px 2px;
    background: #f7b6b6;
    font-weight: bold;
    font-size: 14px;
    color: #902e2e;
    margin: 0;

    padding: 10px;
    padding-left: 30px;
  }
</style>
