<script lang="ts">
  export let icon: string = "";
  export let title: string = "";
</script>

<h1 class="title is-size-6 sectionTitle">
  <span class="icon map-detail-contact-icon contactIcon">
    <i class="material-icons">{icon}</i>
  </span>
  {title}
</h1>

<style>
  .sectionTitle {
    margin-bottom: 0.2rem !important;
  }

  .contactIcon {
    vertical-align: bottom;
    margin-right: 1rem;
  }
</style>
