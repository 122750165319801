<script lang="ts">
  import { isUserLoggedIn, isUserRoleADM, user } from "../app.store";
  import { logout } from "../app.logic";
  //  import { APP_TITLE } from "../utils/constants";

  // let appTitle = APP_TITLE;
  let showUserMenu = true;
  $: userFullName = $user != null ? $user.fullName : "";
  $: companyName = "";
</script>

<nav class="navbar header-navbar noselect">
  <div class="container content header-content">
    <div class="navbar-brand header-brand">
      <a class="navbar-item app-logo logo" href="/" style="padding-left:5px;">
        <!-- svelte-ignore a11y-img-redundant-alt -->
        <img class="logo-img" src="/img/eecone_logo_2.png" alt="Logo" />
      </a>

      <i class="navbar-item app-logo logo" style="min-width: 244px;">
        <!-- svelte-ignore a11y-img-redundant-alt -->
        <img class="logo-eu" src="/img/eu_funded.png" alt="erasmus" />
      </i>

      <span class="navbar-item brand-text header-brand-text">
        <div class="has-text-centered is-uppercase is-size-6 has-text-weight-light">
          <span>European ECOsystem for greeN Electronic</span>
          <span class="has-text-weight-bold">ATLAS</span>
        </div>
      </span>

      <div class="navbar-burger burger" data-target="navMenu">
        <span />
        <span />
        <span />
      </div>
    </div>

    <div id="navMenu" class="navbar-menu">
      <div class="navbar-start">
        {#if $isUserLoggedIn}
          {#if $isUserRoleADM}
            <div class="divider" />
            <a class="navbar-item item" href="/dashboard">KPI</a>
          {/if}
          <div class="divider" />
          <a class="navbar-item item" href="/organizations">Training Providers</a>
          <div class="divider" />
          <a class="navbar-item item" href="/profile">{userFullName}</a>
        {/if}
      </div>
    </div>

    <div class="navbar-end">
      <div class="divider" />

      <a class="navbar-item item" href="/search/organizations">
        <span class="icon" style="margin-right: 0.2rem;">
          <img src="/img/icon_search.png" alt="search" />
        </span>
        <span>Search Training Providers</span>
      </a>

      <div class="divider" />

      <a class="navbar-item item" href="/map">Map</a>

      <div class="divider" />

      {#if $isUserLoggedIn == false}
        <div class="navbar-item">
          <div class="buttons">
            <a class="button is-primary action-button" href="/sign-in">Login</a>
            <a class="button action-button" href="/sign-up">Sign up</a>
          </div>
        </div>
      {:else}
        <!-- svelte-ignore a11y-invalid-attribute -->
        <a href="#" class="navbar-item item" on:click={logout}>
          <span class="icon" style="margin-right: 0.2rem;">
            <img src="/img/icon_logout.png" alt="logout" />
          </span>
          <span>Logout</span>
        </a>
      {/if}
    </div>
  </div>
</nav>

<style>
  /*
  .header-navbar {
    /* border-bottom: 1px solid #4310a9;
    height: 4rem;
  }
  */
  .header-navbar {
    background-color: #c5e0b4;
  }

  a.navbar-item:hover,
  a.navbar-item:active,
  a.navbar-item:focus {
    background-color: #c5e0b4;
  }

  .header-content {
    max-width: unset !important;
    padding-left: 3rem;
    /* border-bottom: 1px solid #f2f2f2; */
    display: flex !important;
  }

  .header-brand {
    /*flex: 1;*/
  }

  .header-brand-text {
    /*
    font-weight: 300;
    padding-right: 5rem;
    */
    /*font-weight: 0;*/
    letter-spacing: 1px;
    /*padding-right: 5rem;*/
    user-select: none;
  }

  .logo {
    padding: 0;
  }

  .logo-img {
    height: 56px;
    width: 56px;
    max-height: unset !important;
    margin: 0;
    padding: 0;
    position: relative;
    top: -2px;
  }

  .logo-eu {
    height: 46px;
    width: auto;
    max-height: unset !important;
    margin: 5px;
    margin-left: 10px;
  }

  .item {
    font-size: 14px;
    font-weight: 0;
  }

  .content {
    max-width: unset;
    /* padding-left: 3rem; */
    padding-left: 10px;
    /* border-bottom: 1px solid #f2f2f2; */
  }

  .divider {
    height: 50%;
    width: 0;
    margin-top: auto;
    margin-bottom: auto;
    border-right: 2px solid var(--color-gray-10);
  }

  .action-button {
    width: 6rem;
    height: 2.2rem;
  }
</style>
