<script lang="ts">
  import MiniSection from "./MiniSection.svelte";

  export let icon: string = "";
  export let title: string = "";
  export let text: string = "";
</script>

<div class="content">
  <MiniSection {icon} {title} />

  <span class="is-size-6" style="margin-left: 2.5rem;">{text}</span>
</div>
