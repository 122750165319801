import { lastValueFrom } from 'rxjs';
import { BaseService } from './base-service';
export class SearchService {
    static searchCourses(filter) {
        //let qs = encodeURIComponent(JSON.stringify(filter));
        return lastValueFrom(BaseService.post(`fo/search-courses`, filter));
    }
    static searchDetail(organizationId, filter) {
        return lastValueFrom(BaseService.post(`fo/search-detail/${organizationId}`, filter));
    }
    static searchOrganizations(filter, pag) {
        let qs = encodeURIComponent(JSON.stringify(filter));
        return lastValueFrom(BaseService.get(`fo/search-organizations?filter=${qs}&pag=${pag}`));
    }
}
