<script lang="ts">
  import { onMount } from "svelte";
  import LayoutOrganizationDetail from "./LayoutOrganizationDetail.svelte";
  import {
    geocodingMessage,
    geocodingResults,
    loading,
    logoFile,
    maybeInitDetail,
    onAddTechArea,
    onDelTechArea,
    OrganizationDetailPage,
    record,
    setGpsCoodinates,
    geocodeOrganizationAddress,
    techAreasSelectableItems,
    techAreasSelectedItems,
    uploadLogo,
    logoDc,
    save,
  } from "./organizations.store";
  import FormLayout from "../../layout/FormLayout.svelte";
  import FormField from "../../components/Form/FormField.svelte";
  import Switch from "../../components/Form/Fields/Switch.svelte";
  import Input from "../../components/Form/Fields/Input.svelte";
  import Textarea from "../../components/Form/Fields/Textarea.svelte";
  import Label from "../../components/Form/Label.svelte";
  import Button from "../../components/Button.svelte";
  import Icon from "../../components/Icon.svelte";
  import TagSelector from "../../components/Business/TagSelector.svelte";
  import ClfDropdown from "../../components/Form/Fields/Dropdown/ClfDropdown.svelte";
  import ImageUpload from "../../components/ImageUpload.svelte";
  import { BASE_API_URL } from "../../utils/constants";
  import { navigate } from "../../app.logic";
  import { get } from "svelte/store";

  export let orgId: number;

  $: canSubmit =
    $record != null && $record.name != "" && $record.nameEn != "" && $record.clfCountryId != 0 && $record.contactName != "" && $record.contactEmail != "";

  $: canSetGps = $record != null && $record.address != "" && $record.clfCountryId != 0;

  async function submitForm() {
    let isNew = orgId <= 0;

    await save();

    if (!isNew) {
      cancelForm();
    } else {
      navigate("/organizations/" + get(record)!.oid);
    }
  }

  function cancelForm() {
    navigate("/organizations");
  }

  onMount(() => maybeInitDetail(orgId));
</script>

<LayoutOrganizationDetail page={OrganizationDetailPage.OrganizationInfo} organization={$record} whiteBox={true} loading={$loading}>
  {#if $record}
    {#if $record.approved == 1 && ($record.gpsLat == 0 || $record.gpsLon == 0)}
      <article class="message is-danger">
        <div class="message-body">
          Training provider has been marked as approved but its GPS coordinates seem to be invalid. This training provider will not appear on the map.
          <br />
          Please make sure GPS coordinates are appropriately set by specifying address, country and then clicking on the 'Set GPS' button.
        </div>
      </article>
    {/if}

    <FormLayout on:submit={(_) => submitForm()} on:cancel={(_) => cancelForm()} {canSubmit} isNew={$record.oid == 0}>
      <div class="columns">
        <div class="column is-3">
          {#if $record.oid != 0}
            <FormField usageHelp="upload training provider logo (png, jpg)">
              <ImageUpload
                on:fileUpload={uploadLogo}
                disabled={false}
                value={$logoFile}
                dc={$logoDc}
                previewUrl={BASE_API_URL + "/organization-logo/" + $record.oid}
                previewHeight="8rem"
                name="Logo"
              />
            </FormField>
          {/if}
        </div>

        <div class="column approval-flag">
          <FormField usageHelp="approved training providers are visible on map">
            <Switch disabled={false} bind:value={$record.approved} name="Approved" />
          </FormField>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <FormField usageHelp="training provider name in English language (200 characters)">
            <Input
              placeholder="training provider english name"
              maxlength={200}
              disabled={false}
              required={true}
              bind:value={$record.nameEn}
              name="English Name"
            />
          </FormField>

          <FormField usageHelp="training provider name in original language (200 characters)">
            <Input
              placeholder="training provider original name"
              maxlength={200}
              disabled={false}
              required={true}
              bind:value={$record.name}
              name="Original Name"
            />
          </FormField>

          <FormField usageHelp="training provider description (unlimited length)">
            <Textarea placeholder="training provider description" disabled={false} bind:value={$record.description} name="Description" />
          </FormField>

          <FormField usageHelp="training provider address, excluding country name, which is specified below (200 characters)">
            <Input placeholder="street, civic number, town, zip code, province" maxlength={200} disabled={false} bind:value={$record.address} name="Address" />
          </FormField>

          <FormField usageHelp="select appropriate training provider country">
            <ClfDropdown placeholder="country" schema="COUNTRYXXX" disabled={false} required={true} bind:value={$record.clfCountryId} name="Country" />
          </FormField>

          <FormField
            usageHelp="after filling out address and country, click on the 'Set GPS' button to calculate coordinates. If more than one result is available, you will have to choose the most appropriate one"
          >
            <Label>GPS Location</Label>

            <div class="field has-addons">
              <p class="control">
                <input class="input" readonly={true} value={$record.gpsLat + " - " + $record.gpsLon} />
              </p>

              <p class="control">
                <Button klass="is-primary" disabled={!canSetGps} on:click={(_) => geocodeOrganizationAddress()}>
                  <Icon name="gps_fixed" />
                  <span>Set GPS</span>
                </Button>
              </p>
            </div>

            {#if $geocodingMessage != ""}
              <article class="message is-danger">
                <div class="message-body">
                  {$geocodingMessage}
                </div>
              </article>
            {/if}

            {#if $geocodingResults && $geocodingResults.length > 0}
              <article class="message is-success">
                <div class="message-body">"Multiple addresses found, please choose the most appropriate one."</div>
              </article>
            {/if}

            <div class="buttons gps-results">
              {#each $geocodingResults as item}
                <Button klass="is-fullwidth is-outlined " on:click={(_) => setGpsCoodinates(item)}>
                  <span>{item.address}</span>
                  <Icon name="navigate_next" />
                </Button>
              {/each}
            </div>
          </FormField>

          <FormField usageHelp="training provider VAT number (with country code - e.g. XX123456789)">
            <Input
              placeholder="training provider VAT number"
              maxlength={20}
              disabled={false}
              required={false}
              bind:value={$record.vatNumber}
              name="VAT Number"
            />
          </FormField>
        </div>

        <div class="column">
          <FormField usageHelp="training provider contact person's name (200 characters)">
            <Input
              placeholder="training provider contact name"
              maxlength={200}
              disabled={false}
              required={true}
              bind:value={$record.contactName}
              name="Contact Name"
            />
          </FormField>

          <FormField usageHelp="training provider contact phone number (50 characters). Please include international calling code">
            <Input
              placeholder="training provider contact phone (e.g. +39 555 5925)"
              maxlength={50}
              disabled={false}
              bind:value={$record.contactPhone}
              name="Contact Phone"
            />
          </FormField>

          <FormField usageHelp="training provider contact e-mail address (100 characters)">
            <Input
              placeholder="training provider contact e-mail (e.g. name@example.com)"
              maxlength={100}
              disabled={false}
              required={true}
              bind:value={$record.contactEmail}
              name="Contact e-mail"
            />
          </FormField>

          <FormField usageHelp="training provider website (200 characters)">
            <Input
              placeholder="training provider contact web site"
              maxlength={200}
              disabled={false}
              bind:value={$record.contactWebsite}
              name="Contact Web Site"
            />
          </FormField>

          <FormField usageHelp="training provider social channel (e.g. Facebook or LinkedIn page address) (200 characters)">
            <Input
              placeholder="training provider social network url (e.g. Facebook / LinkedIn page)"
              maxlength={200}
              disabled={false}
              bind:value={$record.socialNetwork}
              name="Social Network"
            />
          </FormField>

          <FormField usageHelp="training provider video presentation url (e.g. link to YouTube or Vimeo or other video resource) (200 characters)">
            <Input
              placeholder="training provider video presentation url"
              maxlength={200}
              disabled={false}
              bind:value={$record.videoUrl}
              name="Video Presentation"
            />
          </FormField>

          {#if $record.oid != 0}
            {#if $record.digitalInnovationHub == 1}
              <FormField usageHelp="digital innovation hub topics. Please select appropriate values">
                <TagSelector
                  placeholder="topic"
                  selectableItems={$techAreasSelectableItems}
                  items={$techAreasSelectedItems}
                  disabled={false}
                  name="Digital Innovation Hub Topic"
                  on:add={onAddTechArea}
                  on:del={onDelTechArea}
                />
              </FormField>
            {/if}
          {/if}
        </div>
      </div>

      <hr class="hr" />

      <div class="columns">
        <div class="column is-12">
          <FormField usageHelp="internal notes, not visible to end users (unlimited length)">
            <Textarea placeholder="" disabled={false} bind:value={$record.internalNotes} name="Internal Notes (not visible to users)" />
          </FormField>
        </div>
      </div>
    </FormLayout>
  {/if}
</LayoutOrganizationDetail>

<style>
  .hr {
    border: 0;
    border-top: 1px solid #eee;
    margin: 20px 0;
  }
</style>
