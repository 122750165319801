<script lang="ts">
  import MiniSectionWithUrl from "./MiniSectionWithUrl.svelte";
  import MiniContactChannel from "./MiniContactChannel.svelte";
  import { detail, selectedCourse, setSelectedCourse } from "./map-detail.store";
  import { patchUrl } from "../../utils/misc-utils";
  import MaybeTags from "./MaybeTags.svelte";
  import ImageAsset from "../../components/ImageAsset.svelte";
  import { atlasFormatDate } from "../../utils/date-utils";
  import Image from "../../components/Image.svelte";
  import { BASE_API_URL } from "../../utils/constants";
</script>

<div class="card" style="box-shadow: unset;background: transparent;">
  <div class="columns is-gapless">
    <div class="column is-8 contactDetailAnimation">
      {#if $selectedCourse != null && $selectedCourse.courseId != 0}
        <div class="card" style="margin-right: 1rem;padding: 1rem;">
          <div class="content">
            <ImageAsset name="icon_courses.png" />

            <p class="title is-size-4 noMargin">
              {$selectedCourse.titleEn}
            </p>

            <p class="is-size-5 has-text-grey-light is-italic noMargin">
              {$selectedCourse.title}
            </p>

            <hr style="margin-top: 0;margin-bottom: 1rem;" />

            {#if $selectedCourse.objective != ""}
              <div class="content">
                <h1 class="title is-size-6 sectionTitle">Course Objective</h1>

                <p class="is-size-6 has-text-justified">
                  {$selectedCourse.objective}
                </p>
              </div>

              <hr style="margin-top: 0;margin-bottom: 1rem;" />
            {/if}

            <MaybeTags title="Duration" tagClass="is-primary" items={[$selectedCourse.duration]} />

            {#if $selectedCourse.specificDates == 1 || $selectedCourse.onDemand == 1}
              <div class="content">
                <h1 class="title is-size-6 sectionTitle">Dates</h1>

                {#if $selectedCourse.specificDates == 1 && $selectedCourse.startDate != "" && $selectedCourse.endDate != ""}
                  <p class="is-size-6" style="margin-bottom: 0.2rem;">
                    {atlasFormatDate($selectedCourse.startDate)}&nbsp;-&nbsp;{atlasFormatDate($selectedCourse.endDate)}
                  </p>
                {/if}

                {#if $selectedCourse.onDemand == 1}
                  <p class="is-size-6">
                    <span class="tag is-success is-light">Course may be activated on demand</span>
                  </p>
                {/if}
              </div>

              <hr style="margin-top: 0;margin-bottom: 1rem;" />
            {/if}

            <MaybeTags title="Educational Entry Level" tagClass="is-primary" items={$selectedCourse.educationLevels} />

            <MaybeTags title="Course Languages" tagClass="is-primary" items={$selectedCourse.languages} />
            <MaybeTags title="Training Channels" tagClass="is-primary" items={$selectedCourse.channels} />
            <MaybeTags title="Topics" tagClass="is-primary" items={$selectedCourse.techAreas} />

            <MaybeTags title="Skills to be acquired" tagClass="is-primary" items={$selectedCourse.skills} />

            {#if $selectedCourse.description != ""}
              <div class="content">
                <h1 class="title is-size-6 sectionTitle">Description</h1>
                <p class="is-size-6 has-text-justified">{$selectedCourse.description}</p>
              </div>

              <hr style="margin-top: 0;margin-bottom: 1rem;" />
            {/if}

            {#if $selectedCourse.videoUrl != ""}
              <MiniSectionWithUrl icon="slideshow" title="Video Presentation" url={$selectedCourse.videoUrl} />
            {/if}

            {#if $selectedCourse.contactEmail != "" || $selectedCourse.website != ""}
              {#if $selectedCourse.videoUrl != ""}
                <hr style="margin-top: 0;margin-bottom: 1rem;" />
              {/if}

              <div class="content">
                <h1 class="is-size-6 sectionTitle">Contact for further information</h1>

                {#if $selectedCourse.contactEmail != ""}
                  <MiniContactChannel icon="email" label={$selectedCourse.contactEmail} url={"mailto:" + $selectedCourse.contactEmail} />
                {/if}

                {#if $selectedCourse.website != ""}
                  <MiniContactChannel icon="language" label={patchUrl($selectedCourse.website)} url={$selectedCourse.website} />
                {/if}
              </div>
            {/if}
          </div>
        </div>
      {/if}
    </div>

    <div class="column is-4">
      {#if $selectedCourse != null && $selectedCourse.hasLogo}
        <figure class="image" style="margin-bottom: 1rem;">
          <Image src={BASE_API_URL + "/course-logo/" + $selectedCourse.courseId} height="auto" width="100%" disableCache={true} />
        </figure>
      {/if}

      <nav class="map-courses-nav panel has-background-white is-primary">
        <p class="panel-heading has-text-centered valign-center-box">
          <span class="icon" style="margin-right: 0.2rem;">
            <ImageAsset name="competition.png" />
          </span>

          <span style="padding-left: 0.8rem;">Courses matching your criteria</span>
        </p>

        {#if $detail != null}
          {#each $detail.courses as course}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-static-element-interactions -->
            <!-- svelte-ignore a11y-missing-attribute -->
            <a
              class="panel-block valign-center-box"
              class:is-active={$selectedCourse != null && $selectedCourse.courseId == course.courseId}
              on:click={(_) => setSelectedCourse(course)}
            >
              <span class="icon map-detail-contact-icon" style="margin-right: 0.2rem;">
                <i class="material-icons">arrow_forward</i>
              </span>
              <span style="padding-left: 0.8rem;">{course.titleEn}</span>
            </a>
          {/each}
        {/if}
      </nav>
    </div>
  </div>
</div>

<style>
  .noMargin {
    margin-bottom: 0.2rem !important;
  }

  .sectionTitle {
    margin-bottom: 0.2rem !important;
  }

  .contactDetailAnimation {
    animation: fadein 0.8s;
  }
</style>
