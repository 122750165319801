<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import type { IDropdownItem } from "../../../../services/DTO/dropdown-item";
  import { broadcast, getEmitter } from "../../../../utils/misc/pub-sub-wrapper";
  import Label from "../../Label.svelte";

  const dispatch = createEventDispatcher();

  // export let errors: string[] = [];
  export let placeholder: string = "";
  // export let disabled: boolean = false;
  export let required: boolean = false;
  export let disabled: boolean = false;
  export let clearVisible: boolean = false;
  export let filterVisible: boolean = true;
  export let name: string = "";
  export let items: IDropdownItem[] = [];
  export let value: number | null = null;
  export let debounce: number = 700;
  export let hasError: boolean = false;
  export let small: boolean = false;
  export let fullWidth: boolean = false;
  export let contain: boolean = false;

  let isOpen: boolean = false;
  let filter: string = "";

  let searchInputRef: any;
  let subscription: any;

  $: displayName = required && name != "" ? name + " *" : name;
  $: requiredClass = required && !value ? "is-danger" : "";
  $: activeClass = isOpen ? "is-active" : "";
  $: errorClass = hasError ? "has-error" : "";
  $: placeholderClass = !selectedLabel ? "placeholder" : "";
  $: currLabel = (items || []).find((x) => x.oid === value);
  $: selectedLabel = currLabel != null ? currLabel.label : "";
  $: selectedLabelOrPlaceholer = !!selectedLabel ? selectedLabel : placeholder;

  const onFilterDebounced = debounceDelay(invokeFilter, debounce);

  onMount(() => init());
  onDestroy(() => fini());

  function init() {
    subscription = getEmitter().subscribe((_) => closeDropdown());
  }
  function fini() {
    if (!subscription) subscription.unsubscribe();
  }

  function closeDropdown() {
    isOpen = false;
  }

  function toggleDropdown() {
    if (!isOpen) {
      broadcast();
      setTimeout(() => {
        isOpen = true;
        toggleDropdown1();
      }, 10);
    } else {
      isOpen = false;
    }
  }

  function toggleDropdown1() {
    // isOpen = !isOpen;
    if (isOpen) {
      filter = "";
      dispatch("filter", filter);
      if (!!searchInputRef) {
        setTimeout(() => {
          searchInputRef.focus();
        }, 10);
      }
    }
  }

  function silentClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    return true;
  }

  function onFilterKeyUp(_event: Event) {
    onFilterDebounced();
  }

  function invokeFilter() {
    dispatch("filter", filter);
  }

  function handleSelectItem(item: IDropdownItem) {
    isOpen = false;
    value = item.oid;
    dispatch("select", item);
    filter = "";
    invokeFilter();
    return true;
  }

  function handleClearItem(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    isOpen = false;
    value = null;
    dispatch("clear");
    return true;
  }

  function debounceDelay(cb: any, interval: number) {
    let timeout: any;

    return function () {
      let context: any = <any>this,
        args = arguments;
      let later = function () {
        timeout = null;
        cb.apply(context, args);
      };

      clearTimeout(timeout);

      timeout = setTimeout(later, interval);
    };
  }
</script>

<div>
  {#if name != ""}
    <Label>
      {displayName}
    </Label>
  {/if}

  <div class="dropdown {activeClass} {errorClass} {requiredClass}" class:is-small={small} class:is-fullwidth={fullWidth}>
    <div class="dropdown-trigger">
      <button class="button trigger" class:is-small={small} aria-haspopup="true" aria-controls="dropdown-menu" {disabled} on:click={toggleDropdown}>
        <span class="dropdown-text truncate {placeholderClass}" class:is-small={small} title={selectedLabelOrPlaceholer}>
          {selectedLabelOrPlaceholer}
        </span>

        {#if clearVisible && !disabled}
          <i class="delete" on:click={handleClearItem} />
        {/if}

        <span class="icon is-small">
          <i class="material-icons"> expand_more </i>
        </span>
      </button>
    </div>

    <div class="dropdown-menu" class:is-small={small} id="dropdown-menu" role="menu" class:dropdown-contain={contain}>
      <div class="dropdown-content">
        {#if filterVisible}
          <div class="field dropdown-item" class:is-small={small}>
            <div class="control has-icons-left">
              <input
                bind:this={searchInputRef}
                type="text"
                class="input is-transparent"
                class:is-small={small}
                bind:value={filter}
                on:keyup={onFilterKeyUp}
                on:click={silentClick}
                placeholder="please type to search..."
              />

              <span class="icon is-left">
                <i class="material-icons"> search </i>
              </span>
            </div>
          </div>

          <hr class="dropdown-divider" />
        {/if}

        <div class="dropdown-items">
          {#each items as item}
            <!-- svelte-ignore a11y-missing-attribute -->
            <a class="dropdown-item fix1 truncate" on:click={(_) => handleSelectItem(item)} title={item.label}>
              {item.label}
            </a>
          {/each}
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .dropdown-contain {
    min-width: 0 !important;
  }
</style>
