<script>
  import Container from "../../components/Container.svelte";
  import Layout from "../../layout/Layout.svelte";
  import { PRIVACY_URL } from "../../utils/constants";
</script>

<Layout>
  <Container>
    <h1>PROCESSION OF PERSONAL DATA</h1>
    <div class="informative">
      <p class="subtitle">INFORMATION NOTICE ON THE PROCESSING OF PERSONAL DATA</p>
      <p>
        <b>NSBproject S.R.L.</b> with registered office in Mantua (Italy), Corso Garibaldi n. 157, zip code 46100, as owner of the processing of personal data (hereinafter
        the “Owner”), issues this information to the interested party in compliance with the European and Italian legislation on the protection of personal data.
      </p>
      <p>
        This information integrates the navigation policy of the website, in order to illustrate to the User how the Data Controller will specifically process
        the data entered in this contact form: we therefore invite you to read our <a href={PRIVACY_URL} target="_blank">privacy policy</a>.
      </p>
      <h2>Purpose and legal basis of the processing of personal data</h2>
      <p>The Data Controller processes personal data for various purposes:</p>
      <p>
        1) allow the User to register in the reserved area and access the related services, as well as allow the Owner to carry out all the administrative,
        accounting and tax related activities and to fulfill, more generally, all the legal obligations to which he is held: the legal basis of the processing
        is the need for the pursuit of the aforementioned purposes;
      </p>
      <p>
        2) use the interested party’s e-mail address to transmit communications concerning any additional services: express consent is required for this
        purpose.
      </p>
      <h2>Data retention period</h2>
      <p>The Data Controller intends to process the data according to the following temporal criteria:</p>
      <ul>
        <li>
          Twenty-four months from the last access to the reserved area, subject to further storage for the time necessary for the settlement (however reached)
          of any disputes that may have arisen;
        </li>
        <li>
          for the purposes referred to in point 2), the data will be processed for twenty-four months from the last communication, regardless of the channel
          used: the interested party may withdraw consent or otherwise oppose the processing at any time.
        </li>
      </ul>
      <h2>Nature of the provision of data and consequences in case of refusal</h2>
      <p>
        The provision of data for the purposes referred to in point 1) is necessary and therefore any refusal to provide them in whole or in part may make it
        impossible for the Data Controller to pursue the aforementioned purposes. The provision for further purposes is optional: failing that, the Data
        Controller will not be able to carry out the corresponding activities but will still be entitled to pursue the purposes referred to in point 1).
      </p>
      <h2>Categories of recipients of personal data</h2>
      <p>
        The Data Controller will not disclose the data, but intends to communicate them to internal figures authorized to process the data on the basis of their
        respective duties, as well as to our project partners, consultancy companies, associations and / or business organizations, professionals or service
        companies, as well as to public and private entities, also following inspections and verifications.
      </p>
      <p>
        These recipients, should they process data on behalf of the Data Controller, will be appointed as data processors with a specific contract or other
        legal act.
      </p>
      <h2>Transfers of personal data to third countries or international organisations</h2>
      <p>Personal data will not be transferred either to non-European third countries or to international organizations.</p>
      <h2>Rights of interested party</h2>
      <p>
        The interested party has the right to ask the Data Controller to access their personal data and to rectify them if they are inaccurate, to delete them
        or limit their processing if the conditions are met, to oppose their processing for legitimate interests pursued by the Data Controller, as well as to
        obtain the portability of data personally provided only if subject to automated processing based on consent or contract. The interested party also has
        the right to revoke the consent given for the processing purposes that require it, without prejudice to the lawfulness of the processing carried out up
        to the time of revocation.
      </p>
      <p>
        To exercise their rights, the interested party can use the form available here and forward it to the Data Controller at the following address: <a
          href="mailto:info@nsbproject.com">info@nsbproject.com</a
        >. The interested party also has the right to lodge a complaint with the relevant supervisory authority, the Guarantor for the protection of personal
        data (<a href="http://www.garanteprivacy.it/" target="_blank">www.garanteprivacy.it</a>).
      </p>
    </div>
  </Container>
</Layout>

<style>
  .informative h2 {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
    font-size: 1.5rem;
  }
  .informative p {
    margin-bottom: 0.25rem;
  }
  .informative .subtitle {
    margin-bottom: 1rem;
  }
</style>
