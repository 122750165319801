<script lang="ts">
  import { onMount } from "svelte";
  import { Table } from "../../components/Table";
  import LayoutSettings from "./LayoutSettings.svelte";
  import { portalUserDtoFields } from "../../utils/TableFields/portal-user-dto.fields";
  import { loading, records, pagination, initList, onCreate, onEdit, onDelete, onPageChange, filter, load } from "./settings-user.store";
  import Container from "../../components/Container.svelte";
  import SearchHeader from "../../components/SearchHeader.svelte";

  onMount(initList);
</script>

<LayoutSettings>
  <Container>
    {#if $filter}
      <SearchHeader
        entitySingular="user"
        entityPlural="users"
        numRecords={$pagination != null ? $pagination.totalRecords : 0}
        bind:searchValue={$filter.criteria.email}
        on:search={load}
      />
    {/if}

    <Table
      loading={$loading}
      fields={portalUserDtoFields}
      records={$records}
      pagination={$pagination}
      createEnabled={true}
      editEnabled={true}
      deleteEnabled={true}
      on:create={(_) => onCreate()}
      on:edit={(e) => onEdit(e.detail)}
      on:delete={(e) => onDelete(e.detail)}
      on:pageChange={(e) => onPageChange(e.detail)}
    />
  </Container>
</LayoutSettings>
