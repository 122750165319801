<script lang="ts">
  import MiniSection from "./MiniSection.svelte";
  import { patchUrl } from "../../utils/misc-utils";

  export let icon: string = "";
  export let title: string = "";
  export let url: string = "";
</script>

<div class="content">
  <MiniSection {icon} {title} />

  <a style="margin-left: 2.5rem;" href={url} target="_blank">
    {patchUrl(url)}
  </a>
</div>
