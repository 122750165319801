<script lang="ts">
  import { onMount } from "svelte";
  import { ClassificationService } from "../../../../services/classification.service";
  import type { IDropdownItem } from "../../../../services/DTO/dropdown-item";
  import SearchDropdown from "./SearchDropdown.svelte";

  export let placeholder: string = "";
  export let required: boolean = false;
  export let disabled: boolean = false;
  export let clearVisible: boolean = false;
  export let filterVisible: boolean = true;
  export let name: string = "";
  export let schema: string = "";
  export let value: number | null = null;
  export let small: boolean = false;
  export let fullWidth: boolean = false;

  let items: IDropdownItem[] = [];

  function onFilter(event: CustomEvent) {
    let filter: string = event.detail;
    search(filter);
  }

  async function search(filter: string) {
    let selfId = !!value ? value : 0;
    let values = await ClassificationService.searchValueCombo(schema, selfId, filter);
    items = values.payload || [];
  }

  onMount(() => search(""));
</script>

<SearchDropdown {placeholder} {required} {disabled} {clearVisible} {filterVisible} {name} {small} {fullWidth} {items} bind:value on:filter={onFilter} />
