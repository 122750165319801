<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import Errors from "../../Errors.svelte";
  import Label from "../Label.svelte";
  import moment from "moment";
  import flatpickr from "flatpickr";
  import "flatpickr/dist/flatpickr.css";
  import "flatpickr/dist/themes/light.css";
  //  import { Italian } from "flatpickr/dist/l10n/it.js";

  const dispatch = createEventDispatcher();

  export let errors: string[] = [];
  export let placeholder: string = "";
  export let disabled: boolean = false;
  export let required: boolean = false;
  export let small: boolean = false;
  export let value: Date | null = null;
  export let name: string = "";
  export let maxlength: number | null = null;
  export let calendarType: string = "date"; // date, time, datetime

  let inputElement: Element;
  let picker: flatpickr.Instance | null = null;

  $: displayName = required && name != "" ? name + " *" : name;
  $: updateCalendarValue(picker, value);

  function onKeyDown(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      event.preventDefault();
      dispatch("enter", event);
    } else {
      dispatch("keydown", event);
    }
  }

  function onBlur(event: Event) {
    dispatch("blur", event);
  }

  function initCalendar(element: Element) {
    const timeEnabled = calendarType === "datetime";

    const options = {
      enableTime: timeEnabled, //  date|time|datetime
      // dateFormat: "Y-m-d\\TH:i:00",
      dateFormat: "Z",
      //locale: Italian,
      altInput: true,
      altFormat: timeEnabled ? "d/m/Y H:i" : "d/m/Y",
      time_24hr: timeEnabled,
      onChange: function (selectedDates: Date[], _dateStr: string, _instance: any) {
        const val = !!selectedDates && selectedDates.length > 0 ? selectedDates[0] : null;
        if (!!val) {
          // value = val.toISOString();
          value = val;
          dispatch("valueSelected", val);
        } else {
          value = null;
        }
      },
    };

    picker = flatpickr(element, options);

    return null;
  }

  function updateCalendarValue(calendar: flatpickr.Instance | null, val: any) {
    if (calendar == null || val == null) return;
    const d = moment.utc(val).local().toDate();
    calendar.setDate(d, false);
    return null;
  }

  onMount(() => {
    initCalendar(inputElement);
  });

  onDestroy(() => {
    if (picker != null) picker.destroy();
    picker = null;
  });
</script>

<div>
  {#if name != ""}
    <Label>
      {displayName}
    </Label>
  {/if}

  <!-- name={domRef} -->

  <input
    class="input date"
    class:is-danger={required && value == null}
    class:is-small={small}
    bind:this={inputElement}
    on:keydown={onKeyDown}
    on:blur={onBlur}
    {placeholder}
    {disabled}
    {required}
    type="text"
    {maxlength}
  />

  <Errors {errors} prefix={name} />
</div>
