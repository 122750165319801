<script lang="ts">
  export let title: string = "";
  export let tagClass: string = "";
  export let items: string[] = [];
</script>

{#if !!items && items.length != 0}
  <div class="content">
    <h1 class="title is-size-6 sectionTitle">{title}</h1>

    <p>
      {#each items as item}
        <span class="tag {tagClass}" style="margin-right: 0.5rem;">{item}</span>
      {/each}
    </p>
  </div>

  <hr style="margin-top: 0;margin-bottom: 1rem;" />
{/if}

<style>
  .sectionTitle {
    margin-bottom: 0.2rem !important;
  }
</style>
