<script lang="ts">
  import { onMount } from "svelte";
  import { Table } from "../../components/Table";
  import LayoutSettings from "./LayoutSettings.svelte";
  import { loading, records, pagination, initList, onPageChange, markProcessed } from "./settings-deletion-requests.store";
  import Container from "../../components/Container.svelte";
  import SearchHeader from "../../components/SearchHeader.svelte";
  import { deletionRequestDtoFields } from "../../utils/TableFields/deletion-request-dto.fields";
  import type { IDeletionRequestDTO } from "../../services/DTO/deletion-request-dto";
  import ConfirmModal from "../../components/Modal/ConfirmModal.svelte";

  let confirmModalOpen = false;
  let confirmRecord: IDeletionRequestDTO | null = null;

  function onMarkProcessed(item: IDeletionRequestDTO) {
    confirmRecord = item;
    confirmModalOpen = true;
  }

  function confirmProcess() {
    if (confirmRecord) {
      markProcessed(confirmRecord);
    }
    confirmRecord = null;
    confirmModalOpen = false;
  }

  function declineProcess() {
    confirmRecord = null;
    confirmModalOpen = false;
  }

  onMount(initList);
</script>

<LayoutSettings>
  <Container>
    <SearchHeader
      entitySingular="deletion request"
      entityPlural="deletion requests"
      numRecords={$pagination != null ? $pagination.totalRecords : 0}
      searchFieldVisible={false}
    />

    <ConfirmModal
      title="Dismiss Deletion Request"
      message="Really dismiss this deletion request? Please make sure that you have deleted the Traning Provider and possibly the end-user too, if appropriate."
      bind:open={confirmModalOpen}
      on:confirm={confirmProcess}
      on:decline={declineProcess}
    />

    <Table
      loading={$loading}
      fields={deletionRequestDtoFields}
      records={$records}
      pagination={$pagination}
      createEnabled={false}
      editEnabled={true}
      deleteEnabled={false}
      editIcon="move_to_inbox"
      on:edit={(e) => onMarkProcessed(e.detail)}
      on:pageChange={(e) => onPageChange(e.detail)}
    />
  </Container>
</LayoutSettings>
