<script lang="ts">
  import { onMount } from "svelte";
  import { isUserLoggedIn, isUserRoleADM, mapMonth, mapYear } from "../../app.store";
  import OpenLayersMap from "../../components/Business/OpenLayersMap/OpenLayersMap.svelte";
  import TagSelector from "../../components/Business/TagSelector.svelte";
  import Button from "../../components/Button.svelte";
  import Input from "../../components/Form/Fields/Input.svelte";
  import Switch from "../../components/Form/Fields/Switch.svelte";
  import Form from "../../components/Form/Form.svelte";
  import FormField from "../../components/Form/FormField.svelte";
  import ImageAsset from "../../components/ImageAsset.svelte";
  import Layout from "../../layout/Layout.svelte";
  import { PRIVACY_URL } from "../../utils/constants";
  import {
    allCountries,
    clear,
    courseLanguageSelectableItems,
    courseLanguageSelectedItems,
    detail,
    filter,
    initList,
    onAddCourseLanguage,
    onAddTechArea,
    onAddTrainingChannel,
    onDelCourseLanguage,
    onDelTechArea,
    onDelTrainingChannel,
    onDetailClick,
    onPoiClick,
    pois,
    search,
    techAreasSelectableItems,
    techAreasSelectedItems,
    toggleAllCountries,
    trainingChannelSelectableItems,
    trainingChannelSelectedItems,
  } from "./map.store";
  import ClfDropdown from "../../components/Form/Fields/Dropdown/ClfDropdown.svelte";
  import SearchDropdown from "../../components/Form/Fields/Dropdown/SearchDropdown.svelte";

  const MaxTextSizeForTags: string = "150px";

  onMount(() => initList());
</script>

<Layout>
  <div class="base">
    <OpenLayersMap poiItems={pois} {detail} on:poiClick={onPoiClick} on:detailClick={onDetailClick} />
  </div>

  <div class="left-filter-box">
    <div class="left-filter-controls">
      <div class="filters-title valign-center-box">
        <ImageAsset name="icon_courses.png" />
        <span class="has-text-weight-normal">Search Courses</span>
      </div>

      {#if $filter != null && $filter.criteria != null}
        <Form>
          <FormField>
            <Input placeholder="course title" small={true} bind:value={$filter.criteria.title} />
          </FormField>

          <div class="columns is-gapless">
            <div class="column is-4">
              <FormField>
                <!-- <Switch onChange={setAllCountries} disabled={false} condensed={false} value={allCountries} name="All Countries" labelSize="0.8rem" /> -->
                <Switch disabled={false} condensed={false} on:toggle={toggleAllCountries} value={$allCountries} name="All Countries" labelSize="0.8rem" />
              </FormField>
            </div>

            {#if $allCountries == 0}
              <div class="column is-8" style="margin-top: auto;">
                <FormField>
                  <ClfDropdown
                    name=""
                    placeholder="country"
                    fullWidth={true}
                    small={true}
                    required={false}
                    disabled={false}
                    schema="COUNTRYXXX"
                    bind:value={$filter.criteria.countryId}
                  />
                </FormField>
              </div>
            {/if}
          </div>

          <FormField>
            <TagSelector
              placeholder="topic"
              fullWidth={true}
              small={true}
              maxTextSize={MaxTextSizeForTags}
              showEmptyMessage={false}
              selectableItems={$techAreasSelectableItems}
              items={$techAreasSelectedItems}
              on:add={onAddTechArea}
              on:del={onDelTechArea}
            />
          </FormField>

          <FormField>
            <TagSelector
              placeholder="course language"
              fullWidth={true}
              small={true}
              maxTextSize={MaxTextSizeForTags}
              showEmptyMessage={false}
              selectableItems={$courseLanguageSelectableItems}
              items={$courseLanguageSelectedItems}
              on:add={onAddCourseLanguage}
              on:del={onDelCourseLanguage}
            />
          </FormField>

          <FormField>
            <TagSelector
              placeholder="training channel"
              fullWidth={true}
              small={true}
              maxTextSize={MaxTextSizeForTags}
              showEmptyMessage={false}
              selectableItems={$trainingChannelSelectableItems}
              items={$trainingChannelSelectedItems}
              on:add={onAddTrainingChannel}
              on:del={onDelTrainingChannel}
            />
          </FormField>

          <div class="columns is-gapless">
            <div class="column is-6">
              <FormField>
                <SearchDropdown
                  placeholder="start month"
                  fullWidth={true}
                  small={true}
                  items={$mapMonth}
                  disabled={false}
                  bind:value={$filter.criteria.startMonth}
                  name=""
                  filterVisible={false}
                />
              </FormField>
            </div>

            <div class="column is-1" />

            <div class="column is-5">
              <FormField>
                <SearchDropdown
                  placeholder="start year"
                  fullWidth={true}
                  small={true}
                  items={$mapYear}
                  disabled={false}
                  contain={true}
                  bind:value={$filter.criteria.startYear}
                  name=""
                  filterVisible={false}
                />
              </FormField>
            </div>
          </div>

          <div class="columns is-gapless">
            <div class="column is-6">
              <FormField>
                <SearchDropdown
                  placeholder="end month"
                  fullWidth={true}
                  small={true}
                  items={$mapMonth}
                  disabled={false}
                  bind:value={$filter.criteria.endMonth}
                  name=""
                  filterVisible={false}
                />
              </FormField>
            </div>

            <div class="column is-1" />

            <div class="column is-5">
              <FormField>
                <SearchDropdown
                  placeholder="end year"
                  fullWidth={true}
                  small={true}
                  items={$mapYear}
                  disabled={false}
                  contain={true}
                  bind:value={$filter.criteria.endYear}
                  name=""
                  filterVisible={false}
                />
              </FormField>
            </div>
          </div>

          <hr />

          <div class="field is-grouped">
            <div class="control search-buttons">
              <Button klass="is-primary" on:click={(_) => search()}>
                <span class="icon" style="margin-right: 0.2rem;">
                  <ImageAsset name="icon_search.png" />
                </span>
                Search
              </Button>

              <span style="margin: 0.5rem;" />

              <Button on:click={(_) => clear()}>
                <span class="icon" style="margin-right: 0.2rem;">
                  <ImageAsset name="icon_clear.png" />
                </span>
                Clear
              </Button>
            </div>
          </div>
        </Form>
      {/if}

      <a class="privacy-policy-link" href={PRIVACY_URL} target="_blank">Privacy Policy</a>
    </div>

    {#if $isUserLoggedIn && $isUserRoleADM}
      <div class="bottom-settings">
        <div class="valign-center-box">
          <ImageAsset name="icon_settings.png" />
          <a href={"/settings"}>Settings</a>
        </div>
      </div>
    {/if}
  </div>
</Layout>

<style>
  .base {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
</style>
