<script lang="ts">
  import type { IApiPagination } from "../../utils/api/api-response";
  import type { ITableField } from "./table-field";
  import { createEventDispatcher } from "svelte";
  import Icon from "../Icon.svelte";
  import TableHeaderField from "./TableHeaderField.svelte";
  import TableDataField from "./TableDataField.svelte";
  import TablePaginator from "./TablePaginator.svelte";
  import ConfirmModal from "../Modal/ConfirmModal.svelte";

  export let klass: string = "";
  export let fields: ITableField<any>[];
  export let records: any[];
  export let loading: boolean = false;
  export let noElementsText: string = "There are no elements";
  export let deletionRequestText: string = "Do you want to delete this element?";
  export let createEnabled: boolean = false;
  export let editEnabled: boolean = false;
  export let deleteEnabled: boolean = false;
  export let exportEnabled: boolean = false;
  export let pagination: IApiPagination | null = null;
  export let deleteModalTitle: string = "Delete";
  export let applyRowClass: boolean = false;
  export let rowClassFn: (record: any) => string = (_) => "";
  export let editIcon: string = "edit";

  let deleteModalOpen = false;
  let deletingRecord: any = null;

  const dispatch = createEventDispatcher();

  $: hasButtons = createEnabled || editEnabled || deleteEnabled || exportEnabled;
  $: colspanValue = hasButtons ? fields.length + 1 : fields.length;
  $: numRecords = records != null ? records.length : 0;
  $: visibleFields = fields.filter((x) => !x.hidden);

  function onCreate() {
    dispatch("create");
  }

  function onEdit(record: any) {
    dispatch("edit", record);
  }

  function onDelete(record: any) {
    deletingRecord = record;
    dispatch("deleteRequested", record);
    deleteModalOpen = true;
  }

  function confirmDelete() {
    if (!!deletingRecord) {
      let recordToDelete = Object.assign({}, deletingRecord);
      dispatch("delete", recordToDelete);
    }
    deletingRecord = null;
    dispatch("deleteRequested", null);
  }

  function declineDelete() {
    deletingRecord = null;
    dispatch("deleteRequested", null);
  }

  function onPageChange(event: CustomEvent<number>) {
    dispatch("pageChange", event.detail);
  }

  function onExport() {
    dispatch("export");
  }

  function renderField(field: ITableField<any>, record: any) {
    if (field.render != null && typeof field.render === "function") {
      return field.render(record);
    }
    return record[field.name];
  }
</script>

<ConfirmModal title={deleteModalTitle} message={deletionRequestText} bind:open={deleteModalOpen} on:confirm={confirmDelete} on:decline={declineDelete} />

<table class="table is-striped is-fullwidth table-base {klass}">
  <thead>
    <tr>
      {#if hasButtons || createEnabled}
        <TableHeaderField klass="table-buttons">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          {#if createEnabled}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div class="add-new valign-center-box hoverable" on:click={(_) => onCreate()}>
              Add New
              <i class="material-icons icon-base">add</i>
            </div>
          {/if}
          {#if exportEnabled}
            <Icon name="file_download" on:click={(_) => onExport()} />
          {/if}
        </TableHeaderField>
      {/if}

      {#each visibleFields as field}
        <TableHeaderField klass={field.titleClass || ""} sortable={field.sortable}>
          {field.title}
        </TableHeaderField>
      {/each}
    </tr>
  </thead>

  <tbody>
    {#if loading == false}
      {#if numRecords == 0}
        <tr class="table-no-data">
          <td colspan={colspanValue}>
            {noElementsText}
          </td>
        </tr>
      {:else}
        {#each records as record}
          <tr class={applyRowClass ? rowClassFn(record) : ""}>
            {#if hasButtons}
              <TableDataField klass="has-text-centered">
                {#if editEnabled}
                  <Icon name={editIcon} on:click={(_) => onEdit(record)} />
                {/if}
                {#if deleteEnabled}
                  <Icon name="delete" on:click={(_) => onDelete(record)} />
                {/if}
              </TableDataField>
            {/if}

            {#each visibleFields as field}
              <TableDataField klass={field.dataClass || ""}>
                {@html renderField(field, record)}
              </TableDataField>
            {/each}
          </tr>
        {/each}
      {/if}
    {:else}
      <tr class="table-no-data">
        <td colspan={colspanValue}>
          <div class="table-loader" />
        </td>
      </tr>
    {/if}
  </tbody>
</table>

<TablePaginator {pagination} on:pageChange={onPageChange} />

<style>
  .table-base {
    margin-top: 1rem;
  }

  .table-base tr.table-no-data {
    transition: all 1s linear;
  }

  .table-no-data td {
    vertical-align: middle;
  }

  .icon-base {
    cursor: default;
    width: 24px;
  }
</style>
