<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let title: string = "";
  export let message: string = "";
  export let confirmButtonText: string = "Yes";
  export let declineButtonText: string = "No";
  export let open: boolean = false;

  const dispatch = createEventDispatcher();

  function handleConfirmClick(event: Event) {
    open = false;
    dispatch("confirm", event);
  }

  function handleDeclineClick(event: Event) {
    open = false;
    dispatch("decline", event);
  }
</script>

{#if open}
  <div class="modal is-active">
    <div class="modal-background" />

    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {title}
        </p>

        <button class="delete" aria-label="close" on:click={handleDeclineClick} />
      </header>

      <section class="modal-card-body">
        {message}
      </section>

      <footer class="modal-card-foot modal-confirm-modal-card-foot">
        <button class="button is-danger" on:click={handleConfirmClick}>
          {confirmButtonText}
        </button>

        <button class="button" on:click={handleDeclineClick}>
          {declineButtonText}
        </button>
      </footer>
    </div>
  </div>
{/if}

<style>
  .modal-confirm-modal-card-foot {
    display: inline-block;
    text-align: center;
  }
</style>
