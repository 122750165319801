<script lang="ts">
  import { onMount } from "svelte";
  import LayoutOrganizationDetail from "./LayoutOrganizationDetail.svelte";
  import { loadByOrganization, loading, onCreate, onDelete, onEdit, records } from "./organizations-courses.store";
  import { courseDtoFields } from "../../utils/TableFields/course.fields";
  import { maybeInitDetail, OrganizationDetailPage, record } from "./organizations.store";
  import Container from "../../components/Container.svelte";
  import Table from "../../components/Table/Table.svelte";
  import type { ICourse } from "../../entities/course";

  export let orgId: number;

  function courseUnpublishedStyle(r: ICourse) {
    return r != null && r.published == 0 ? "course-unpublished" : "";
  }

  onMount(() => {
    maybeInitDetail(orgId);
    loadByOrganization(orgId);
  });
</script>

<LayoutOrganizationDetail page={OrganizationDetailPage.OrganizationCourses} organization={$record} whiteBox={false} loading={$loading}>
  <Container>
    <Table
      loading={$loading}
      fields={courseDtoFields}
      records={$records}
      deletionRequestText="Do you want to delete this course?"
      createEnabled={true}
      editEnabled={true}
      deleteEnabled={true}
      applyRowClass={true}
      rowClassFn={courseUnpublishedStyle}
      on:create={(_) => onCreate(orgId)}
      on:edit={(e) => onEdit(e.detail)}
      on:delete={(e) => onDelete(e.detail)}
    />
  </Container>
</LayoutOrganizationDetail>
