<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let open: boolean = false;

  const dispatch = createEventDispatcher();

  function handleCreateOrgClick(event: Event) {
    open = false;
    dispatch("createOrg", event);
  }

  function handleCreateDihClick(event: Event) {
    open = false;
    dispatch("createDih", event);
  }

  function handleCancelClick(event: Event) {
    open = false;
    dispatch("cancel", event);
  }
</script>

{#if open}
  <div class="modal is-active">
    <div class="modal-background" />

    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">New Provider creation</p>
        <button class="delete" aria-label="close" on:click={handleCancelClick} />
      </header>

      <section class="modal-card-body">Would you like to create a Training Provider or a Digital Innovation Hub?</section>

      <footer class="modal-card-foot modal-confirm-modal-card-foot">
        <button class="button is-primary" on:click={handleCreateOrgClick}> Training Provider </button>

        <button class="button is-primary" on:click={handleCreateDihClick}> Digital Innovation Hub </button>
      </footer>
    </div>
  </div>
{/if}

<style>
  .modal-confirm-modal-card-foot {
    display: inline-block;
    text-align: center;
  }
</style>
