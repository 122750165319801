<script lang="ts">
  import { navigate } from "../../app.logic";
  import Button from "../../components/Button.svelte";
  import Image from "../../components/Image.svelte";
  import type { ISearchCoursesDetailConnectionDTO } from "../../services/DTO/search-courses-detail-connection-dto";
  import { BASE_API_URL } from "../../utils/constants";

  export let conn: ISearchCoursesDetailConnectionDTO;

  function gotoDetails(c: ISearchCoursesDetailConnectionDTO) {
    navigate(`/search/organizations/${c.organizationId}`);
  }
</script>

<div class="card org-link-card">
  <div class="content">
    <div class="org-link-card-info-area">
      {#if conn.hasLogo}
        <figure class="image">
          <Image src={BASE_API_URL + "/organization-logo/" + conn.organizationId} height="5rem" width="auto" disableCache={true} />
        </figure>
      {/if}

      <p class="no-margin">
        <span class="title is-size-5">
          {conn.nameEn}
        </span>

        <span class="is-size-5">&nbsp-&nbsp{conn.address}&nbsp-&nbsp{conn.country}</span>
      </p>

      {#if conn.numMatchingCourses > 0}
        <p class="subtitle is-size-6">
          {#if conn.numMatchingCourses == 1}
            {conn.numMatchingCourses} course available
          {:else}
            {conn.numMatchingCourses} courses available
          {/if}
        </p>
      {/if}
    </div>

    <div>
      <Button klass="connection-nav-button" on:click={() => gotoDetails(conn)}>
        <i class="material-icons">arrow_forward</i>
      </Button>
    </div>
  </div>
</div>

<style>
  .no-margin {
    margin-bottom: 0.2rem !important;
  }
</style>
