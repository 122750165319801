import { writable } from "svelte/store";
import { PortalUserService } from "../../services/portal-user.service";
import { OrganizationService } from "../../services/organization.service";
export const loading = writable(false);
export const records = writable([]);
export const record = writable();
export const organizationsComboItems = writable([]);
export const selectedItem = writable(null);
export const filterValue = writable("");
export async function loadByUser(userId) {
    loading.set(true);
    selectedItem.set(null);
    filterValue.set("");
    organizationsComboItems.set([]);
    record.set(null);
    records.set([]);
    try {
        let _resp = await PortalUserService.getPortalUserOrganizations(userId);
        records.set(_resp.payload);
    }
    finally {
        loading.set(false);
    }
}
export async function savePortalUserOrganization(userId, organizationId) {
    let dto = {
        portalUserId: userId,
        organizationId: organizationId
    };
    let res = await PortalUserService.savePortalUserOrganization(dto);
    record.set(res.payload);
    await loadByUser(userId);
}
export async function deletePortalUserOrganization(userId, oid) {
    await PortalUserService.deletePortalUserOrganization(oid);
    await loadByUser(userId);
}
export async function searchOrganizations(search) {
    let res = await OrganizationService.searchOrganizationsCombo(-1, search);
    organizationsComboItems.set(res.payload);
}
