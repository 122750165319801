import { get, writable } from 'svelte/store';
import { ManagementService } from '../../services/management.service';
export const loading = writable(false);
export const filter = writable({ criteria: {} });
export const records = writable([]);
export const pagination = writable(null);
export async function initList() {
    pagination.set({ currentPage: 1, totalPages: 0, totalRecords: 0 });
    await load();
}
export async function load() {
    loading.set(true);
    try {
        let _filter = get(filter);
        let _pag = get(pagination);
        let _resp = await ManagementService.getDeletionRequests(_filter, _pag.currentPage);
        records.set(_resp.payload);
        pagination.set(_resp.pagination);
    }
    finally {
        loading.set(false);
    }
}
export async function markProcessed(item) {
    let r = item.deletionRequest;
    r.processed = 1;
    await ManagementService.saveDeletionRequest(r);
    await load();
}
export function onPageChange(pageNumber) {
    pagination.set(Object.assign(Object.assign({}, get(pagination)), { currentPage: pageNumber }));
    load();
}
