import { derived, get, writable } from 'svelte/store';
import { navigate } from '../../app.logic';
import { OrganizationService } from '../../services/organization.service';
import { ClassificationService } from '../../services/classification.service';
import { GeocodingService } from '../../services/geocoding.service';
import { isUserRoleADM } from '../../app.store';
import { UUID } from "uuidjs";
export const loading = writable(false);
export const filter = writable({ criteria: {} });
export const records = writable([]);
export const pagination = writable(null);
export const record = writable(null);
export const logoFile = writable(null);
export const logoDc = writable("");
export const orgTechAreas = writable([]);
export const clfTechAreaItems = writable([]);
export const techAreaSelIds = derived(orgTechAreas, $orgTechAreas => $orgTechAreas ? $orgTechAreas.map(x => x.clfTechAreaId) : []);
export const techAreasSelectedItems = derived([clfTechAreaItems, techAreaSelIds], ([$items, $ids]) => $items.filter(value => $ids.findIndex(id => id == value.oid) !== -1));
export const techAreasSelectableItems = derived([clfTechAreaItems, techAreaSelIds], ([$items, $ids]) => $items.filter(value => $ids.findIndex(id => id == value.oid) == -1));
export const geocodingMessage = writable("");
export const geocodingResults = writable([]);
export var OrganizationDetailPage;
(function (OrganizationDetailPage) {
    OrganizationDetailPage[OrganizationDetailPage["OrganizationInfo"] = 1] = "OrganizationInfo";
    OrganizationDetailPage[OrganizationDetailPage["OrganizationCourses"] = 2] = "OrganizationCourses";
    OrganizationDetailPage[OrganizationDetailPage["OrganizationCourse"] = 3] = "OrganizationCourse";
    OrganizationDetailPage[OrganizationDetailPage["OrganizationConnections"] = 4] = "OrganizationConnections";
})(OrganizationDetailPage || (OrganizationDetailPage = {}));
function reset() {
    records.set([]);
    pagination.set({ currentPage: 1, totalPages: 0, totalRecords: 0 });
    record.set(null);
    orgTechAreas.set([]);
    logoFile.set(null);
    logoDc.set(UUID.genV4().toString());
    geocodingMessage.set("");
    geocodingResults.set([]);
}
export async function initList() {
    filter.set({ criteria: { name: "" }, params: null, sorting: "" });
    pagination.set({ currentPage: 1, totalPages: 0, totalRecords: 0 });
    record.set(null);
    await load();
}
export async function maybeInitDetail(oid) {
    let _record = get(record);
    if (_record == null || _record.oid != oid) {
        initDetail(oid);
    }
}
export async function initDetail(oid) {
    loading.set(true);
    reset();
    try {
        if (oid > 0) {
            let _resp = await OrganizationService.fetchOrganization(oid);
            record.set(_resp.payload);
            await loadOrgTechAreas(oid);
            await loadClfTechAreas();
        }
        else {
            let _rec = {
                oid: 0,
                name: "",
                nameEn: "",
                description: "",
                address: "",
                clfCountryId: 0,
                gpsLat: 0.0,
                gpsLon: 0.0,
                vatNumber: "",
                contactName: "",
                contactPhone: "",
                contactEmail: "",
                contactWebsite: "",
                socialNetwork: "",
                videoUrl: "",
                digitalInnovationHub: 0,
                //managerUserId: null,
                approved: 0,
                internalNotes: ""
            };
            if (oid == -2 && get(isUserRoleADM)) {
                _rec.digitalInnovationHub = 1;
            }
            record.set(_rec);
            await loadClfTechAreas();
        }
    }
    finally {
        loading.set(false);
    }
}
export async function search() {
    pagination.set({ currentPage: 1, totalPages: 0, totalRecords: 0 });
    await load();
}
export async function load() {
    loading.set(true);
    try {
        let _filter = get(filter);
        let _pag = get(pagination);
        let _resp = await OrganizationService.getOrganizations(_filter, _pag.currentPage);
        records.set(_resp.payload);
        pagination.set(_resp.pagination);
    }
    finally {
        loading.set(false);
    }
}
export async function loadOrgTechAreas(orgId) {
    let _resp = await OrganizationService.getOrganizationTechAreas(orgId);
    orgTechAreas.set(_resp.payload);
}
export async function loadClfTechAreas() {
    let _resp = await ClassificationService.getAllValuesCombo("TECHAREAXX");
    clfTechAreaItems.set(_resp.payload);
}
export function onCreateOrganization() {
    // console.log("onCreate");
    navigate(`/organizations/-1`);
}
export function onCreateDIH() {
    // console.log("onCreate");
    navigate(`/organizations/-2`);
}
export function onEdit(item) {
    // console.log("onEdit", item);
    navigate(`/organizations/${item.oid}`);
}
export async function save() {
    let _record = get(record);
    let _rec = await OrganizationService.saveOrganization(_record);
    record.set(_rec.payload);
    return _rec;
}
export async function onDelete(item) {
    // console.log("onDelete", item);
    await OrganizationService.deleteOrganization(item.oid);
    await load();
}
export function onPageChange(pageNumber) {
    // console.log("onPageChange: " + pageNumber);
    pagination.set(Object.assign(Object.assign({}, get(pagination)), { currentPage: pageNumber }));
    load();
}
// ======================================================================
export async function onAddTechArea(evt) {
    let techAreaId = evt.detail;
    let items = get(techAreaSelIds);
    let _record = get(record);
    if (_record == null) {
        return;
    }
    if (items.findIndex(val => val == techAreaId) == -1) {
        let dto = {
            oid: 0,
            organizationId: _record.oid,
            clfTechAreaId: techAreaId
        };
        await OrganizationService.saveOrganizationTechArea(dto);
        await loadOrgTechAreas(_record.oid);
    }
}
export async function onDelTechArea(evt) {
    let techAreaId = evt.detail;
    let items = get(orgTechAreas);
    let idx = items.findIndex(val => val.clfTechAreaId == techAreaId);
    if (idx == -1) {
        return;
    }
    let item = items[idx];
    if (item) {
        await OrganizationService.deleteOrganizationTechArea(item.oid);
        await loadOrgTechAreas(item.organizationId);
    }
}
// ======================================================================
export async function geocodeOrganizationAddress() {
    let _rec = get(record);
    if (_rec == null) {
        geocodingMessage.set("Invalid data");
        return;
    }
    if (_rec.clfCountryId == 0) {
        geocodingMessage.set("Country is mandatory for geocoding address");
        return;
    }
    if (_rec.address.trim() == "") {
        geocodingMessage.set("Address to geocode is missing");
        return;
    }
    geocodingMessage.set("");
    geocodingResults.set([]);
    let dto = {
        address: _rec.address,
        clfCountryId: _rec.clfCountryId,
        countryCode: ""
    };
    try {
        let _res = await GeocodingService.geocodeAddress(dto);
        let g = _res.payload;
        if (_res.issue.error || g == null)
            throw new Error("geocode error");
        if (g.found) {
            if (g.results.length == 1) {
                geocodingMessage.set("");
                geocodingResults.set([]);
                setGpsCoodinates(g.results[0]);
            }
            else {
                geocodingMessage.set("");
                geocodingResults.set(g.results);
            }
        }
        else {
            geocodingMessage.set("Could not geocode given address");
        }
    }
    catch (err) {
        geocodingMessage.set("Error while geocoding given address");
    }
}
export function setGpsCoodinates(r) {
    // here we could set address too, but we don't, because country would be doubled while displaying, since we always add clf country too
    geocodingResults.set([]);
    let _rec = get(record);
    if (_rec != null) {
        _rec.gpsLat = r.latitude;
        _rec.gpsLon = r.longitude;
        record.set(_rec);
    }
}
export async function uploadLogo(event) {
    let _rec = get(record);
    let file = event.detail;
    logoFile.set(file);
    await OrganizationService.uploadOrganizationLogo(_rec.oid, file);
    logoFile.set(null);
    logoDc.set(UUID.genV4().toString());
}
