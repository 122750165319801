import { lastValueFrom } from 'rxjs';
import { BaseService } from './base-service';
export class CounterService {
    static trackCounter(name) {
        return lastValueFrom(BaseService.post(`fo/track-counter/${name}`, {}));
    }
    static trackCounter_MapPageViews() {
        return CounterService.trackCounter("map_page_views");
    }
}
