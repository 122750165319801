<script lang="ts">
  import { onMount } from "svelte";
  import FormField from "../../components/Form/FormField.svelte";
  import FormLayout from "../../layout/FormLayout.svelte";
  import Input from "../../components/Form/Fields/Input.svelte";
  import Icon from "../../components/Icon.svelte";
  import { initUserDetail, selfUser, describeRole, save, changePassword, changePasswordDto, passwordChangedMessage } from "./profile.store";
  import LayoutWithSidebar from "../../layout/LayoutWithSidebar.svelte";
  import Container from "../../components/Container.svelte";
  import ImageAsset from "../../components/ImageAsset.svelte";

  $: fullName = $selfUser != null ? $selfUser.firstName + " " + $selfUser.lastName : "";

  $: roleDescription = $selfUser != null ? describeRole($selfUser.portalRoleCode) : "";

  $: canChangePassword =
    $changePasswordDto != null && $changePasswordDto.oldPassword != "" && $changePasswordDto.newPassword1 != "" && $changePasswordDto.newPassword2 != "";

  async function onSubmit() {
    await save();
  }

  async function doChangePassword() {
    await changePassword();
  }

  onMount(() => initUserDetail());
</script>

<LayoutWithSidebar>
  {#if $selfUser}
    <Container>
      <div class="user-profile-parent">
        <article class="panel is-primary profile">
          <p class="panel-heading has-text-centered valign-center-box">
            <Icon name="perm_identity" customIconStyle="color: white;" />
            <span style="padding-left: 0.8rem;">Your Profile</span>
          </p>

          <div class="user-info-banner">
            <div class="valign-center-box">
              <ImageAsset name="profile-image.png" />

              <div>
                <div class="has-text-weight-bold">
                  {fullName}
                </div>

                <div class="is-italic">
                  {roleDescription}
                </div>
              </div>
            </div>

            <hr />
          </div>

          <div class="columns">
            <div class="column">
              <FormLayout on:submit={onSubmit} submitButtonText="Update Profile" cancelEnabled={false}>
                <FormField>
                  <Input placeholder="email" disabled={true} bind:value={$selfUser.email} name="Login e-mail" />
                </FormField>

                <FormField>
                  <Input placeholder="first name" disabled={false} bind:value={$selfUser.firstName} name="First Name" />
                </FormField>

                <FormField>
                  <Input placeholder="last name" disabled={false} bind:value={$selfUser.lastName} name="Last Name" />
                </FormField>
              </FormLayout>
            </div>

            <div class="column">
              {#if $changePasswordDto}
                <FormLayout on:submit={doChangePassword} canSubmit={canChangePassword} submitButtonText="Change password" cancelEnabled={false}>
                  <FormField>
                    <Input
                      placeholder="current password"
                      disabled={false}
                      bind:value={$changePasswordDto.oldPassword}
                      type="password"
                      name="Current Password"
                    />
                  </FormField>

                  <FormField>
                    <Input placeholder="new password" disabled={false} bind:value={$changePasswordDto.newPassword1} type="password" name="New Password" />
                  </FormField>

                  <FormField>
                    <Input
                      placeholder="new password (again)"
                      disabled={false}
                      bind:value={$changePasswordDto.newPassword2}
                      type="password"
                      name="Repeat New Password"
                    />
                  </FormField>

                  {#if $passwordChangedMessage != ""}
                    <article class="message is-danger">
                      <div class="message-body">
                        {$passwordChangedMessage}
                      </div>
                    </article>
                  {/if}
                </FormLayout>
              {/if}
            </div>
          </div>
        </article>
      </div>
    </Container>
  {/if}
</LayoutWithSidebar>

<style>
  .user-profile-parent {
    padding-top: 2rem;
    max-width: 70%;
    margin: auto;
  }
</style>
