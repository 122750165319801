<script lang="ts">
  import { onMount } from "svelte";
  import { Table } from "../../components/Table";
  import LayoutSettings from "./LayoutSettings.svelte";
  import { loading, records, pagination, initList, onPageChange, record } from "./settings-registration-requests.store";
  import Container from "../../components/Container.svelte";
  import SearchHeader from "../../components/SearchHeader.svelte";
  import { registrationRequestFields } from "../../utils/TableFields/registration-request.fields";
  import type { IRegistrationRequest } from "../../entities/registration-request";
  import SettingsRegistrationRequest from "./SettingsRegistrationRequest.svelte";

  function onEdit(item: IRegistrationRequest) {
    record.set(item);
  }

  onMount(initList);
</script>

<LayoutSettings>
  {#if $record != null}
    <SettingsRegistrationRequest />
  {:else}
    <Container>
      <SearchHeader
        entitySingular="deletion request"
        entityPlural="deletion requests"
        numRecords={$pagination != null ? $pagination.totalRecords : 0}
        searchFieldVisible={false}
      />

      <Table
        loading={$loading}
        fields={registrationRequestFields}
        records={$records}
        pagination={$pagination}
        createEnabled={false}
        editEnabled={true}
        deleteEnabled={false}
        on:edit={(e) => onEdit(e.detail)}
        on:pageChange={(e) => onPageChange(e.detail)}
      />
    </Container>
  {/if}
</LayoutSettings>
