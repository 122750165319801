import { lastValueFrom } from 'rxjs';
import { BaseService } from './base-service';
export class OrganizationService {
    static getOrganizations(filter, pageNumber) {
        let qs = encodeURIComponent(JSON.stringify(filter));
        return lastValueFrom(BaseService.getPaginated(`organizations?filter=${qs}`, pageNumber));
    }
    static fetchOrganization(oid) {
        return lastValueFrom(BaseService.get(`organizations/${oid}`));
    }
    static saveOrganization(dto) {
        return lastValueFrom(BaseService.save(`organizations`, dto));
    }
    static deleteOrganization(oid) {
        return lastValueFrom(BaseService.delete(`organizations/${oid}`));
    }
    static searchOrganizationsCombo(selfId, search) {
        let qs = encodeURIComponent(search);
        return lastValueFrom(BaseService.get(`organization-combo-search/${selfId}?search=${qs}`));
    }
    // ----------------------------------------------------------------------
    // Organization Tech Area
    // ----------------------------------------------------------------------
    static getOrganizationTechAreas(organizationId) {
        return lastValueFrom(BaseService.get(`organization-tech-areas/${organizationId}`));
    }
    static saveOrganizationTechArea(dto) {
        return lastValueFrom(BaseService.save(`organization-tech-areas`, dto));
    }
    static deleteOrganizationTechArea(oid) {
        return lastValueFrom(BaseService.delete(`organization-tech-areas/${oid}`));
    }
    // ----------------------------------------------------------------------
    // Organization Connections
    // ----------------------------------------------------------------------
    static getOrganizationConnections(organizationId) {
        return lastValueFrom(BaseService.get(`organization-connections/${organizationId}`));
    }
    static saveOrganizationConnection(dto) {
        return lastValueFrom(BaseService.save(`organization-connections`, dto));
    }
    static deleteOrganizationConnection(oid) {
        return lastValueFrom(BaseService.delete(`organization-connections/${oid}`));
    }
    // ----------------------------------------------------------------------
    // Organization Logo
    // ----------------------------------------------------------------------
    static uploadOrganizationLogo(organizationId, logo) {
        return lastValueFrom(BaseService.uploadFile(`organization-logo-upload/${organizationId}`, logo));
    }
}
