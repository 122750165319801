import { Api } from "../utils/api/api";
import { BASE_API_URL } from "../utils/constants";
export class BaseService {
    static relativePermaUrl(url) {
        return `${this.baseApiUrl}${url}`;
    }
    static relativeUrl(url) {
        const nocache = new Date().getTime();
        const appender = url.indexOf('?') !== -1 ? '&' : '?';
        return `${this.baseApiUrl}/${url}${appender}nocache=${nocache}`;
    }
    static getPaginated(url, pageNumber) {
        let paginatedUrl = this.relativeUrl(url);
        if (pageNumber != null) {
            paginatedUrl += '&pag=' + pageNumber;
        }
        // return Api.get<T>(paginatedUrl).pipe(map(x => [x.payload, x.pagination]));
        return Api.get(paginatedUrl);
    }
    static get(url) {
        return Api.get(this.relativeUrl(url));
    }
    static post(url, data) {
        return Api.post(this.relativeUrl(url), data);
    }
    static save(url, data) {
        return Api.post(this.relativeUrl(url), data);
    }
    static delete(url) {
        return Api.del(this.relativeUrl(url));
    }
    static multipleDelete(url, oids) {
        const dto = { oids };
        const qs = encodeURIComponent(JSON.stringify(dto));
        // DELETE http method does not accept body, we need to use query-string
        return Api.del(this.relativeUrl(url) + '&dd=' + qs);
    }
    static uploadFile(url, file) {
        const formData = new FormData();
        formData.append('fileKey', file, file.name);
        //return Api.post<any, IEmptyDTO>(this.relativeUrl(url), formData);
        //return Api.sendForm<IEmptyDTO>('POST', this.relativeUrl(url), "multipart/form-data", formData);
        return Api.sendForm('POST', this.relativeUrl(url), null, formData);
    }
    // public downloadFile<I, Blob>(url: string, data: I): Observable<Blob> {
    //   return Api.post<Blob>(this.relativeUrl(url), data, { responseType: 'blob' as any });
    // }
    static downloadFile(url, fileName, data) {
        Api.download(this.relativeUrl(url), fileName, data);
    }
}
Object.defineProperty(BaseService, "baseApiUrl", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: BASE_API_URL
});
