import { get, writable } from 'svelte/store';
import { ManagementService } from '../../services/management.service';
export const loading = writable(false);
export const filter = writable({ criteria: {} });
export const records = writable([]);
export const record = writable(null);
export const pagination = writable(null);
export async function initList() {
    records.set([]);
    record.set(null);
    pagination.set({ currentPage: 1, totalPages: 0, totalRecords: 0 });
    await load();
}
export async function load() {
    loading.set(true);
    try {
        let _filter = get(filter);
        let _pag = get(pagination);
        let _resp = await ManagementService.getRegistrationRequests(_filter, _pag.currentPage);
        records.set(_resp.payload);
        pagination.set(_resp.pagination);
    }
    finally {
        loading.set(false);
    }
}
export async function saveRegistration() {
    let item = get(record);
    if (item) {
        let _resp = await ManagementService.saveRegistrationRequest(item);
        record.set(_resp.payload);
        await load();
    }
}
export async function approveRegistration() {
    let item = get(record);
    if (item) {
        let _resp = await ManagementService.approveRegistrationRequest(item);
        record.set(_resp.payload);
        await load();
    }
}
export async function approveRegistrationUserOnly() {
    let item = get(record);
    if (item) {
        let _resp = await ManagementService.approveRegistrationRequestUserOnly(item);
        record.set(_resp.payload);
        await load();
    }
}
export async function rejectRegistration() {
    let item = get(record);
    if (item) {
        let _resp = await ManagementService.rejectRegistrationRequest(item);
        record.set(_resp.payload);
        await load();
    }
}
export function onPageChange(pageNumber) {
    pagination.set(Object.assign(Object.assign({}, get(pagination)), { currentPage: pageNumber }));
    load();
}
