<script lang="ts">
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  function handleSubmit(event: Event) {
    event.preventDefault();
    dispatch("submit", event);
  }
</script>

<form class="form" on:submit={handleSubmit} autocomplete="false">
  <input style="display: none;" name="prevent_autofill" type="text" value="" />
  <slot />
</form>

<style>
  .form {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
  }
</style>
