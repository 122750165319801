<script lang="ts">
  import { onMount } from "svelte";
  import { PortalUserService } from "../../../../services/portal-user.service";
  import type { IDropdownItem } from "../../../../services/DTO/dropdown-item";
  import SearchDropdown from "./SearchDropdown.svelte";

  export let placeholder: string = "";
  export let required: boolean = true;
  export let clearVisible: boolean = false;
  export let filterVisible: boolean = false;
  export let name: string = "";
  export let value: number | null = null;
  export let disabled: boolean = false;

  let items: IDropdownItem[] = [];

  function onFilter(_event: CustomEvent) {
    //   let filter: string = event.detail;
    //   search(filter);
  }

  async function search(_filter: string) {
    let values = await PortalUserService.getPortalRoles();
    if (values == null) {
      items = [];
    } else {
      items = values.payload.map((x) => {
        return { oid: x.oid, label: x.description } as IDropdownItem;
      });
    }
  }

  onMount(() => search(""));
</script>

<SearchDropdown {placeholder} {required} {disabled} {clearVisible} {filterVisible} {name} {items} bind:value on:filter={onFilter} />
