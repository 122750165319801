<script lang="ts">
  export let errors: string[] = [];
</script>

{#if errors != null && errors.length > 0}
  <div class="global-errors-base">
    {#each errors as error}
      <div>
        {error}
      </div>
    {/each}
  </div>
{/if}

<style>
  .global-errors-base {
    margin-bottom: 20px;
    background: #f7b6b6;
    text-align: center;
    border-radius: 2px;
    font-weight: bold;
    font-size: 14px;
    color: #902e2e;
    padding: 20px;
  }
</style>
