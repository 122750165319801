<script lang="ts">
  import { onMount } from "svelte";
  import { get, writable } from "svelte/store";
  import LayoutWithSidebar from "../../layout/LayoutWithSidebar.svelte";
  import Container from "../../components/Container.svelte";
  import SearchHeader from "../../components/SearchHeader.svelte";
  import Table from "../../components/Table/Table.svelte";
  import {
    filter,
    initList,
    loading,
    onCreateOrganization,
    onCreateDIH,
    onDelete,
    onEdit,
    onPageChange,
    pagination,
    records,
    search,
  } from "./organizations.store";
  import { isUserRoleADM, isUserRoleORG, isUserRolePAR } from "../../app.store";
  import { organizationAdminFields, organizationUserFields } from "../../utils/TableFields/organization.fields";
  import type { IOrganization } from "../../entities/organization";
  import CreateOrgVsDihModal from "../../components/Modal/CreateOrgVsDihModal.svelte";
  import { DIH_CREATION_ENABLED } from "../../utils/constants";

  const deletingRecord = writable<IOrganization | null>(null);

  let createModalOpen = false;

  function onDeleteRequested(item: IOrganization | null) {
    deletingRecord.set(item);
  }

  function dispatchDeletionRequestText(rec: IOrganization | null) {
    let name = rec?.name ?? "";

    if (name == "") {
      return `Really delete training provider?`;
    }
    if (get(isUserRoleADM)) {
      return `Really delete training provider "${name}"? Training provider and all associated courses will be immediately deleted.`;
    }
    if (get(isUserRolePAR)) {
      return `Really delete training provider "${name}"? A deletion request will be sent to administrators for approval.`;
    }
    if (get(isUserRoleORG)) {
      return `Really delete training provider "${name}"? A deletion request will be sent to administrators for approval. Your account might get disabled.`;
    }

    return `Really delete training provider "${name}"?`;
  }

  function onCreate() {
    if (DIH_CREATION_ENABLED) {
      if (get(isUserRoleADM)) {
        createModalOpen = true;
      } else if (get(isUserRolePAR)) {
        onCreateOrganization();
      }
    } else {
      onCreateOrganization();
    }
  }

  onMount(() => initList());
</script>

<LayoutWithSidebar>
  <Container>
    <SearchHeader
      entitySingular="training provider"
      entityPlural="training providers"
      numRecords={$pagination != null ? $pagination.totalRecords : 0}
      bind:searchValue={$filter.criteria.name}
      on:search={search}
    ></SearchHeader>

    <CreateOrgVsDihModal bind:open={createModalOpen} on:createOrg={onCreateOrganization} on:createDih={onCreateDIH} />

    {#if $loading == false}
      {#if $pagination != null && $pagination.totalRecords == 0}
        {#if $isUserRolePAR}
          <article class="message is-warning">
            <div class="message-body">
              <p>You have no training providers associated to your account, please contact your administrator.</p>
            </div>
          </article>
        {/if}
        {#if $isUserRoleORG}
          <article class="message is-warning">
            <div class="message-body">
              <p>
                You have no training providers associated to your account, please contact your administrator.
                <br />
                If you're a new user, your profile is probably being configured.
                <br />
                If you've requested your training provider to be deleted, then you no longer have any associated organizations and you may request your account to
                be removed, if you so wish.
              </p>
            </div>
          </article>
        {/if}
      {/if}
    {/if}

    <Table
      loading={$loading}
      fields={$isUserRoleADM ? [...organizationUserFields, ...organizationAdminFields] : organizationUserFields}
      records={$records}
      pagination={$pagination}
      createEnabled={$isUserRoleADM || $isUserRolePAR}
      deleteModalTitle="Delete Training Provider"
      deletionRequestText={dispatchDeletionRequestText($deletingRecord)}
      editEnabled={true}
      deleteEnabled={true}
      on:create={(_) => onCreate()}
      on:edit={(e) => onEdit(e.detail)}
      on:delete={(e) => onDelete(e.detail)}
      on:pageChange={(e) => onPageChange(e.detail)}
      on:deleteRequested={(e) => onDeleteRequested(e.detail)}
    />

    <!--
    case (organizationsStatus) {
      Api.Status::Ok x => renderNoOrganizationsWarning()
      => Html.empty()
    }

    <Table
      hasButtons={true}
      loading={organizationsStatus == Api.Status::Loading}
      addEnabled={Application.isUserRoleADM || Application.isUserRolePAR}
      onAdd={onCreate}
      fields={fields}
      pagination={pagination}
      onPageChange={onPageChange}
      numRecords={Array.size(data)}
      renderBody={renderBody}/>
-->
  </Container>
</LayoutWithSidebar>
