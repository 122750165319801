import { get, writable } from 'svelte/store';
import { PortalUserService } from '../../services/portal-user.service';
import { refreshUserSession, navigateToRoot } from '../../app.logic';
export const selfUser = writable(null);
export const changePasswordDto = writable(null);
export const passwordChangedMessage = writable("");
export async function initUserDetail() {
    selfUser.set(null);
    clearPasswordDto();
    let _resp = await PortalUserService.fetchSelf();
    selfUser.set(_resp.payload);
}
export async function save() {
    let _record = get(selfUser);
    let _resp = await PortalUserService.saveSelf(_record);
    selfUser.set(_resp.payload);
    // realod auth user data and refresh app header
    await refreshUserSession();
    navigateToRoot();
}
export async function changePassword() {
    let _record = get(changePasswordDto);
    clearPasswordDto();
    try {
        await PortalUserService.selfChangePassword(_record);
        passwordChangedMessage.set('Password changed');
    }
    catch (issue) {
        switch (issue.code) {
            case 'NEW_PASS_MISMATCH':
                passwordChangedMessage.set('New password mismatch');
                break;
            case 'OLD_PASS_MISMATCH':
                passwordChangedMessage.set('Incorrect old password');
                break;
            default:
                passwordChangedMessage.set('Password could not be changed, please try again');
        }
    }
}
export function describeRole(roleCode) {
    switch (roleCode) {
        case 'ADM':
            return "Super Administrator";
        case 'PAR':
            return "Partner Administrator";
        case 'ORG':
            return "Organization Manager";
        default:
            return '';
    }
}
function clearPasswordDto() {
    passwordChangedMessage.set("");
    changePasswordDto.set({
        oldPassword: "",
        newPassword1: "",
        newPassword2: "",
    });
}
