import { lastValueFrom } from "rxjs";
import { BaseService } from "./base-service";
export class DashboardService {
    static getDashboardTiles(dto) {
        return lastValueFrom(BaseService.post(`dashboard-tiles`, dto));
    }
    static getNumCoursesByCountry(dto) {
        return lastValueFrom(BaseService.post(`dashboard-num-courses-by-country`, dto));
    }
    static getNumCoursesByType(dto) {
        return lastValueFrom(BaseService.post(`dashboard-num-courses-by-type`, dto));
    }
    static getExpiringCourses(dto) {
        return lastValueFrom(BaseService.post(`dashboard-expiring-courses`, dto));
    }
}
