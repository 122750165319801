<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Errors from "../../Errors.svelte";
  import Label from "../Label.svelte";

  const dispatch = createEventDispatcher();

  export let errors: string[] = [];
  export let placeholder: string = "";
  export let disabled: boolean = false;
  export let required: boolean = false;
  export let value: string = "";
  export let name: string = "";
  export let rows: number = 4;
  export let maxlength: number | null = null;
  export let small: boolean = false;

  $: displayName = required && name != "" ? name + " *" : name;

  function onKeyDown(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      //event.preventDefault();
      dispatch("enter", event);
    } else {
      dispatch("keydown", event);
    }
  }

  function onBlur(event: Event) {
    dispatch("blur", event);
  }
</script>

<div>
  {#if name != ""}
    <Label>
      {displayName}
    </Label>
  {/if}

  <textarea
    class="textarea"
    class:is-danger={required && value == ""}
    class:is-small={small}
    bind:value
    on:keydown={onKeyDown}
    on:blur={onBlur}
    {placeholder}
    {disabled}
    {required}
    {rows}
    {maxlength}
  />

  <Errors {errors} prefix={name} />
</div>
