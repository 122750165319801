<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Errors from "../../Errors.svelte";
  import Label from "../Label.svelte";

  export let errors: string[] = [];
  export let name: string = "";
  export let value: number = 0;
  export let altText: string = "";
  export let disabled: boolean = false;
  export let condensed: boolean = false;
  export let labelSize: string = "";

  const dispatch = createEventDispatcher();

  let checkboxField: HTMLInputElement;

  $: valueAsBool = value == 1;
  $: condensedClass = condensed ? "condensed" : "";
  $: labelSizeStyle = labelSize != "" ? "font-size:" + labelSize : "";

  function handleOnChange(_event: Event) {
    value = checkboxField.checked ? 1 : 0;
    dispatch("toggle", value);
  }
</script>

<div>
  {#if name != ""}
    <Label>
      <span style={labelSizeStyle}>
        {name}
      </span>
    </Label>
  {/if}

  <label class="checkbox">
    <input class={condensedClass} title={altText} {disabled} checked={valueAsBool} bind:this={checkboxField} on:change={handleOnChange} type="checkbox" />

    <slot></slot>
  </label>

  <Errors {errors} prefix={name} />
</div>

<style>
</style>
