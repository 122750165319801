<script lang="ts">
  import { Router, Route, NotFoundRoute, redirect } from "./utils/pager";
  import { isUserRoleADM, user } from "./app.store";
  import { initialize } from "./app.logic";
  import { onMount } from "svelte";
  import { get } from "svelte/store";
  import ProfilePage from "./pages/Profile/ProfilePage.svelte";
  import NotFoundPage from "./pages/NotFound/NotFoundPage.svelte";
  import MapPage from "./pages/Map/MapPage.svelte";
  import SignInPage from "./pages/SignIn/SignInPage.svelte";
  import SignUpPage from "./pages/SignUp/SignUpPage.svelte";
  import OrganizationsPage from "./pages/Organization/OrganizationsPage.svelte";
  import SearchOrganizationsPage from "./pages/Search/SearchOrganizationsPage.svelte";
  import MapDetailPage from "./pages/Map/MapDetailPage.svelte";
  import OrganizationInfoPage from "./pages/Organization/OrganizationInfoPage.svelte";
  import OrganizationCoursesPage from "./pages/Organization/OrganizationCoursesPage.svelte";
  import OrganizationConnectionsPage from "./pages/Organization/OrganizationConnectionsPage.svelte";
  import OrganizationCoursePage from "./pages/Organization/OrganizationCoursePage.svelte";
  import SettingsUserListPage from "./pages/Settings/SettingsUserListPage.svelte";
  import SettingsRegistrationRequestsPage from "./pages/Settings/SettingsRegistrationRequestsPage.svelte";
  import SettingsDeletionRequestsPage from "./pages/Settings/SettingsDeletionRequestsPage.svelte";
  import SettingsSystemDataPage from "./pages/Settings/SettingsSystemDataPage.svelte";
  import SettingsUserDetailPage from "./pages/Settings/SettingsUserDetailPage.svelte";
  import DashboardPage from "./pages/Dashboard/DashboardPage.svelte";
  import PersonalDataProcessingInfoPage from "./pages/Informative/PersonalDataProcessingInfoPage.svelte";
  import PrivacyPolicyInfoPage from "./pages/Informative/PrivacyPolicyInfoPage.svelte";

  // const data1 = { foo: "bar", custom: true };

  const loginGuard = (_ctx: any, next: any) => {
    // user.subscribe((u) => {
    // console.log("loginGuard user:", u);
    let u = get(user);
    // check if user is authenticated
    if (u === null) {
      // redirect("/login");
      initialize();
    } else {
      // go to the next callback in the chain
      next();
      //redirect("/dashboard");
    }
    // });
  };

  const adminGuard = (_ctx: any, next: any) => {
    let adm = get(isUserRoleADM);
    if (!adm) {
      redirect("/");
    } else {
      next();
    }
  };

  const defaultRouteGuard = (_ctx: any, _next: any) => {
    redirect("/map");
  };

  const settingsRouteGuard = (_ctx: any, _next: any) => {
    redirect("/settings/users");
  };

  onMount(initialize);
</script>

<Router>
  <Route path="/" middleware={[defaultRouteGuard]} component={null}>
    <div class="initial-loader" />
  </Route>
  <Route path="/sign-in" component={SignInPage} />
  <Route path="/sign-up" component={SignUpPage} />

  <Route path="/info/personal-data-processing" component={PersonalDataProcessingInfoPage} />
  <Route path="/info/privacy-policy" component={PrivacyPolicyInfoPage} />

  <Route path="/profile" middleware={[loginGuard]} component={ProfilePage} />

  <Route path="/map" component={MapPage} />
  <Route path="/map/detail" component={MapDetailPage} />
  <Route path="/map/:oid" component={MapDetailPage} />

  <Route path="/search/organizations" component={SearchOrganizationsPage} />
  <Route path="/search/organizations/:oid" component={MapDetailPage} />

  <Route path="/organizations" component={OrganizationsPage} />
  <Route path="/organizations/:orgId" component={OrganizationInfoPage} />
  <Route path="/organizations/:orgId/courses" component={OrganizationCoursesPage} />
  <Route path="/organizations/:orgId/courses/:courseId" component={OrganizationCoursePage} />
  <Route path="/organizations/:orgId/connections" component={OrganizationConnectionsPage} />

  <!-- DASHBOARD -->
  <Route path="/dashboard" middleware={[loginGuard, adminGuard]} component={DashboardPage} />

  <!-- ADMIN -->
  <Route path="/settings" middleware={[loginGuard, adminGuard, settingsRouteGuard]} component={null} />
  <Route path="/settings/users" middleware={[loginGuard, adminGuard]} component={SettingsUserListPage} />
  <Route path="/settings/users/:oid" middleware={[loginGuard, adminGuard]} component={SettingsUserDetailPage} />
  <Route path="/settings/registration-requests" middleware={[loginGuard, adminGuard]} component={SettingsRegistrationRequestsPage} />
  <Route path="/settings/deletion-requests" middleware={[loginGuard, adminGuard]} component={SettingsDeletionRequestsPage} />
  <Route path="/settings/system-data" middleware={[loginGuard, adminGuard]} component={SettingsSystemDataPage} />
  <NotFoundRoute>
    <NotFoundPage />
  </NotFoundRoute>
</Router>

<!--<style>
  main {
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }
  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
  nav a {
    padding-right: 3rem;
  }
</style>-->
