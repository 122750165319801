import { get, writable } from 'svelte/store';
import { navigate } from '../../app.logic';
import { PortalUserService } from '../../services/portal-user.service';
export const loading = writable(false);
export const filter = writable(null);
export const records = writable([]);
export const pagination = writable(null);
export const record = writable(null);
export const resetPasswordVisible = writable(false);
export const passwordResetMessage = writable("");
export const userPromoteMessage = writable("");
export async function initList() {
    filter.set({ criteria: {}, params: null, sorting: "" });
    pagination.set({ currentPage: 1, totalPages: 0, totalRecords: 0 });
    record.set(null);
    await load();
}
export async function initDetail(oid) {
    record.set(null);
    passwordResetMessage.set("");
    if (oid == 0) {
        //console.log("new portal user: ");
        record.set({ oid: 0, active: 1, newPassword: '' });
        resetPasswordVisible.set(false);
    }
    else {
        let _resp = await PortalUserService.fetchPortalUser(oid);
        //console.log("fetched portal user: ", _resp);
        record.set(Object.assign(Object.assign({}, _resp.payload), { newPassword: '' }));
        resetPasswordVisible.set(true);
    }
}
export async function load() {
    loading.set(true);
    try {
        let _filter = get(filter);
        let _pag = get(pagination);
        let _resp = await PortalUserService.getPortalUsers(_filter, _pag.currentPage);
        records.set(_resp.payload);
        pagination.set(_resp.pagination);
    }
    finally {
        loading.set(false);
    }
}
export function onCreate() {
    // console.log("onCreate");
    navigate(`/settings/users/0`);
}
export function onEdit(item) {
    // console.log("onEdit", item);
    navigate(`/settings/users/${item.oid}`);
}
export async function save() {
    let _record = get(record);
    return await PortalUserService.savePortalUser(_record);
}
export async function onDelete(item) {
    // console.log("onDelete", item);
    await PortalUserService.deletePortalUser(item.oid);
    await load();
}
export function onPageChange(pageNumber) {
    // console.log("onPageChange: " + pageNumber);
    pagination.set(Object.assign(Object.assign({}, get(pagination)), { currentPage: pageNumber }));
    load();
}
export async function resetPassword() {
    let _record = get(record);
    let dto = { userId: _record.oid, newPassword: _record.newPassword };
    let _resp = await PortalUserService.resetPassword(dto);
    record.set(Object.assign(Object.assign({}, _record), { newPassword: '' }));
    passwordResetMessage.set('Password changed.');
}
export async function promoteRole() {
    let user = get(record);
    userPromoteMessage.set("");
    try {
        if (user != null) {
            let dto = {
                userId: user.oid,
                roleId: -1
            };
            let resp = await PortalUserService.changeRole(dto);
            if (resp.issue.error)
                throw new Error("service error");
            await initDetail(user.oid);
            userPromoteMessage.set("User role has been promoted");
        }
        else {
            throw new Error("null user");
        }
    }
    catch (err) {
        userPromoteMessage.set("Could not promote user role, please try again");
    }
}
