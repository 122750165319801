<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Form from "../components/Form/Form.svelte";
  import GlobalErrors from "../components/GlobalErrors.svelte";
  import Button from "../components/Button.svelte";
  import Container from "../components/Container.svelte";

  export let errors: string[] = [];
  export let isNew: boolean = false;
  export let canSubmit: boolean = true;
  export let disabled: boolean = false;
  export let submitEnabled: boolean = true;
  export let cancelEnabled: boolean = true;
  export let submitButtonText: string = "Save";
  export let createButtonText: string = "Create";
  export let cancelButtonText: string = "Cancel";
  export let saveButtonClass: string = "is-primary";

  const dispatch = createEventDispatcher();

  function handleSubmit(event: Event) {
    dispatch("submit", event);
  }

  function handleCancel(event: Event) {
    dispatch("cancel", event);
  }
</script>

<Container>
  <GlobalErrors {errors} />

  <Form>
    <slot />

    <hr class="form-hr" />

    <div class="field is-grouped form-btn-centered">
      <div class="padding" />
      {#if submitEnabled}
        <div class="control">
          <Button klass={saveButtonClass} disabled={disabled || !canSubmit} on:click={handleSubmit}>
            {isNew ? createButtonText : submitButtonText}
          </Button>
        </div>
      {/if}

      {#if cancelEnabled}
        <div class="control">
          <Button klass="is-light" {disabled} on:click={handleCancel}>
            {cancelButtonText}
          </Button>
        </div>
      {/if}
      <div class="padding" />
    </div>
  </Form>
</Container>

<style>
  .form-hr {
    border: 0;
    border-top: 1px solid #eee;
    margin: 20px 0;
  }

  .form-btn-centered {
    margin: 0 auto;
  }

  .form-btn-centered .control {
    margin: 0 2rem;
  }

  .form-btn-centered .padding {
    margin: 0 auto;
  }
</style>
