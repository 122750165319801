<script lang="ts">
  export let klass: string = "";
  export let usageHelp: string = "";
</script>

<div class="field {klass}">
  <div class="control">
    <slot />
  </div>

  {#if usageHelp != ""}
    <p class="help is-dark is-italic">
      {usageHelp}
    </p>
  {/if}
</div>
