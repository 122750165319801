<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import type { ISearchCoursesDetailDTO } from "../../../services/DTO/search-courses-detail-dto";
  import { BASE_API_URL } from "../../../utils/constants";
  import { patchUrl } from "../../../utils/misc-utils";
  import Button from "../../Button.svelte";
  import Icon from "../../Icon.svelte";
  import Image from "../../Image.svelte";
  import ImageAsset from "../../ImageAsset.svelte";

  export let poiDetail: ISearchCoursesDetailDTO;

  const dispatch = createEventDispatcher();

  function gotoDetails() {
    //navigate("/map/detail");
    dispatch("detailClick", poiDetail);
  }
</script>

{#if !!poiDetail}
  {#if poiDetail.hasLogo}
    <figure class="image">
      <Image src={BASE_API_URL + "/organization-logo/" + poiDetail.organizationId} height="auto" width="10rem" disableCache={true} />
    </figure>
  {/if}

  <div class="content">
    <p style="margin-bottom: 0;">
      <span class="title is-size-6"> {poiDetail.nameEn} </span>
      <span class="subtitle is-size-6">&nbsp-&nbsp;{poiDetail.address}&nbsp-&nbsp;{poiDetail.country}</span>
    </p>

    <p class="is-size-7 is-italic">
      {#if poiDetail.digitalInnovationHub == 1}
        Digital Innovation Hub
      {:else}
        Training Provider
      {/if}
    </p>

    {#if poiDetail.contactWebsite != ""}
      <hr style="margin: 0.2rem;" />

      <div class="valign-center-box" style="margin-left: 0.3rem;">
        <ImageAsset name="icon_web.png" />

        <a class="subtitle is-size-7" style="padding-left: 0.8rem;" href={poiDetail.contactWebsite} target="_blank">
          {patchUrl(poiDetail.contactWebsite)}
        </a>
      </div>
    {/if}

    <hr style="margin: 0.2rem;" />

    <div class="columns is-gapless">
      <div class="column is-8">
        {#if poiDetail.courses != null && poiDetail.courses.length > 0}
          <div class="valign-center-box" style="margin-left: 0.3rem;margin-top: 0.2rem;">
            <ImageAsset name="icon_courses_white.png" />
            <p class="subtitle is-size-7" style="padding-left: 0.8rem; margin-bottom: 0;">
              {#if poiDetail.courses.length == 1}
                {poiDetail.courses.length} course available
              {:else}
                {poiDetail.courses.length} courses available
              {/if}
            </p>
          </div>
        {/if}
      </div>

      <div class="column is-4 has-text-right">
        <Button klass="is-primary is-small" on:click={(_) => gotoDetails()}>
          <Icon name="expand_more" />
          <span class="is-size-7">Details</span>
        </Button>
      </div>
    </div>
  </div>
{/if}
