<script lang="ts">
  import MiniSectionWithUrl from "./MiniSectionWithUrl.svelte";
  import MiniSectionWithText from "./MiniSectionWithText.svelte";
  import MiniContactChannel from "./MiniContactChannel.svelte";
  import { detail } from "./map-detail.store";
  import { patchUrl } from "../../utils/misc-utils";
  import MaybeTags from "./MaybeTags.svelte";
</script>

{#if !!$detail}
  <div class="card">
    <div class="columns is-gapless">
      <div class="column is-9">
        <div style="padding: 1rem;padding-right: 5rem;">
          <div class="content">
            <p class="noMargin">
              <span class="title is-size-4">
                {$detail.nameEn}
              </span>

              <span class="subtitle is-size-5">
                &nbsp;-&nbsp;{$detail.address}&nbsp;-&nbsp;{$detail.country}
              </span>
            </p>

            <p class="is-size-5 has-text-grey-light is-italic noMargin">
              {$detail.name}
            </p>

            <hr style="margin-top: 0.5rem;" />
          </div>

          {#if $detail.digitalInnovationHub == 1}
            <MaybeTags title="Topics" tagClass="is-primary" items={$detail.dihTechAreas} />
          {/if}

          <div class="content has-text-justified" style="margin-bottom: 2rem;">
            {$detail.description}
          </div>

          {#if $detail.videoUrl != ""}
            <MiniSectionWithUrl icon="slideshow" title="Video Presentation" url={$detail.videoUrl} />
          {/if}

          {#if $detail.socialNetwork != ""}
            <MiniSectionWithUrl icon="share" title="Social Network" url={$detail.socialNetwork} />
          {/if}

          {#if $detail.contactName != ""}
            <MiniSectionWithText icon="person" title="Contact Person" text={$detail.contactName} />
          {/if}

          <div class="content">
            {#if $detail.contactPhone != ""}
              <MiniContactChannel icon="phone" label={$detail.contactPhone} url={"tel:" + $detail.contactPhone} />
            {/if}

            {#if $detail.contactEmail != ""}
              <MiniContactChannel icon="email" label={$detail.contactEmail} url={"mailto:" + $detail.contactEmail} />
            {/if}

            {#if $detail.contactWebsite != ""}
              <MiniContactChannel icon="language" label={patchUrl($detail.contactWebsite)} url={$detail.contactWebsite} />
            {/if}
          </div>
        </div>
      </div>

      <div class="column is-3 buildingImage" />
    </div>
  </div>
{/if}

<style>
  .noMargin {
    margin-bottom: 0.2rem !important;
  }

  .buildingImage {
    background: url("/img/edificio.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
</style>
