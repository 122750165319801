<script lang="ts">
  import Image from "../../components/Image.svelte";
  import Button from "../../components/Button.svelte";
  import type { ISearchCoursesDetailConnectionDTO } from "../../services/DTO/search-courses-detail-connection-dto";
  import { navigate } from "../../app.logic";
  import { BASE_API_URL } from "../../utils/constants";
  import { detail, initDetail } from "./map-detail.store";

  function gotoConnectionDetails(c: ISearchCoursesDetailConnectionDTO) {
    navigate(`/map/${c.organizationId}`);
    initDetail(c.organizationId);
  }
</script>

{#if $detail != null}
  {#each $detail.connections as conn}
    <div class="card org-link-card">
      <div class="content">
        <div class="org-link-card-info-area">
          {#if conn.hasLogo}
            <figure class="image">
              <Image src={BASE_API_URL + "/organization-logo/" + conn.organizationId} height="5rem" width="auto" disableCache={true} />
            </figure>
          {/if}

          <p class="noMargin">
            <span class="title is-size-5"> {conn.nameEn} </span>
            <span class="is-size-5">&nbsp;-&nbsp;{conn.address}&nbsp;-&nbsp;{conn.country}</span>
          </p>

          <p class="noMargin">
            <span class="is-size-6"> {conn.connectionDescription} </span>
          </p>

          {#if conn.numMatchingCourses > 0}
            <p class="subtitle is-size-6 has-text-weight-semibold" style="color:#777;">
              {#if conn.numMatchingCourses == 1}
                {conn.numMatchingCourses} course available
              {:else}
                {conn.numMatchingCourses} courses available
              {/if}
            </p>
          {/if}
        </div>

        <div>
          <Button klass="connection-nav-button" on:click={(_) => gotoConnectionDetails(conn)}>
            <i class="material-icons">arrow_forward </i>
          </Button>
        </div>
      </div>
    </div>
  {/each}
{/if}

<style>
  .noMargin {
    margin-bottom: 0.2rem !important;
  }
</style>
