import { get } from 'svelte/store';
import { sid, user } from './app.store';
import { AuthService } from './services/auth.service';
import { redirect } from './utils/pager';
import { clfForceReload } from './classification.store';
// import page from 'page';
const SID_KEY = "eecone.sid";
const ROOT_URL = "/";
export function navigate(path) {
    redirect(path);
}
export function navigateToRoot() {
    navigate(ROOT_URL);
}
export async function login(u) {
    resetStores();
    storeUserSession(u);
    navigateToRoot();
}
function storeUserSession(u) {
    try {
        let newSid = u.token;
        window.localStorage.setItem(SID_KEY, newSid);
        sid.set(newSid);
        user.set(u);
    }
    catch (err) {
        //user.set(null);
        //sid.set("");
        logout();
    }
}
export async function refreshUserSession() {
    try {
        // console.log("refreshUserSession: called");
        let _resp = await AuthService.refresh();
        let authUserDto = _resp.payload;
        storeUserSession(authUserDto);
        return true;
    }
    catch (err) {
        // console.error("error refreshing user session", err);
        logout();
    }
    return false;
}
function clearUserSession() {
    //console.log("clearUserSession: called");
    window.localStorage.removeItem(SID_KEY);
    resetStores();
    user.set(null);
    sid.set("");
}
export async function logout() {
    //console.log("logout: called");
    await AuthService.logout();
    clearUserSession();
    // navigateToRoot();
    // redirect("/sign-in");
    // hard, to reload page
    window.location.href = '/sign-in';
}
export async function initialize() {
    // console.log("initialize: called");
    if (get(user) != null) {
        // console.log("session user", get(user));
        return;
    }
    // console.log("initialize: no user loaded");
    let currSid = window.localStorage.getItem(SID_KEY);
    // console.log("currSid: " + currSid);
    if (currSid != null && currSid != "") {
        sid.set(currSid);
        let ok = await refreshUserSession();
        if (ok) {
            navigateToRoot();
            loadStores();
        }
    }
    else {
        loadStores();
        // console.log("initialize: no sid loaded, logging out");
        clearUserSession();
    }
}
function resetStores() {
    clfForceReload();
}
function loadStores() {
    resetStores();
}
