import { formatTimestampHuman } from "../date-utils";
export const deletionRequestDtoFields = [{
        title: "Requestor",
        titleClass: "",
        dataClass: "",
        name: "portalUserName",
        render: (record) => {
            if (record.deletionRequest.userId)
                return `<a href="/settings/users/${record.deletionRequest.userId}">${record.portalUserName}</a>`;
            else
                return `<span>${record.portalUserName}</span>`;
        }
    }, {
        title: "Organization",
        titleClass: "",
        dataClass: "",
        name: "organizationName",
        render: (record) => `<a href="/organizations/${record.organization.oid}">${record.organization.nameEn}</a>`
    }, {
        title: "Country",
        titleClass: "",
        dataClass: "",
        name: "country",
        render: (record) => record.country
        /*
      }, {
        title: "Status",
        titleClass: "has-text-centered",
        dataClass: "has-text-centered",
        name: "status",
        render: (record: IDeletionRequestDTO) =>
          `<i class="material-icons">${record.deletionRequest.processed === 1 ? "check" : "help_outline"}</i>`
      }, {
        title: "Reason",
        titleClass: "",
        dataClass: "",
        name: "reason",
        render: (record: IDeletionRequestDTO) => record.deletionRequest.reason)
      */
    }, {
        title: "Request Date",
        titleClass: "",
        dataClass: "",
        name: "createdOn",
        render: (record) => formatTimestampHuman(record.deletionRequest.createdOn)
    }];
