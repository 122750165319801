<script lang="ts">
  import Container from "../components/Container.svelte";
  import MainMenu from "../components/MainMenu/MainMenu.svelte";
  import BaseLayout from "./BaseLayout.svelte";
</script>

<BaseLayout>
  <div class="main-layout">
    <MainMenu />

    <div class="main-layout-content">
      <Container>
        <div class="main-layout-content-inner">
          <slot />
        </div>
      </Container>
    </div>
  </div>
</BaseLayout>

<style>
  .main-layout {
    display: flex;
    flex-direction: row;
    flex: 1;
    max-height: calc(100% - 4rem); /* must match header navbar height */
  }

  .main-layout-content {
    animation: fadein 0.8s;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
  }

  .main-layout-content-inner {
    flex: 1;
    overflow: hidden;
  }
</style>
