<script lang="ts">
  import { onMount } from "svelte";
  import { init, signupRequest, loading, doSubmit, errors, outcome } from "./signup.store";
  import Button from "../../components/Button.svelte";
  import { DATA_PROCESSING_URL, PRIVACY_URL } from "../../utils/constants";
  import LayoutOutside from "../../layout/LayoutOutside.svelte";
  import Form from "../../components/Form/Form.svelte";
  import GlobalErrors from "../../components/GlobalErrors.svelte";
  import FormField from "../../components/Form/FormField.svelte";
  import Input from "../../components/Form/Fields/Input.svelte";
  import ClfDropdown from "../../components/Form/Fields/Dropdown/ClfDropdown.svelte";
  import Textarea from "../../components/Form/Fields/Textarea.svelte";
  import Checkbox from "../../components/Form/Fields/Checkbox.svelte";
  import ConfirmModal from "../../components/Modal/ConfirmModal.svelte";

  let confirmModalOpen = false;

  $: canSubmit =
    $signupRequest != null &&
    $signupRequest.firstName != "" &&
    $signupRequest.lastName != "" &&
    $signupRequest.email != "" &&
    $signupRequest.password != "" &&
    $signupRequest.organizationName != "" &&
    $signupRequest.address != "" &&
    $signupRequest.clfCountryId != 0 &&
    $signupRequest.organizationDescription != "" &&
    $signupRequest.privacyAccepted == 1;

  $: buttonText = $loading ? "Please wait, registration in progress..." : "Sign Up";

  function submitShowConsent() {
    confirmModalOpen = true;
  }

  function submitWithCaptcha() {
    doSubmit();
    confirmModalOpen = false;
  }

  function declineSubmit() {
    confirmModalOpen = false;
  }

  onMount(() => init());
</script>

<LayoutOutside title="Sign Up" subtitle={$outcome == null ? "Welcome! Please fill-in the form to register your account." : ""}>
  {#if $outcome == null}
    <ConfirmModal
      title="Explicit Consent"
      message="Having read the information notice, I CONSENT TO THE COLLECTING AND PROCESSING OF PERSONAL DATA to be registered in the personal area of ​​this website, and I agree that NEROSUBIANCO Srl will use the registered e-mail address to send communications of any additional services to the Digital Industry Training Atlas."
      bind:open={confirmModalOpen}
      on:confirm={submitWithCaptcha}
      on:decline={declineSubmit}
    />

    <Form>
      <GlobalErrors errors={$errors} />

      <div class="columns sign-up-group">
        <div class="column">
          <FormField>
            <Input placeholder="First Name" bind:value={$signupRequest.firstName} disabled={false} required={true} name="" />
          </FormField>

          <FormField>
            <Input placeholder="Last Name" bind:value={$signupRequest.lastName} disabled={false} required={true} name="" />
          </FormField>

          <FormField>
            <Input placeholder="E-mail" bind:value={$signupRequest.email} disabled={false} required={true} type="text" name="" />
          </FormField>

          <FormField>
            <Input placeholder="Password" bind:value={$signupRequest.password} disabled={false} required={true} type="password" name="" />
          </FormField>
        </div>

        <div class="column">
          <FormField>
            <Input placeholder="Organization Name" bind:value={$signupRequest.organizationName} disabled={false} required={true} name="" />
          </FormField>

          <FormField>
            <Input placeholder="Organization Address" bind:value={$signupRequest.address} disabled={false} required={true} name="" />
          </FormField>

          <FormField>
            <ClfDropdown placeholder="Organization Country" bind:value={$signupRequest.clfCountryId} schema="COUNTRYXXX" required={true} name="" />
          </FormField>

          <FormField>
            <Input
              placeholder="VAT Number (with country code)"
              maxlength={20}
              bind:value={$signupRequest.vatNumber}
              disabled={false}
              required={false}
              name=""
            />
          </FormField>
        </div>
      </div>

      <div class="columns sign-up-group">
        <div class="column">
          <FormField>
            <Textarea
              placeholder="Please type a short description of your organization"
              bind:value={$signupRequest.organizationDescription}
              disabled={false}
              required={true}
              name=""
            />
          </FormField>
        </div>
      </div>

      <div class="has-text-centered">
        <Checkbox bind:value={$signupRequest.privacyAccepted} disabled={false}>
          I have read the <a href={DATA_PROCESSING_URL} target="_blank">Personal Data Processing Information Notice</a> and the
          <a href={PRIVACY_URL} target="_blank">Privacy Policy</a>.
        </Checkbox>
      </div>

      <hr class="hr" />

      <div class="field is-grouped">
        <div class="control btn-centered">
          <Button klass="is-primary" disabled={!canSubmit || $loading} on:click={submitShowConsent}>{buttonText}</Button>
        </div>
      </div>
    </Form>
  {:else if $outcome.success == true}
    <article class="message is-success">
      <div class="message-body">
        <p class="has-text-weight-bold">Thank you for registering. Your request has been processed.</p>
        <p>An e-mail message has been sent to you.</p>
        <p>You can now sign in with the credentials you created and manage your organization and your courses.</p>
      </div>
    </article>
    <div class="has-text-centered">
      <a class="button is-primary" href="/sign-in">Sign in</a>
    </div>
  {:else}
    <article class="message is-danger">
      <div class="message-body">
        <p>{$outcome.message}</p>
      </div>
    </article>
  {/if}
</LayoutOutside>

<style>
  .btn-centered {
    margin: 0 auto;
  }

  .hr {
    border: 0;
    border-top: 1px solid #eee;
    margin: 20px 0;
  }
</style>
