<script lang="ts">
  import Header from "../components/Header.svelte";
</script>

<div class="base-layout">
  <Header />
  <slot />
</div>

<style>
  .base-layout {
    flex-direction: column;
    /*background: #eae5ef;*/
    display: flex;
    flex: 1;
    width: 100%;
  }
</style>
