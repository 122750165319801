<script>
  import Container from "../components/Container.svelte";
  import { PRIVACY_URL } from "../utils/constants";
  import Layout from "./Layout.svelte";

  export let title = "";
  export let subtitle = "";
</script>

<Layout>
  <div class="splash">
    <img src="/img/background-splash.jpg" alt="splash" />
  </div>

  <Container customStyle="z-index: 1000;display: flex;flex-direction: column;margin-bottom: 10rem;padding: 0;">
    <div class="base">
      <div class="form outside-form">
        <div class="title">{title}</div>

        {#if subtitle != ""}
          <div class="subtitle">{subtitle}</div>
        {/if}

        <hr class="hr" />

        <slot />
      </div>
    </div>

    <div class="bottom-bar">
      <a class="bottom-bar-link" href={PRIVACY_URL} target="_blank">Privacy Policy</a>
      <img class="eu-funded" src="/img/eu_funded.png" alt="EU Funded" />

      <div class="bottom-bar-disclaimer">
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </div>
  </Container>
</Layout>

<style>
  .splash {
    position: absolute;
    display: flex;

    /* top: 3.25rem; */
    top: 56px;
    bottom: 3rem;
    left: 0;
    right: 0;
    z-index: 1;
    background: #ffffff;
  }

  .splash img {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
  }

  .base {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .form {
    flex-direction: column;
    max-width: 800px;
    margin: auto;
    width: 100%;

    background-color: var(--color-gray-4);

    /* opacity: 0.85; */
    padding: 2rem;
    padding-left: 5rem;
    padding-right: 5rem;
    border-radius: 10px;
    box-shadow: 0 80px 45px -45px #dddde0;
  }

  /*
  .brand {
    line-height: 50px;
    margin-left: 5px;
    height: 40px;
  }
  */

  .hr {
    border: 0;
    border-top: 1px solid #eee;
    margin: 20px 0;
  }

  .title {
    text-align: center;
    margin-top: 1rem;
    font-size: 1.5rem;
  }

  .subtitle {
    text-align: center;
    margin-top: 0.5rem;
    font-size: 0.9rem;
  }

  .bottom-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    color: #003399;
    font-size: 0.8rem;
    /* background-image: radial-gradient(circle at top right, #0c71c3 0%, #7cda24 100%); */
    background-color: #c5e0b4;
  }

  .bottom-bar-link {
    color: #003399;
    display: inline-block;
    float: right;
    margin-right: 1.5rem;
  }

  .bottom-bar-disclaimer {
    font-size: 0.75rem;
    color: #003399;
    display: inline-block;
    line-height: 1rem;
    margin-top: 0.5rem;
    text-align: left;
  }

  .bottom-bar-disclaimer p {
    margin-bottom: 0;
  }

  .eu-funded {
    height: 48px;
    float: left;
    margin-left: 5px;
  }
</style>
