export const clfValueFields = [{
        name: "code",
        title: "Code"
    }, {
        name: "displayOrder",
        title: "Display Order"
    }, {
        name: "description",
        titleClass: "table-description-field",
        dataClass: "table-description-field",
        title: "Description"
    }];
