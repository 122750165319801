<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Errors from "../../Errors.svelte";
  import Label from "../Label.svelte";

  const dispatch = createEventDispatcher();

  export let errors: string[] = [];
  export let placeholder: string = "";
  export let disabled: boolean = false;
  export let required: boolean = false;
  export let small: boolean = false;
  export let value: string = "";
  export let name: string = "";
  export let type: string = "text";
  export let maxlength: number | null = null;

  $: displayName = required && name != "" ? name + " *" : name;

  function onKeyDown(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      event.preventDefault();
      dispatch("enter", event);
    } else {
      dispatch("keydown", event);
    }
  }

  function onBlur(event: Event) {
    dispatch("blur", event);
  }
</script>

<div>
  {#if name != ""}
    <Label>
      {displayName}
    </Label>
  {/if}

  {#if type === "text"}
    <input
      class="input"
      class:is-danger={required && value == ""}
      class:is-small={small}
      class:input-ctr-disabled={disabled}
      bind:value
      on:keydown={onKeyDown}
      on:blur={onBlur}
      {placeholder}
      {disabled}
      {required}
      type="text"
      {maxlength}
    />
  {/if}

  {#if type === "password"}
    <input
      class="input"
      class:is-danger={required && value == ""}
      class:is-small={small}
      class:input-ctr-disabled={disabled}
      bind:value
      on:keydown={onKeyDown}
      on:blur={onBlur}
      {placeholder}
      {disabled}
      {required}
      type="password"
      {maxlength}
    />
  {/if}

  <Errors {errors} prefix={name} />
</div>

<style>
  .input-ctr-disabled {
    border-color: #dbdbdb;
  }
</style>
