export const courseDtoFields = [{
        name: "titleEn",
        title: "Course Title (English)",
        titleClass: "min-w-13",
        dataClass: "",
        render: (record) => record.titleEn
    }, {
        name: "titleEn",
        title: "Course Title (original)",
        titleClass: "",
        dataClass: "",
        render: (record) => record.title
    }, {
        name: "published",
        title: "Published",
        titleClass: "has-text-centered",
        dataClass: "has-text-centered",
        render: (record) => `<i class="material-icons">${record.published === 1 ? "check" : ""}</i>`
    }];
