import { derived, get, writable } from 'svelte/store';
import { CourseService } from '../../services/course.service';
import { navigate } from '../../app.logic';
import { UUID } from 'uuidjs';
export const loading = writable(false);
export const records = writable([]);
export const record = writable(null);
export const logoFile = writable(null);
export const logoDc = writable("");
export const courseChannels = writable([]);
export const channelSelIds = derived(courseChannels, $items => $items ? $items.map(x => x.clfChannelId) : []);
export const courseLanguages = writable([]);
export const languageSelIds = derived(courseLanguages, $items => $items ? $items.map(x => x.clfLanguageId) : []);
export const courseTechAreas = writable([]);
export const techAreaSelIds = derived(courseTechAreas, $items => $items ? $items.map(x => x.clfTechAreaId) : []);
export const courseEduLevels = writable([]);
export const eduLevelSelIds = derived(courseEduLevels, $items => $items ? $items.map(x => x.clfEducationLevelId) : []);
export async function reset() {
    record.set(null);
    logoFile.set(null);
    logoDc.set(UUID.genV4().toString());
    courseChannels.set([]);
    courseLanguages.set([]);
    courseTechAreas.set([]);
    courseEduLevels.set([]);
}
export async function loadByOrganization(organizationId) {
    loading.set(true);
    try {
        if (organizationId > 0) {
            let _resp = await CourseService.getCoursesByOrganization(organizationId);
            records.set(_resp.payload);
        }
        else {
            records.set([]);
        }
    }
    finally {
        loading.set(false);
    }
}
export async function initDetail(oid, orgId) {
    record.set(null);
    reset();
    if (oid > 0) {
        loadCourseChannels(oid);
        loadCourseLanguages(oid);
        loadCourseTechAreas(oid);
        loadCourseEduLevels(oid);
        let _resp = await CourseService.fetchCourse(oid);
        record.set(_resp.payload);
    }
    else {
        //let now = dateToISO(new Date());
        record.set({
            oid: 0,
            title: "",
            titleEn: "",
            objective: "",
            description: "",
            startDate: "",
            endDate: "",
            specificDates: 0,
            onDemand: 0,
            clfDurationId: 0,
            website: "",
            contactEmail: "",
            videoUrl: "",
            published: 0,
            internalNotes: "",
            organizationId: orgId
        });
    }
}
export async function deleteCourse(organizationId, courseId) {
    await CourseService.deleteCourse(courseId);
    await loadByOrganization(organizationId);
}
export function onCreate(orgId) {
    // console.log("onCreate");
    navigate(`/organizations/${orgId}/courses/-1`);
}
export function onEdit(item) {
    // console.log("onEdit", item);
    navigate(`/organizations/${item.organizationId}/courses/${item.oid}`);
}
export async function save() {
    let _record = get(record);
    let _rec = await CourseService.saveCourse(_record);
    record.set(_rec.payload);
    await loadByOrganization(_record.organizationId);
}
export async function onDelete(item) {
    // console.log("onDelete", item);
    await CourseService.deleteCourse(item.oid);
    await loadByOrganization(item.organizationId);
}
export async function uploadLogo(event) {
    let _rec = get(record);
    let file = event.detail;
    logoFile.set(file);
    await CourseService.uploadCourseLogo(_rec.oid, file);
    logoFile.set(null);
    logoDc.set(UUID.genV4().toString());
}
// ======================================================================
// ----------------------------------------------------------------------
// Course Channels
export async function loadCourseChannels(courseId) {
    let _resp = await CourseService.getCourseChannels(courseId);
    courseChannels.set(_resp.payload);
}
export async function onAddCourseChannel(evt) {
    let channelId = evt.detail;
    let _record = get(record);
    if (_record == null) {
        return;
    }
    let dto = {
        oid: 0,
        courseId: _record.oid,
        clfChannelId: channelId
    };
    await CourseService.saveCourseChannel(dto);
    await loadCourseChannels(_record.oid);
}
export async function onDelCourseChannel(evt) {
    let channelId = evt.detail;
    let items = get(courseChannels);
    let idx = items.findIndex(val => val.clfChannelId == channelId);
    if (idx == -1) {
        return;
    }
    let item = items[idx];
    if (item) {
        await CourseService.deleteCourseChannel(item.oid);
        await loadCourseChannels(item.courseId);
    }
}
// ----------------------------------------------------------------------
// Course Languages
export async function loadCourseLanguages(courseId) {
    let _resp = await CourseService.getCourseLanguages(courseId);
    courseLanguages.set(_resp.payload);
}
export async function onAddCourseLanguage(evt) {
    let languageId = evt.detail;
    let _record = get(record);
    if (_record == null) {
        return;
    }
    let dto = {
        oid: 0,
        courseId: _record.oid,
        clfLanguageId: languageId
    };
    await CourseService.saveCourseLanguage(dto);
    await loadCourseLanguages(_record.oid);
}
export async function onDelCourseLanguage(evt) {
    let languageId = evt.detail;
    let items = get(courseLanguages);
    let idx = items.findIndex(val => val.clfLanguageId == languageId);
    if (idx == -1) {
        return;
    }
    let item = items[idx];
    if (item) {
        await CourseService.deleteCourseLanguage(item.oid);
        await loadCourseLanguages(item.courseId);
    }
}
// ----------------------------------------------------------------------
// Course Tech Areas
export async function loadCourseTechAreas(courseId) {
    let _resp = await CourseService.getCourseTechAreas(courseId);
    courseTechAreas.set(_resp.payload);
}
export async function onAddCourseTechArea(evt) {
    let techAreaId = evt.detail;
    let _record = get(record);
    if (_record == null) {
        return;
    }
    let dto = {
        oid: 0,
        courseId: _record.oid,
        clfTechAreaId: techAreaId
    };
    await CourseService.saveCourseTechArea(dto);
    await loadCourseTechAreas(_record.oid);
}
export async function onDelCourseTechArea(evt) {
    let techAreaId = evt.detail;
    let items = get(courseTechAreas);
    let idx = items.findIndex(val => val.clfTechAreaId == techAreaId);
    if (idx == -1) {
        return;
    }
    let item = items[idx];
    if (item) {
        await CourseService.deleteCourseTechArea(item.oid);
        await loadCourseTechAreas(item.courseId);
    }
}
// ----------------------------------------------------------------------
// Educational Entry Level
export async function loadCourseEduLevels(courseId) {
    let _resp = await CourseService.getCourseEducationLevels(courseId);
    courseEduLevels.set(_resp.payload);
}
export async function onAddCourseEduLevel(evt) {
    let levelId = evt.detail;
    let _record = get(record);
    if (_record == null) {
        return;
    }
    let dto = {
        oid: 0,
        courseId: _record.oid,
        clfEducationLevelId: levelId
    };
    await CourseService.saveCourseEducationLevel(dto);
    await loadCourseEduLevels(_record.oid);
}
export async function onDelCourseEduLevel(evt) {
    let levelId = evt.detail;
    let items = get(courseEduLevels);
    let idx = items.findIndex(val => val.clfEducationLevelId == levelId);
    if (idx == -1) {
        return;
    }
    let item = items[idx];
    if (item) {
        await CourseService.deleteCourseEducationLevel(item.oid);
        await loadCourseEduLevels(item.courseId);
    }
}
