import Map from 'ol/Map';
import View from 'ol/View';
import Overlay from 'ol/Overlay';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import TileLayer from 'ol/layer/Tile';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import Zoom from 'ol/control/Zoom';
import ScaleLine from 'ol/control/ScaleLine';
import XYZ from 'ol/source/XYZ';
import VectorSource from 'ol/source/Vector';
import { fromLonLat } from 'ol/proj';
import { defaults } from 'ol/control';
export class AtlasMap {
    constructor(options) {
        Object.defineProperty(this, "imgBaseUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pinBaseUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "onPoiClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "map", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vectorSource", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "styles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.imgBaseUrl = options.imgBaseUrl;
        this.pinBaseUrl = options.imgBaseUrl + 'map-pins/';
        this.onPoiClick = function () { };
        this.map = null;
        this.vectorSource = null;
        this.styles = {
            'green-pin': new Style({
                image: new Icon({
                    anchor: [0.5, 1],
                    src: this.pinBaseUrl + 'pin-green.png'
                })
            }),
            'blue-pin': new Style({
                image: new Icon({
                    anchor: [0.5, 1],
                    src: this.pinBaseUrl + 'pin-blue.png'
                })
            }),
            'grey-pin': new Style({
                image: new Icon({
                    anchor: [0.5, 1],
                    src: this.pinBaseUrl + 'pin-grey.png'
                })
            }),
        };
        if (options.onPoiClick && typeof options.onPoiClick === 'function')
            this.onPoiClick = options.onPoiClick;
        this.start();
    }
    start() {
        let self = this;
        let controls = [new Zoom(), new ScaleLine()];
        this.map = new Map({
            controls: defaults({ attribution: true }).extend(controls),
            layers: [
                new TileLayer({
                    source: new XYZ({
                        url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                        maxZoom: 18
                    })
                })
            ],
            target: 'map',
            view: new View({
                center: fromLonLat([12.0, 53.0]),
                maxZoom: 18,
                zoom: 4.5
            })
        });
        this.vectorSource = new VectorSource({ features: [] });
        let layer = new VectorLayer({
            source: this.vectorSource,
            style: function (feature) {
                return self.styles[feature.get('type')];
            }
        });
        this.map.addLayer(layer);
        this.addPopup();
        return null;
    }
    addPopup() {
        let self = this;
        let container = document.getElementById('popup');
        let closer = document.getElementById('popup-closer');
        let overlay = new Overlay({
            element: container,
            autoPan: true,
            //autoPanAnimation: {
            //  duration: 250
            //},
            offset: [32, -54]
        });
        this.map.addOverlay(overlay);
        closer.addEventListener('click', function () {
            overlay.setPosition(undefined);
            closer.blur();
            return false;
        });
        this.map.on('singleclick', function (event) {
            console.log("map click");
            if (self.map.hasFeatureAtPixel(event.pixel) === true) {
                let features = self.map.getFeaturesAtPixel(event.pixel);
                if (features.length == 0)
                    return;
                let feature = features[0];
                let props = feature.getProperties();
                self.onPoiClick(props.oid);
                //overlay.setPosition(event.coordinate);
                //feature.getGeometry().
                //let coordinate = feature.getGeometry()!.getCoordinates();
                //let coordinate = feature.getGeometry()
                //overlay.setPosition(coordinate);
                let point = feature.getGeometry();
                let coordinate = point.getCoordinates();
                overlay.setPosition(coordinate);
            }
            else {
                overlay.setPosition(undefined);
                closer.blur();
            }
        });
    }
    addPois(pois) {
        let features = [];
        if (pois) {
            for (const poi of pois) {
                features.push(new Feature({
                    id: 'org-' + poi.organizationId,
                    oid: poi.organizationId,
                    type: poi.digitalInnovationHub == 1 ? 'blue-pin' : 'green-pin',
                    geometry: new Point(fromLonLat([poi.gpsLon, poi.gpsLat]))
                }));
            }
        }
        this.vectorSource.clear();
        this.vectorSource.addFeatures(features);
    }
}
