<script lang="ts">
  import Header from "../components/Header.svelte";
</script>

<div class="base-layout">
  <Header />
  <div class="content">
    <slot />
  </div>
</div>

<style>
  .base-layout {
    flex-direction: column;
    background: var(--color-gray-6);
    min-height: 100vh;
    display: flex;
  }

  .content {
    animation: fadein 0.8s;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: calc(100vh - 56px);
    overflow-y: auto;
  }
</style>
