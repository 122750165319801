<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let klass: string = "";
  export let disabled: boolean = false;

  const dispatch = createEventDispatcher();

  function handleClick(event: Event) {
    dispatch("click", event);
  }
</script>

<button type="button" class="button {klass}" on:click={handleClick} {disabled}>
  <slot />
</button>
