import { clfCountryPipe } from "../../classification.store";
import { atlasFormatDateTime } from "../date-utils";
export const organizationUserFields = [{
        name: "name",
        title: "Training Provider Name",
        titleClass: "min-w-13",
        dataClass: "",
        render: (record) => record.name
    }, {
        name: "description",
        title: "Training Provider Description",
        titleClass: "",
        dataClass: "",
        render: (record) => record.description
    }, {
        name: "country",
        title: "Country",
        titleClass: "",
        dataClass: "",
        render: (record) => clfCountryPipe(record.clfCountryId)
    }];
export const organizationAdminFields = [{
        name: "approved",
        title: "Approved",
        titleClass: "has-text-centered",
        dataClass: "has-text-centered",
        render: (record) => `<i class="material-icons">${record.approved === 1 ? "check" : ""}</i>`
    }, {
        name: "signUpDate",
        title: "Sign Up Date",
        titleClass: "has-text-centered",
        dataClass: "has-text-centered",
        render: (record) => `<span style="display:inline-block;min-width:10rem;">${atlasFormatDateTime(record.createdOn)}</span>`
    }];
