import { toast } from 'bulma-toast';
export function showToast(message, type) {
    toast({
        message: message,
        type: type,
        dismissible: true,
        closeOnClick: false,
        pauseOnHover: true,
        position: "bottom-left",
        duration: 5000,
        opacity: 0.9
        // animate: { in: 'fadeIn', out: 'fadeOut' }
    });
}
