<script lang="ts">
  import type { IOrganizationConnectionDTO } from "../../services/DTO/organization-connection-dto";
  import { onMount } from "svelte";
  import LayoutOrganizationDetail from "./LayoutOrganizationDetail.svelte";
  import { loading, maybeInitDetail, OrganizationDetailPage, record } from "./organizations.store";
  import FormLayout from "../../layout/FormLayout.svelte";
  import FormField from "../../components/Form/FormField.svelte";
  import SearchDropdown from "../../components/Form/Fields/Dropdown/SearchDropdown.svelte";
  import Textarea from "../../components/Form/Fields/Textarea.svelte";
  import Container from "../../components/Container.svelte";
  import Table from "../../components/Table/Table.svelte";
  import {
    deleteOrganizationConnection,
    description,
    editing,
    loadByOrganization,
    organizationsComboItems,
    records,
    resetEdit,
    saveOrganizationConnection,
    searchOrganizations,
    selectedItem,
  } from "./organizations-connections.store";
  import { get } from "svelte/store";
  import type { IDropdownItem } from "../../services/DTO/dropdown-item";
  import { organizationConnectionDtoFields } from "../../utils/TableFields/organization-connection-dto.fields";

  export let orgId: number;

  function onCreate() {
    resetEdit();
    editing.set(true);
  }

  function onEdit(item: IOrganizationConnectionDTO) {
    let organization = get(record);
    if (organization != null) {
      searchOrganizations(organization.oid, "");
    }
    selectedItem.set({ oid: item.connectedOrganization.oid, label: item.connectedOrganization.nameEn });
    description.set(item.connectionDescription);
    editing.set(true);
  }

  function onDelete(item: IOrganizationConnectionDTO) {
    let organization = get(record);

    if (organization != null) {
      deleteOrganizationConnection(organization.oid, item.oid);
    }
  }

  function onFilter(evt: CustomEvent<string>) {
    let text: string = evt.detail;
    let organization = get(record);

    if (organization != null) {
      searchOrganizations(organization.oid, text);
    }
  }

  function onItemSelected(evt: CustomEvent<IDropdownItem>) {
    let item: IDropdownItem = evt.detail;
    selectedItem.set(item);
  }

  function submit() {
    let item = get(selectedItem);
    let organization = get(record);

    if (item != null && organization != null) {
      let descr = get(description);
      saveOrganizationConnection(organization.oid, item.oid, descr);
    }

    resetEdit();
  }

  onMount(() => {
    maybeInitDetail(orgId);
    loadByOrganization(orgId);
  });
</script>

<LayoutOrganizationDetail page={OrganizationDetailPage.OrganizationConnections} organization={$record} whiteBox={false} loading={$loading}>
  {#if $editing == true}
    <FormLayout on:submit={(_) => submit()} submitButtonText="Connect Training Provider" cancelEnabled={false}>
      <div style="max-width: 60%;margin: 0 auto;">
        <FormField usageHelp="select a training provider or digital innovation hub to connect with. Links will be visible to end-users too">
          <SearchDropdown
            placeholder="training provider to connect with"
            items={$organizationsComboItems}
            on:filter={onFilter}
            on:select={onItemSelected}
            disabled={false}
            value={$selectedItem != null ? $selectedItem.oid : 0}
            name="Connected Training Provider"
          />
        </FormField>

        <FormField usageHelp="connection description (unlimited length) visible to end-users">
          <Textarea placeholder="connection description" disabled={false} bind:value={$description} name="Connection Description" />
        </FormField>
      </div>
    </FormLayout>
  {/if}

  {#if $editing == false}
    <Container>
      <Table
        loading={$loading}
        fields={organizationConnectionDtoFields}
        records={$records}
        deletionRequestText="Do you want to delete this connection?"
        createEnabled={true}
        editEnabled={true}
        deleteEnabled={true}
        on:create={(_) => onCreate()}
        on:edit={(e) => onEdit(e.detail)}
        on:delete={(e) => onDelete(e.detail)}
      />
    </Container>
  {/if}
</LayoutOrganizationDetail>
