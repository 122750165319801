import { get, writable } from "svelte/store";
import { AuthService } from "../../services/auth.service";
import { withProof } from "../../utils/misc-utils";
export const loading = writable(false);
export const signupRequest = writable({});
export const outcome = writable(null);
export const errors = writable([]);
export function init() {
    signupRequest.set({});
    outcome.set(null);
    errors.set([]);
}
export async function submit() {
    loading.set(true);
    try {
        let req = get(signupRequest);
        let res = await AuthService.signup(req);
        outcome.set(res.payload);
        if (res.payload.success == false) {
            if (res.payload.message)
                errors.set([res.payload.message]);
            else
                errors.set(["An error has occurred, please try again later"]);
        }
    }
    finally {
        loading.set(false);
    }
}
export async function doSubmit() {
    let x = get(signupRequest);
    x.recaptchaToken = await withProof("sign_up");
    signupRequest.set(x);
    await submit();
}
