<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import type { IApiPagination } from "../../utils/api/api-response";
  import Icon from "../Icon.svelte";

  export let pagination: IApiPagination | null;

  const dispatch = createEventDispatcher();

  function onPageChange(pageNumber: number) {
    dispatch("pageChange", pageNumber);
  }

  function toPage(pageNumber: number) {
    onPageChange(pageNumber);
  }

  function toNextPage() {
    toPage(curPage + 1);
  }

  function toPrevPage() {
    toPage(curPage - 1);
  }

  $: curPage = pagination != null ? pagination.currentPage : 0;
  $: numPages = pagination != null ? pagination.totalPages : 0;
</script>

{#if curPage > 0}
  <nav class="pagination is-centered" aria-label="pagination">
    <ul class="pagination-list">
      {#if curPage != 1}
        <li class="pag-item">
          <!-- svelte-ignore a11y-missing-attribute -->
          <a class="pagination-previous" on:click={toPrevPage}>
            <Icon name="navigate_before" />
          </a>
        </li>
      {/if}

      {#if curPage > 1}
        <li class="pag-item">
          <!-- svelte-ignore a11y-missing-attribute -->
          <a class="pagination-link" on:click={(_) => toPage(1)}> 1 </a>
        </li>
      {/if}

      {#if curPage - 1 > 2}
        <li class="pag-item">
          <span class="pagination-ellipsis">
            <Icon name="more_horiz" />
          </span>
        </li>
      {/if}

      {#if curPage - 1 > 1}
        <li class="pag-item">
          <!-- svelte-ignore a11y-missing-attribute -->
          <a class="pagination-link" on:click={(_) => toPage(curPage - 1)}>
            {curPage - 1}
          </a>
        </li>
      {/if}

      <li class="pag-item">
        <!-- svelte-ignore a11y-missing-attribute -->
        <a class="pagination-link is-current paginator-current-page">
          {curPage}
        </a>
      </li>

      {#if curPage + 1 < numPages}
        <li class="pag-item">
          <!-- svelte-ignore a11y-missing-attribute -->
          <a class="pagination-link" on:click={(_) => toPage(curPage + 1)}>
            {curPage + 1}
          </a>
        </li>
      {/if}

      {#if curPage + 1 < numPages - 1}
        <li class="pag-item">
          <span class="pagination-ellipsis">
            <Icon name="more_horiz" />
          </span>
        </li>
      {/if}

      {#if curPage < numPages}
        <li class="pag-item">
          <!-- svelte-ignore a11y-missing-attribute -->
          <a class="pagination-link" on:click={(_) => toPage(numPages)}>
            {numPages}
          </a>
        </li>
      {/if}

      {#if curPage < numPages}
        <li class="pag-item">
          <!-- svelte-ignore a11y-missing-attribute -->
          <a class="pagination-next" on:click={toNextPage}>
            <Icon name="navigate_next" />
          </a>
        </li>
      {/if}
    </ul>
  </nav>
{/if}

<style>
  .paginator-current-page {
    /* background-color: red; */
    border-color: #888;
    color: #fff;
  }

  .pag-item {
    margin-top: 0.25rem;
  }
</style>
