import { clfCountryPipe } from "../../classification.store";
import { formatTimestampHuman } from "../date-utils";
export const registrationRequestFields = [{
        title: "Requestor",
        titleClass: "",
        dataClass: "",
        name: "fullName",
        render: (record) => record.firstName + " " + record.lastName
    }, {
        title: "Organization",
        titleClass: "",
        dataClass: "",
        name: "organizationName",
        render: (record) => record.organizationName
    }, {
        title: "Country",
        titleClass: "",
        dataClass: "",
        name: "country",
        render: (record) => clfCountryPipe(record.clfCountryId)
    }, {
        title: "Status",
        titleClass: "has-text-centered",
        dataClass: "has-text-centered",
        name: "status",
        render: (record) => `<i class="material-icons">${record.processed == 1 ? (record.approved == 1 ? "check" : "close") : "help_outline"}</i>`
    }, {
        title: "Request Date",
        titleClass: "",
        dataClass: "",
        name: "createdOn",
        render: (record) => formatTimestampHuman(record.createdOn)
    }];
