<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import Errors from "./Errors.svelte";
  import Label from "./Form/Label.svelte";
  import Image from "./Image.svelte";

  export let errors: string[] = [];
  export let placeholder: string = "Upload";
  export let disabled: boolean = false;
  export let fileNameVisible: boolean = false;
  export let value: File | null = null;
  export let dc: string;
  export let name: string = "";
  export let boxed: boolean = false;
  export let accept: string = ".png,.jpg";
  export let previewUrl: string = "";
  export let previewHeight: string = "auto";
  export let previewWidth: string = "auto";

  let inputElement: HTMLInputElement;

  $: selectedFileName = value != null ? value.name : "no file selected";
  $: realPreviewUrl = previewUrl != "" && dc != "" ? previewUrl + "?_dc=" + dc : "";

  const dispatch = createEventDispatcher();

  function onSelectFile() {
    inputElement.click();
  }

  function onInputChange() {
    const file = inputElement.files?.item(0);
    if (file == null) {
      return;
    }

    dispatch("fileUpload", file);
  }
</script>

<Label>{name}</Label>

{#if realPreviewUrl != ""}
  <div class="preview">
    {#key realPreviewUrl}
      <Image src={realPreviewUrl} height={previewHeight} width={previewWidth} disableCache={false} />
    {/key}
  </div>
{/if}

<div class="file is-primary" class:has-name={fileNameVisible} class:is-boxed={boxed}>
  <input class="file-input-hidden" type="file" {disabled} {accept} bind:this={inputElement} on:change={onInputChange} />
  <!-- svelte-ignore a11y-label-has-associated-control -->
  <label class="file-label">
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <span class="file-cta" on:click={(_) => onSelectFile()}>
      <span class="file-icon"><i class="material-icons">file_upload</i></span>
      <span class="file-label">{placeholder}</span>
    </span>

    {#if fileNameVisible}
      <span class="file-name is-italic">{selectedFileName}</span>
    {/if}
  </label>
</div>

<Errors {errors} prefix={name} />

<style>
  .preview {
    margin-bottom: 0.5rem;
  }

  .file-input-hidden {
    visibility: hidden;
  }
</style>
