import { writable, get } from 'svelte/store';
import { ClassificationService } from './services/classification.service';
export const loaded = writable(false);
export const clfCountry = writable([]);
export const clfCourseLanguage = writable([]);
export const clfTechArea = writable([]);
export const clfCourseDuration = writable([]);
export const clfCourseEducationLevel = writable([]);
export const clfCourseChannel = writable([]);
export const clfCourseSkill = writable([]);
export async function clfLoad() {
    if (get(loaded) == false) {
        await Promise.all([
            loadSchemaValues("COUNTRYXXX", clfCountry),
            loadSchemaValues("COURSELANG", clfCourseLanguage),
            loadSchemaValues("TECHAREAXX", clfTechArea),
            loadSchemaValues("COURSEDRTN", clfCourseDuration),
            loadSchemaValues("COURSEEDLV", clfCourseEducationLevel),
            loadSchemaValues("COURSETRCH", clfCourseChannel),
            loadSchemaValues("COURSSKILL", clfCourseSkill),
        ]);
        loaded.set(true);
    }
}
export async function clfForceReload() {
    loaded.set(false);
    await clfLoad();
}
async function loadSchemaValues(schema, store) {
    let res = await ClassificationService.getAllValuesCombo(schema);
    store.set(res.payload);
}
export function clfPipe(clfData, oid) {
    return clfData != null ? clfData.filter(item => item.oid == oid).map(x => x.label).join("") : "";
}
export function clfCountryPipe(oid) {
    return clfPipe(get(clfCountry), oid);
}
