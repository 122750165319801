import { writable } from 'svelte/store';
import { SearchService } from '../../services/search.service';
export const loading = writable(false);
export const detail = writable(null);
export const selectedCourse = writable(null);
export var MapDetailPage;
(function (MapDetailPage) {
    MapDetailPage[MapDetailPage["Organization"] = 1] = "Organization";
    MapDetailPage[MapDetailPage["Courses"] = 2] = "Courses";
    MapDetailPage[MapDetailPage["Connections"] = 3] = "Connections";
})(MapDetailPage || (MapDetailPage = {}));
export const currentTab = writable(MapDetailPage.Organization);
export async function initDetailWithDetail(det) {
    currentTab.set(MapDetailPage.Organization);
    detail.set(det);
    if (det.courses != null && det.courses.length > 0) {
        selectedCourse.set(det.courses[0]);
    }
}
export async function initDetail(oid) {
    loading.set(true);
    try {
        currentTab.set(MapDetailPage.Organization);
        selectedCourse.set(null);
        detail.set(null);
        let criteria = {
            title: '',
            techAreaIds: [],
            channelIds: [],
            languageIds: [],
            countryId: undefined,
            startMonth: 0,
            startYear: 0,
            endMonth: 0,
            endYear: 0
        };
        let filter = { criteria: criteria, params: null, sorting: "" };
        let _resp = await SearchService.searchDetail(oid, filter);
        detail.set(_resp.payload);
        if (_resp.payload.courses != null && _resp.payload.courses.length > 0) {
            selectedCourse.set(_resp.payload.courses[0]);
        }
    }
    finally {
        loading.set(false);
    }
}
export function setSelectedCourse(value) {
    selectedCourse.set(value);
}
