<script lang="ts">
  import type { IOrganization } from "../../entities/organization";
  import { navigate } from "../../app.logic";
  import Container from "../../components/Container.svelte";
  import Tab from "../../components/Tab/Tab.svelte";
  import Tabs from "../../components/Tab/Tabs.svelte";
  import LayoutWithSidebar from "../../layout/LayoutWithSidebar.svelte";
  import { OrganizationDetailPage } from "./organizations.store";

  export let organization: IOrganization | null;
  export let whiteBox: boolean = false;
  export let page: OrganizationDetailPage;
  export let loading: boolean;

  $: isNew = organization != null && organization.oid == 0;
</script>

<LayoutWithSidebar>
  {#if loading}
    <div class="table-loader" />
  {:else if organization}
    <Container>
      <div class="title">
        {#if isNew && organization.digitalInnovationHub == 0}
          New Training Provider
        {/if}

        {#if isNew && organization.digitalInnovationHub == 1}
          New Digital Innovation Hub
        {/if}

        {#if !isNew && organization.digitalInnovationHub == 0}
          Training Provider: {organization.nameEn}
        {/if}

        {#if !isNew && organization.digitalInnovationHub == 1}
          Digital Innovation Hub: {organization.nameEn}
        {/if}
      </div>

      <Tabs>
        <Tab
          label="Training Provider"
          active={page == OrganizationDetailPage.OrganizationInfo}
          on:click={(_) => navigate("/organizations/" + organization.oid)}
        />

        {#if !isNew}
          <Tab
            label="Courses"
            active={page == OrganizationDetailPage.OrganizationCourses || page == OrganizationDetailPage.OrganizationCourse}
            on:click={(_) => navigate("/organizations/" + organization.oid + "/courses")}
          />

          <Tab
            label="Connections"
            active={page == OrganizationDetailPage.OrganizationConnections}
            on:click={(_) => navigate("/organizations/" + organization.oid + "/connections")}
          />
        {/if}
      </Tabs>

      {#if whiteBox == true}
        <div class="card">
          <slot />
        </div>
      {:else}
        <slot />
      {/if}
    </Container>
  {/if}
</LayoutWithSidebar>

<style>
  .title {
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 22px;
    padding-left: 1rem;
  }
</style>
