import { lastValueFrom } from 'rxjs';
import { BaseService } from './base-service';
export class ManagementService {
    // ----------------------------------------------------------------------
    // Registration Requests
    // ----------------------------------------------------------------------
    static getRegistrationRequests(filter, pageNumber) {
        let qs = encodeURIComponent(JSON.stringify(filter));
        return lastValueFrom(BaseService.getPaginated(`management/registration-requests?filter=${qs}`, pageNumber));
    }
    static saveRegistrationRequest(dto) {
        return lastValueFrom(BaseService.save(`management/registration-requests`, dto));
    }
    static rejectRegistrationRequest(dto) {
        return lastValueFrom(BaseService.save(`management/registration-request-reject`, dto));
    }
    static approveRegistrationRequest(dto) {
        return lastValueFrom(BaseService.save(`management/registration-request-approve`, dto));
    }
    static approveRegistrationRequestUserOnly(dto) {
        return lastValueFrom(BaseService.save(`management/registration-request-approve-user-only`, dto));
    }
    // ----------------------------------------------------------------------
    // Deletion Requests
    // ----------------------------------------------------------------------
    static getDeletionRequests(filter, pageNumber) {
        let qs = encodeURIComponent(JSON.stringify(filter));
        return lastValueFrom(BaseService.getPaginated(`management/deletion-requests?filter=${qs}`, pageNumber));
    }
    static saveDeletionRequest(dto) {
        return lastValueFrom(BaseService.save(`management/deletion-requests`, dto));
    }
}
