import moment from "moment";
export function parseDate(d) {
    return moment(d).local().toDate();
}
export function formatDateHuman(d) {
    return moment(d).format('DD/MM/YYYY');
}
export function formatTimestampHuman(d) {
    return moment(d).format('DD/MM/YYYY HH:mm');
}
export function parseDateRaw(d) {
    return moment(d, 'YYYYMMDD').local().toDate();
}
export function formatDateRaw(d) {
    return moment(d).format('YYYYMMDD');
}
export function formatCurrency(val) {
    return '€ ' + (val || 0).toFixed(2);
}
export function atlasFormatDate(s) {
    if (!s || s === "")
        return "";
    return moment(s).format("Do MMM YYYY");
}
export function atlasFormatDateTime(s) {
    if (!s || s === "")
        return "";
    return moment(s).format("Do MMM YYYY HH:mm");
}
export function dateFromISO(s) {
    if (s == null || s == "")
        return null;
    //return moment(s, 'YYYY-MM-DD HH:mm:ss').local().toDate();
    return moment(s, 'YYYY-MM-DD').local().toDate();
}
export function dateToISO(d) {
    if (d == null)
        return "";
    //return moment(d).format('YYYY-MM-DD HH:mm:ss');
    return moment(d).format('YYYY-MM-DD');
}
