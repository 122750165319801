import { lastValueFrom } from 'rxjs';
import { BaseService } from './base-service';
export class AuthService {
    static login(dto) {
        return lastValueFrom(BaseService.post(`auth/login`, dto));
    }
    static refresh() {
        return lastValueFrom(BaseService.post(`auth/refresh`, {}));
    }
    static logout() {
        return lastValueFrom(BaseService.post(`auth/logout`, {}));
    }
    static signup(dto) {
        return lastValueFrom(BaseService.post(`auth/signup`, dto));
    }
    static forgotPassword(dto) {
        return lastValueFrom(BaseService.post(`auth/forgot-password`, dto));
    }
    static resetPassword(dto) {
        return lastValueFrom(BaseService.post(`auth/reset-password`, dto));
    }
}
