<script lang="ts">
  export let klass: string = "";
</script>

<td class={"table-data-field " + klass}>
  <slot />
</td>

<style>
  .table-data-field {
    vertical-align: middle;
  }
</style>
