<script lang="ts">
  import { onMount } from "svelte";
  import Container from "../../components/Container.svelte";
  import Image from "../../components/Image.svelte";
  import Tab from "../../components/Tab/Tab.svelte";
  import Tabs from "../../components/Tab/Tabs.svelte";
  import LayoutWithSidebar from "../../layout/LayoutWithSidebar.svelte";
  import { BASE_API_URL } from "../../utils/constants";
  import { MapDetailPage, currentTab, detail, initDetail } from "./map-detail.store";
  import MapDetailPageOrganization from "./MapDetailPageOrganization.svelte";
  import MapDetailPageCourses from "./MapDetailPageCourses.svelte";
  import MapDetailPageConnections from "./MapDetailPageConnections.svelte";

  export let oid: number = -1;

  onMount(() => {
    if (oid != -1) {
      initDetail(oid);
    }
  });
</script>

<LayoutWithSidebar>
  {#if $detail}
    <Container>
      <div class="parent">
        {#if $detail.hasLogo}
          <div class="logo-container">
            <figure class="image">
              <Image
                src={BASE_API_URL + "/organization-logo/" + $detail.organizationId}
                height="auto"
                width="100%"
                customStyle="max-width: 200px;"
                disableCache={true}
              />
            </figure>
          </div>
        {/if}

        <div class="info-container">
          <Tabs>
            <Tab
              label="Training Provider"
              active={$currentTab == MapDetailPage.Organization}
              on:click={(_) => {
                currentTab.set(MapDetailPage.Organization);
              }}
            />

            {#if $detail.courses.length != 0}
              <Tab
                label="Courses"
                active={$currentTab == MapDetailPage.Courses}
                on:click={(_) => {
                  currentTab.set(MapDetailPage.Courses);
                }}
              />
            {/if}

            {#if $detail.connections.length != 0}
              <Tab
                label="Connections"
                active={$currentTab == MapDetailPage.Connections}
                on:click={(_) => {
                  currentTab.set(MapDetailPage.Connections);
                }}
              />
            {/if}
          </Tabs>

          {#if $currentTab == MapDetailPage.Organization}
            <MapDetailPageOrganization />
          {/if}

          {#if $currentTab == MapDetailPage.Courses}
            <MapDetailPageCourses />
          {/if}

          {#if $currentTab == MapDetailPage.Connections}
            <MapDetailPageConnections />
          {/if}
        </div>
      </div>
    </Container>
  {/if}
</LayoutWithSidebar>

<style>
  .parent {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  .logo-container {
    max-width: 200px;
    margin-top: 4rem;
    margin-right: 2rem;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: var(--color-gray-11);
  }
</style>
