<script>
  import Button from "../../components/Button.svelte";
  import Form from "../../components/Form/Form.svelte";
  import FormField from "../../components/Form/FormField.svelte";
  import GlobalErrors from "../../components/GlobalErrors.svelte";
  import LayoutOutside from "../../layout/LayoutOutside.svelte";
  import Input from "../../components/Form/Fields/Input.svelte";
  import {
    showForgotPassword,
    showRecovery,
    backToLoginPage,
    email,
    password,
    recoveryCode,
    submitRecoveryWithCaptcha,
    submitResetWithCaptcha,
    recoveryDoneMessage,
    init,
    gotoSignUp,
    loginErrors,
    setShowForgotPassword,
    loading,
    submitWithCaptcha,
  } from "./signin.store";
  import { onMount } from "svelte";

  $: canSubmit = $email != null && $email != "" && $password != null && $password != "";
  $: disabled = $loading;

  onMount(() => init());
</script>

{#if $showForgotPassword}
  <LayoutOutside title="Password Recovery">
    <Form>
      <GlobalErrors errors={[]} />

      <p class="has-text-centered" style="margin-bottom: 1rem;">Please enter your e-mail and we'll send you a recovery code to reset your password.</p>

      <FormField>
        <Input placeholder="E-mail" bind:value={$email} disabled={false} type="text" name="" />
      </FormField>

      <hr class="hr" />

      <div class="field is-grouped">
        <div class="control btn-centered">
          <Button klass="is-primary" disabled={disabled || $email == ""} on:click={submitRecoveryWithCaptcha}>Send recovery e-mail</Button>
        </div>

        <div class="control btn-centered">
          <Button klass="" on:click={backToLoginPage}>Back to login page</Button>
        </div>
      </div>
    </Form>
  </LayoutOutside>
{:else if $showRecovery}
  <LayoutOutside title="Password Recovery">
    {#if $recoveryDoneMessage != ""}
      <Form>
        <p class="has-text-centered" style="margin-bottom: 1rem;">
          {$recoveryDoneMessage}
        </p>

        <hr class="hr" />

        <div class="field is-grouped">
          <div class="control btn-centered">
            <Button klass="is-primary" on:click={backToLoginPage}>Back to login page</Button>
          </div>
        </div>
      </Form>
    {:else}
      <Form>
        <GlobalErrors errors={[]} />

        <p class="has-text-centered" style="margin-bottom: 1rem;">
          We've sent a recovery code to your e-mail.
          <br />
          Please enter that code below and a new password.
        </p>

        <FormField>
          <Input placeholder="recovery code" bind:value={$recoveryCode} disabled={false} type="text" name="" />
        </FormField>

        <FormField>
          <Input placeholder="new password" bind:value={$password} disabled={false} type="password" name="" />
        </FormField>

        <hr class="hr" />

        <div class="field is-grouped">
          <div class="control btn-centered">
            <Button klass="is-primary" disabled={disabled || $email == "" || $password == "" || $recoveryCode == ""} on:click={submitResetWithCaptcha}>
              Reset Password
            </Button>
          </div>

          <div class="control btn-centered">
            <Button klass="" on:click={backToLoginPage}>Back to login page</Button>
          </div>
        </div>
      </Form>
    {/if}
  </LayoutOutside>
{:else}
  <LayoutOutside title="Sign In" subtitle="Welcome! Please login to your account.">
    <Form>
      <GlobalErrors errors={$loginErrors} />

      <FormField>
        <Input placeholder="E-mail" bind:value={$email} disabled={false} type="text" name="" />
      </FormField>

      <FormField>
        <Input placeholder="Password" bind:value={$password} disabled={false} type="password" name="" />
      </FormField>

      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <!-- svelte-ignore a11y-missing-attribute -->
      <div class="forgotPassword">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <a class="black" on:click={(_) => setShowForgotPassword(true)}>Forgot your password?</a>
      </div>

      <hr class="hr" />

      <div class="field is-grouped">
        <div class="control btn-centered">
          <Button klass="is-primary" disabled={disabled || !canSubmit} on:click={submitWithCaptcha}>Sign in</Button>
        </div>

        <div class="control btn-centered">
          <Button klass="" on:click={(_) => gotoSignUp()}>Sign up</Button>
        </div>
      </div>
    </Form>
  </LayoutOutside>
{/if}

<style>
  .btn-centered {
    margin: 0 auto;
  }

  .hr {
    border: 0;
    border-top: 1px solid #eee;
    margin: 20px 0;
  }

  .forgotPassword {
    color: #444;
    font-size: 0.75rem;
    line-height: 1rem;
    padding-right: 0.5rem;
    display: inline-block;
    width: 100%;
    text-align: right;
  }

  .black {
    color: #444;
  }
</style>
