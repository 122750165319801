<script lang="ts">
  import Icon from "../Icon.svelte";

  export let klass: string = "";
  export let sortable: boolean = false;
</script>

<th class={"table-header-field " + klass} style="vertical-align: middle;">
  {#if sortable}
    <Icon name="sort" />
  {/if}

  <slot />
</th>

<style>
  .table-header-field {
    vertical-align: middle;
  }
</style>
