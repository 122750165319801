<script lang="ts">
  import { isUserLoggedIn, isUserRoleADM } from "../app.store";
  import ImageAsset from "../components/ImageAsset.svelte";
  import Layout from "./Layout.svelte";
</script>

<Layout>
  <div class="parent">
    <div class="sidebar">
      <a href="/map">
        <ImageAsset name="icon_map.png" />
      </a>

      <div class="filler" />

      {#if $isUserLoggedIn && $isUserRoleADM}
        <div class="bottom-settings">
          <a href="/settings">
            <ImageAsset name="icon_settings.png" />
          </a>
        </div>
      {/if}
    </div>

    <div class="content">
      <slot />
    </div>
  </div>
</Layout>

<style>
  .parent {
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    width: 56px;
    background-color: var(--color-white-1);

    /* box-shadow: 2px 2px 6px #ccc; */
    text-align: center;
    padding: 0;
    padding-top: 1.5rem;

    position: absolute;
    top: 56px;
    left: 0;
    bottom: 0;
  }

  .filler {
    flex: 1;
  }

  .bottom-settings {
    padding-top: 15px;
    padding-bottom: 10px;
    text-align: center;
    width: 100%;
    background: var(--color-green-settings);
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 56px;
  }
</style>
