<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let label: string = "";
  export let active: boolean = false;

  $: activeClass = active ? "is-active" : "";

  const dispatch = createEventDispatcher();

  function handleClick(event: Event) {
    dispatch("click", event);
  }
</script>

<li class={activeClass}>
  <!-- svelte-ignore a11y-missing-attribute -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <a on:click={handleClick}>
    {label}
  </a>
</li>
